import Shape from './index.js'
class Picture extends Shape {
  constructor(ctx, sx, sy, ox, oy, img) {
    super(ctx, sx, sy, ox, oy, img)
    this.img = img
    this.width = this.getSize(img).width
    this.height = this.getSize(img).height
    this._left = 0
    this._top = 0
  }

  fill() {
    this.ctx.drawImage(this.img, this._left, this._top, this.width, this.height)
  }

  getSize(img) {
    return { width: img.width, height: img.height }
  }

  _setStartX(sx) {
    this._left = sx
    return this
  }

  _setStartY(sy) {
    this._top = sy
    return this
  }

  setWidth(width) {
    this.width = width
    return this
  }

  setHeight(height) {
    this.height = height
    return this
  }

  setStart(sx, sy) {
    this._setStartX(sx)._setStartY(sy)
  }
}

export default Picture
