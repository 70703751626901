import Link from './index'
import FoldLink from './foldLink'
import CurveLink from './curveLink'
import StraightLink from './straightLink'
import { LINETYPE } from './constant'

Link.injectLineType = () => {
  Link.type = {
    [LINETYPE.FOLD]: FoldLink, // 折线
    [LINETYPE.CURVE]: CurveLink, // 曲线
    [LINETYPE.STRAIGHT]: StraightLink // 直线
  }
}

export default Link
