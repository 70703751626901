import Helper from 'utils/helper'

const TRANSFORM = 'transform'

export const getTransformByLocal = () => {
  let res
  try {
    res = Helper.localStorageGetItem(TRANSFORM)
  } catch (error) {
    return {}
  }
  return res || {}
}

export const setTransformToLocal = (val) => {
  return Helper.localStorageSetItem(TRANSFORM, val)
}
