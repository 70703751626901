import Style from '../style'

class CurveLinkStyle extends Style {
  constructor(target) {
    super(target)

    this.controlPointColor = 'white'
    this.controlPointHoverColor = 'black'
    this.midPointColor = 'black'
  }

  applyChildStyle() {
    const { compoundLines } = this.target
    const lines = compoundLines.getAllCurves()
    for (const l of lines) {
      const { background, curveLine } = l
      background.getStyle().setStrokeStyle(this.backgroundColor).setLineWidth(this.backgroundLineWidth).setLineDash(this.lineDash)
      curveLine.getStyle().setStrokeStyle(this.lineColor).setLineWidth(this.lineWidth).setLineDash(this.lineDash)
    }
    this.resetControlStyle()
  }

  // 重置控制点、头节点样式
  resetControlStyle() {
    const { compoundLines } = this.target
    const lines = compoundLines.getAllCurves()
    for (const l of lines) {
      const { startControl, endControl } = l
      ;[startControl, endControl].forEach(
        h => h.dragTarget.getStyle().setFillStyle(this.controlPointColor)
      )

      // 曲线头节点（即曲线的分割点）的默认样式
      // curveHead.getStyle().setFillStyle(this.headColor)

      // 曲线中点
      // centerSplit.getStyle().setFillStyle(this.headColor)
    }
  }
}

export default CurveLinkStyle
