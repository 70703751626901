// TODO 后续需要继抽离为模版形式
function getNode(node) {
  return {
    id: node.id,
    type: node.type,
    x: node.x,
    y: node.y,
    name: node.name,
    subName: node.subName,
    value: node.value,
    tagIds: node.tagIds || [],
    width: node.width,
    height: node.height,
    img: node.img,
    funcLock: {
      lockType: node.funcLock.lockType
    },
    hyperlink: node.hyperlink,
    picUrl: node.picUrl,
    style: {
      backgroundColor: node.style.backgroundColor,
      backgroundOpacity: node.style.backgroundOpacity,
      borderOpacity: node.style.borderOpacity,
      boundaryColor: node.style.boundaryColor,
      boundaryLineWidth: node.style.boundaryLineWidth,
      deleteline: node.style.deleteline,
      fontColor: node.style.fontColor,
      fontFamily: node.style.fontFamily,
      fontSize: node.style.fontSize,
      fontStyle: node.style.fontStyle,
      fontVariant: node.style.fontVariant,
      fontWeight: node.style.fontWeight,
      lineColor: node.style.lineColor,
      textAlign: node.style.textAlign,
      textAngle: node.style.textAngle,
      textOpacity: node.style.textOpacity,
      underline: node.style.underline,
      lineDash: node.style.lineDash,
      paddingLeft: node.style.paddingLeft,
      paddingTop: node.style.paddingTop,
      lineWidth: node.style.lineWidth
    }
  }
}

function getLink(link) {
  const { points, controlPoints } = link.getPoints()

  return {
    id: link.id,
    points,
    controlPoints,
    name: link.name,
    type: link.type,
    texts: link.texts.map(t => getNode(t)),
    isUsedSplit: link.isUsedSplit,
    style: {
      arrowLineWidth: link.style.arrowLineWidth,
      backgroundColor: link.style.backgroundColor,
      color: link.style.color,
      fontColor: link.style.fontColor,
      fontSize: link.style.fontSize,
      headColor: link.style.headColor,
      headWidth: link.style.headWidth,
      lineColor: link.style.lineColor,
      lineWidth: link.style.lineWidth,
      startArrowColor: link.style.startArrowColor,
      startArrowType: link.style.startArrowType,
      endArrowType: link.style.endArrowType
    },
    start: { x: link.start.x, y: link.start.y },
    end: { x: link.end.x, y: link.end.y },
    firstId: link.first && link.first.id,
    lastId: link.last && link.last.id
  }
}

function getGroup(group) {
  return {
    id: group.id,
    members: group.members.members,
    x: group.x,
    y: group.y,
    width: group.width,
    height: group.height
  }
}

export {
  getNode,
  getLink,
  getGroup
}
