import {
  CHANGE_SYSTEM_STYLE,
  CHANGE_FLOAT_MENU_TYPE,
  CHANGE_FLOAT_MENU_TYPE_AND_POS,
  CHANGE_FLOAT_MENU_SHOW_STATUS
} from '../mutation-type'

const changeSystemStyle = (value) => {
  return {
    type: CHANGE_SYSTEM_STYLE,
    value
  }
}

const changeFloatMenuType = (value) => {
  return {
    type: CHANGE_FLOAT_MENU_TYPE,
    value
  }
}

const changeFloatMenuTypeAndPos = (value) => {
  return {
    type: CHANGE_FLOAT_MENU_TYPE_AND_POS,
    value
  }
}

const changeFloatMenuShowStatus = (value) => {
  return {
    type: CHANGE_FLOAT_MENU_SHOW_STATUS,
    value
  }
}

export {
  changeSystemStyle,
  changeFloatMenuType,
  changeFloatMenuTypeAndPos,
  changeFloatMenuShowStatus
}
