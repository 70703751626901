import React from 'react'
import { Select } from 'antd'
import PropTypes from 'prop-types'

const Option = Select.Option

export default function SimpleSelect(props) {
  const { values = [], curValue = '', handleChange } = props

  return (
    <div onWheel={(e) => e.stopPropagation()}>
      <Select
        style={{
          width: 80
        }}
        defaultValue={curValue}
        onChange={handleChange}
      >
        {values.map((v, index) => (
          <Option value={v} key={index}>
            {v}
          </Option>
        ))}
      </Select>
    </div>
  )
}

SimpleSelect.propTypes = {
  values: PropTypes.array,
  curValue: PropTypes.string || PropTypes.number,
  handleChange: PropTypes.func
}
