/*
  表示文件的icon
 */
import Rect from './rect.js'
class Lock extends Rect {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.setWidth(width || 0).setHeight(height || 0)
    this.arcRadius = 1
    this.initial(sx, sy, 0, 0)
  }
  initial(sx, sy, ox, oy) { // 初始化画布
    return this.init(sx, sy, ox, oy)._horizontal()._vertical()
  }
  process() { // 绘制锁
    const { ctx, _left, _top, _right, _bottom, height, width, arcRadius, sty } = this
    const gapX = width / 6
    const gapY = height / 3
    const start = { x: _left + gapX, y: _top }
    const core = { width: width / 8, height: height / 3, gapX: width / 8 * 7 / 2, gapY: height / 2 }
    ctx.beginPath()
    ctx.moveTo(start.x, _top + gapY)
    ctx.arcTo(start.x, start.y, _right - gapX, _top, 4)
    ctx.arcTo(_right - gapX, _top, _right - gapX, _top + gapY, 4)
    ctx.lineTo(_right - gapX, _top + gapY)
    ctx.strokeStyle = sty.strokeStyle
    ctx.stroke()
    ctx.beginPath()
    ctx.moveTo(_right - gapX, _top + gapY)
    ctx.rect(_left + core.gapX, _top + core.gapY, core.width, core.height)
    ctx.lineTo(_right - gapX, _top + gapY)
    ctx.arcTo(_left, _top + gapY, _left, _bottom, arcRadius)
    ctx.arcTo(_left, _bottom, _right, _bottom, arcRadius)
    ctx.arcTo(_right, _bottom, _right, _top + gapY, arcRadius)
    ctx.arcTo(_right, _top + gapY, _left, _top + gapY, arcRadius)
    ctx.lineTo(_right - gapX, _top + gapY)
    ctx.fill()
  }
}

export default Lock
