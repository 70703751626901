/*
 绘制6边形
 */
import Process from './process.js'
class six extends Process {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.setWidth(width).setHeight(height)
  }
  initial(sx, sy, ox, oy) { // 初始化画布
    return this.init(sx, sy, ox, oy)._horizontal()._vertical()
  }
  process() { // 6边形
    const halfX = this._left + this.width / 2
    const halfY = this._top + this.height / 2
    const base = this.width / 4
    const dip = this.width / 4 * 3
    this.ctx.beginPath()
    this.ctx.moveTo(halfX, this._top)
    this.ctx.arcTo(this._right - base, this._top, this._right, halfY - 1, 3)
    this.ctx.arcTo(this._right, halfY - 1, this._right - base, this._bottom, 3)
    this.ctx.arcTo(this._right - base, this._bottom, this._right - dip, this._bottom, 3)
    this.ctx.arcTo(this._right - dip, this._bottom, this._left, halfY - 1, 3)
    this.ctx.arcTo(this._left, halfY - 1, this._right - dip, this._top, 3)
    this.ctx.arcTo(this._right - dip, this._top, this._right - base, this._top, 2)
    this.ctx.lineTo(halfX, this._top)
  }
}
export default six
