import { Tooltip } from 'antd'
import React from 'react'
import PropTypes from 'prop-types'
import IconButton from 'components/comm/IconButton'

const IconButtonWithTooltip = (props) => {
  const { name, toolTip, onClick, placement = 'top', widthInPx, tooltipOffset } = props

  return (
    <Tooltip
      title={toolTip}
      placement={placement}
      arrowPointAtCenter
      align={{
        offset: tooltipOffset
      }}
    >
      <span>
        <IconButton name={name} onClick={onClick} widthInPx={widthInPx}/>
      </span>
    </Tooltip>
  )
}

IconButtonWithTooltip.propTypes = {
  toolTip: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  placement: PropTypes.string,
  widthInPx: PropTypes.number,
  tooltipOffset: PropTypes.array
}

export default IconButtonWithTooltip
