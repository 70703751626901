import styled from 'styled-components'

export const CssWrapper = styled.div`
  position: absolute;
  display: flex;
  border: 1px solid #edeeef;
  background: white;
  z-index: 1;
  .group {
    display: flex;
    align-items: center;
  }
`
