import Shape from '../shapes/index.js'

const InitLineWidth = 0.8

class Grid extends Shape {
  constructor(ctx, board, sx, sy, width, height, gridConfig) {
    super(ctx, sx, sy, 0, 0)
    const { size } = gridConfig
    this.setWidth(width).setHeight(height)
    this.board = board
    this.gridSize = size
    this.bigSpan = 4
    this.lc2 = '#cbcccf' // #f6f5f6
    this.lc1 = '#dcdde0'
    this.backGroundLay1 = '#eff0f3' // #f2f2f2 #9297a1
    this.backGroundLay2 = '#eff0f3'
    this.borderWidth = 30
    this.initLineWidth = InitLineWidth
    this.lineWidth = InitLineWidth
    this.showGrid = true
    this.isAlign = true
    this.isAlignNode = true // 都是吸附相关
  }

  process() { // 绘制矩形
    const { showGrid, lineWidth, backGroundLay1, backGroundLay2, borderWidth, lc1, lc2, height, width, bigSpan, gridSize } = this

    // 1. 绘制背景1
    this.ctx.lineWidth = lineWidth
    this.ctx.fillStyle = backGroundLay1
    this.ctx.fillRect(this._sx - borderWidth, this._sy - borderWidth, width + 2 * borderWidth, height + 2 * borderWidth)

    // 2. 绘制背景色2
    this.ctx.fillStyle = backGroundLay2
    this.ctx.fillRect(this._sx, this._sy, width, height)

    if (showGrid) {
      // 3. 采用遍历的方式，绘画第一层x轴的线条
      var xLineTotals = Math.floor(height / gridSize) // 计算需要绘画的x轴条数
      for (let i = 1; i < xLineTotals; i++) {
        if (i % bigSpan === 0) continue
        this.ctx.beginPath() // 开启路径，设置不同的样式
        this.ctx.moveTo(this._sx, gridSize * i - 0.5 + this._sy) // -0.5是为了解决像素模糊问题
        this.ctx.lineTo(width + this._sx, gridSize * i - 0.5 + this._sy)
        this.ctx.strokeStyle = lc1 // 设置每个线条的颜色
        this.ctx.stroke()
      }

      // 4.采用遍历的方式，绘画第一层y轴的线条
      var yLineTotals = Math.floor(width / gridSize) // 计算需要绘画y轴的条数
      for (let j = 1; j < yLineTotals; j++) {
        if (j % bigSpan === 0) continue
        this.ctx.beginPath() // 开启路径，设置不同的样式
        this.ctx.moveTo(gridSize * j + this._sx, this._sy)
        this.ctx.lineTo(gridSize * j + this._sx, height + this._sy)
        this.ctx.strokeStyle = lc1 // 设置每个线条的颜色
        this.ctx.stroke()
      }

      // 5.采用遍历的方式，绘画第二层x轴的线条
      for (let i = bigSpan; i < xLineTotals; i = i + bigSpan) {
        this.ctx.beginPath() // 开启路径，设置不同的样式
        this.ctx.moveTo(this._sx, gridSize * i - 0.5 + this._sy) // -0.5是为了解决像素模糊问题
        this.ctx.lineTo(width + this._sx, gridSize * i - 0.5 + this._sy)
        this.ctx.strokeStyle = lc2 // 设置每个线条的颜色
        this.ctx.stroke()
      }

      // 6.采用遍历的方式，绘画第二层y轴的线条
      for (let j = bigSpan; j < yLineTotals; j = j + bigSpan) {
        this.ctx.beginPath() // 开启路径，设置不同的样式
        this.ctx.moveTo(gridSize * j + this._sx, this._sy)
        this.ctx.lineTo(gridSize * j + this._sx, height + this._sy)
        this.ctx.strokeStyle = lc2 // 设置每个线条的颜色
        this.ctx.stroke()
      }
    }
  }

  updateLineWidth(val) {
    this.lineWidth = val
  }

  updateShowGrid(status) {
    this.showGrid = status
  }

  updateIsAlign(status) {
    this.isAlign = status
  }

  updateIsAlignNode(status) {
    this.isAlignNode = status
  }

  updateGridSize(size) {
    this.gridSize = size
  }

  _isQuickInPath(x, y, gap = 0) { // 判断点是否存在于矩形内
    return x >= this._left - gap && x <= this._right + gap && y >= this._top - gap && y <= this._bottom + gap
  }

  _setStartX(sx) {
    return this.setStartX(sx)._horizontal()
  }
  _setStartY(sy) {
    return this.setStartY(sy)._vertical()
  }
  setStart(sx, sy) {
    return this._setStartX(sx)._setStartY(sy)
  }
  setWidth(width) {
    this.width = width
    return this._horizontal()
  }
  setHeight(height) {
    this.height = height
    return this._vertical()
  }
  _horizontal() {
    if (this.width > 0) {
      this._left = this._sx
      this._right = this._left + this.width
    } else {
      this._right = this._sx
      this._left = this._right + this.width
    }
    this.halfX = this._left + this.width / 2
    return this
  }
  _vertical() {
    if (this.height > 0) {
      this._top = this._sy
      this._bottom = this._top + this.height
    } else {
      this._bottom = this._sy
      this._top = this._bottom + this.height
    }
    this.halfY = this._top + this.height / 2
    return this
  }
}

export default Grid
