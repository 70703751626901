// 形状字段
export const Shapes = {
  Parallelogram: 'Parallelogram',
  RoundRect: 'RoundRect',
  ArcRect: 'ArcRect',
  TrimmingRect: 'TrimmingRect',
  Rect: 'Rect',
  Prismatic: 'Prismatic',
  Hexagon: 'Hexagon',
  HexagonIn: 'HexagonIn'
}
// 连接线字段
export const LinkTypes = {
  curve: 'curve',
  zline: 'zline',
  xline: 'xline',
  radio: 'radio',
  dcurve: 'dcurve'
}

export const HistoryOperation = {
  add: 'add',
  delete: 'delete',
  update: 'update'
}

// note 字体大小配置
export const NoteFontSizes = [4, 8, 10, 12, 14, 18, 24, 32, 48, 64]
export const getNoteFontSizes = () => {
  // return ['auto', ...NoteFontSizes]
  return [...NoteFontSizes]
}

// Note 对齐
export const AliBtnAttrs = [
  {
    icon: 'top-left',
    toolTip: '顶部左对齐'
  },
  {
    icon: 'top-center',
    toolTip: '顶部居中对齐'
  },
  {
    icon: 'top-right',
    toolTip: '顶部右对齐'
  },
  {
    icon: 'center-left',
    toolTip: '中部左对齐'
  },
  {
    icon: 'center-center',
    toolTip: '中部居中对齐'
  },
  {
    icon: 'center-right',
    toolTip: '中部右对齐'
  },
  {
    icon: 'bottom-left',
    toolTip: '底部左对齐'
  },
  {
    icon: 'bottom-center',
    toolTip: '底部居中对齐'
  },
  {
    icon: 'bottom-right',
    toolTip: '底部右对齐'
  }
]
export const DefaultAlign = AliBtnAttrs[4].icon

export const NoteBgColors = ['#f5f6f8', '#fef9b2', '#f5d129', '#f69e49',
  '#d5f692', '#c9df56', '#93d275', '#67c6c1',
  '#facee0', '#ea95bb', '#be89c8', '#f16c7f',
  '#a6ccf6', '#6cd8fb', '#7b92ff', '#000000'
]

export const NoteTagColors = ['#f24727', '#cee741', '#4ccdd4', '#f9c70f',
  '#da0063', '#8fd14f', '#399bf0', '#808080',
  '#9510ac', '#3da789', '#414bb2', '#1a1a1a'
]

