import React from 'react'
import { Select } from 'antd'
import PropTypes from 'prop-types'

const Option = Select.Option

export default function SizeSelect(props) {
  const { values = [], curValue = '', onChange, maxValue } = props

  return (
    <div onWheel={(e) => e.stopPropagation()}>
      <Select
        style={{
          width: 80
        }}
        value={curValue}
        onChange={onChange}
      >
        {values.map((v, index) => (
          <Option value={v} key={index} disabled={v > maxValue}>
            {v.text}
          </Option>
        ))}
      </Select>
    </div>
  )
}

SizeSelect.propTypes = {
  values: PropTypes.array,
  curValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  maxValue: PropTypes.number,
  onChange: PropTypes.func
}
