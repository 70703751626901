import Shape from './index.js'

class Shadow extends Shape {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.setWidth(width).setHeight(height)
    this.shapeRatio = 1
  }
  setShapeRatio(v) { // 形状宽高比例
    this.shapeRatio = v
  }
  initial(sx, sy, ox, oy) { // 初始化画布
    return this.init(sx, sy, ox, oy)
  }
  process() { //
    const { ctx, _sx, _sy, width, height } = this
    const paddingX = width / 20
    let startX = _sx + paddingX
    let endX = _sx + width - paddingX
    ctx.beginPath()
    ctx.moveTo(startX, _sy);
    ctx.lineTo(endX, _sy);
    ctx.lineTo(endX, _sy + height);
    ctx.lineTo(startX, _sy + height);
    ctx.closePath()
  }
  _setStartX(sx) {
    return this.setStartX(sx)
  }
  _setStartY(sy) {
    return this.setStartY(sy)
  }
  setStart(sx, sy) {
    return this._setStartX(sx)._setStartY(sy)
  }
  _setOriginX(ox) {
    return this.setOriginX(ox)
  }
  _setOriginY(oy) {
    return this.setOriginY(oy)
  }
  offset(x, y) {
    let { sx, sy } = this
    this._setStartX(sx += x)._setStartY(sy += y)
  }
  getWidth() {
    return this.width
  }
  setWidth(width) {
    this.width = width
    return this
  }
  getHeight() {
    return this.height
  }
  setHeight(height) {
    this.height = height
    return this
  }
}

export default Shadow
