import CompoundCurveLine from './compoundCurveLine'
import { getInitCurveLinePoints, getCurveLineShowCPs } from './cubicHelper'
import mathHelper from '../../../utils/mathHelper'

// 复合曲线
class CompoundCurveLines {
  constructor(ctx, board, style, data) {
    this.ctx = ctx
    this.board = board
    this.style = style

    this.isControlPointUpdated = false // 有一个变了，就算, 必须在 this.lines上面,这里可能修改了isControlPointUpdated
    this.lines = this.initGroupedEndPoints(data)
    this.touchingIndex = null

    this.lastSplitIndex = null
    this.isSplitMoved = false
  }

  createLine(config) {
    const line = new CompoundCurveLine(
      this.ctx,
      this.board,
      this.style,
      config
    )
    const { p1, p2, cp1, cp2, cp1s, cp2s } = config
    line.updateCurveLine(p1, p2, cp1, cp2, cp1s, cp2s)

    return line
  }

  isControlPointsUpdated() {
    if (this.isControlPointUpdated) {
      return true
    }

    for (const l of this.lines) {
      if (l.isControlPointUpdated) {
        this.updateIsControlPointsUpdated()
      }
    }

    return false
  }

  updateIsControlPointsUpdated() {
    this.isControlPointUpdated = true
  }

  getGroupedPoints(data) {
    const groups = []
    const { start, end, points = [], controlPoints = [] } = data
    const cps = [start, ...points, end]
    const hasCPs = controlPoints.length > 0
    for (let i = 0; i < cps.length - 1; i++) {
      const p1 = cps[i]
      const p2 = cps[i + 1]
      const j = (i + 1) * 2
      const cp1 = hasCPs ? controlPoints[j - 2] : null
      const cp2 = hasCPs ? controlPoints[j - 1] : null

      groups.push({ p1, p2, cp1, cp2 })
    }

    return groups
  }

  // 初始化曲线
  initGroupedEndPoints(data) {
    const groups = this.getGroupedPoints(data)
    const lines = []
    for (let i = 0; i < groups.length; i++) {
      const points = groups[i]
      const { p1, p2, cp1, cp2 } = points

      let line
      if (cp1 && cp2) {
        // 已经存在控制点, 说明是基于历史数据创建的
        const { cp1s, cp2s } = getCurveLineShowCPs(p1, p2, cp1, cp2)
        const config = { p1, p2, cp1, cp2, cp1s, cp2s }
        line = new CompoundCurveLine(this.ctx, this.board, this.style, config)

        // 更新dis
        line.updateControlPointsRDis(cp1, cp2, cp1s, cp2s)
        this.updateIsControlPointsUpdated()
      } else {
        // 新创建的
        line = new CompoundCurveLine(this.ctx, this.board, this.style)
      }

      lines.push(line)
    }

    return lines
  }

  getAllCurves() {
    return this.lines
  }

  getSplitControlPoints(cp1, cp2, p1, p2, cp) {
    const cp1s = mathHelper.getMidPointOfTwoPoints(cp1, p1)
    const cp2s = mathHelper.getMidPointOfTwoPoints(cp2, p2)

    const slope = mathHelper.getSlopeByTwoPoint(cp1s, cp2s)
    // const slope2 = this.getSlopeByPoints(p1, cp1s, cp2s, p2, 0.5);

    const antiSlope = Math.PI + slope

    const gridSize = this.board.gridPage.getGridSize()
    const span = Math.max(
      mathHelper.getDisOfTwoPoints(cp1, cp2) * 0.1,
      gridSize / 2
    )

    const line1_cp1 = cp1s
    const line1_cp1s = mathHelper.getMidPointOfTwoPoints(cp1s, p1)
    const line1_cp2 = mathHelper.getEndPointOfSlash(cp, antiSlope, span * 2)
    const line1_cp2s = mathHelper.getEndPointOfSlash(cp, antiSlope, span)

    const line2_cp2 = cp2s
    const line2_cp2s = mathHelper.getMidPointOfTwoPoints(cp2s, p2)
    const line2_cp1 = mathHelper.getEndPointOfSlash(cp, slope, span * 2)
    const line2_cp1s = mathHelper.getEndPointOfSlash(cp, slope, span)

    return {
      line1_cp1,
      line1_cp1s,
      line1_cp2,
      line1_cp2s,
      line2_cp1,
      line2_cp1s,
      line2_cp2,
      line2_cp2s
    }
  }

  /**
   * @param {*} point
   * @param {*} index: 线段索引
   * 1. 先获取旧曲线的p1, p2, cp1, cp2
   * 2. 删除旧曲线
   * 3. 基于新point 创建两条新曲线
   *    + 第一条的新曲线 createLine(p1, point);
   *    + 第一条的新曲线 的cp1 为 保存的cp1
   *    + 第二条的新曲线 createLine(point, p2);
   *    + 第二条的新曲线 的cp2 为 保存的cp2
   *    + 新曲线的另外两个控制点曲线斜率为(p1, p2)的斜率，长度为（p1, p2）的 长度* 1/5 (比率先猜测的，后面再调整)
   */
  splitCurveLineByIndex(index) {
    const oldLine = this.lines[index]
    const { p1, p2, cp1, cp2 } = oldLine.getCurveLinePoints()
    // const { cp1s, cp2s } = oldLine.getShowControlsPos();
    const point = oldLine.getCurveLineCenterPoint()

    // 获取两个新控制点
    const {
      line1_cp1,
      line1_cp1s,
      line1_cp2,
      line1_cp2s,
      line2_cp1,
      line2_cp1s,
      line2_cp2,
      line2_cp2s
    } = this.getSplitControlPoints(cp1, cp2, p1, p2, point)
    const line1 = this.createLine({
      p1,
      p2: point,
      cp1: line1_cp1,
      cp2: line1_cp2,
      cp1s: line1_cp1s,
      cp2s: line1_cp2s
    })
    const line2 = this.createLine({
      p1: point,
      p2,
      cp1: line2_cp1,
      cp2: line2_cp2,
      cp1s: line2_cp1s,
      cp2s: line2_cp2s
    })

    line1.updateControlPointsRDis(line1_cp1, line1_cp2, line1_cp1s, line1_cp2s)
    line2.updateControlPointsRDis(line2_cp1, line2_cp2, line2_cp1s, line2_cp2s)
    this.lines.splice(index, 1, line1, line2)
  }

  // 拖动中间节点
  dragDragPoint(move, arrowLen) {
    const id = this.touchingIndex
    const arrow = {}
    if (typeof id !== 'number' || id < 1) return arrow

    this.isSplitMoved = true // 标记分割点已经移动

    const firstId = id - 1
    const secondId = id
    const line1 = this.lines[firstId]
    const line2 = this.lines[secondId]
    const { p1, p2: midP } = line1.getCurveLinePoints()
    const { p2 } = line2.getCurveLinePoints()
    const movedMidP = mathHelper.getPointPatchedPos(midP, move)

    const n1s = line1.getUpdateCurveLinePoints(p1, movedMidP)
    line1.updateCurveLine(n1s.p1, n1s.p2, n1s.cp1, n1s.cp2, n1s.cp1s, n1s.cp2s)

    const n2s = line2.getUpdateCurveLinePoints(movedMidP, p2)
    line2.updateCurveLine(n2s.p1, n2s.p2, n2s.cp1, n2s.cp2, n2s.cp1s, n2s.cp2s)

    if (firstId === 0) {
      arrow.start = line1.getArrowSlope(arrowLen, true)
    }

    // end箭头需要变化
    if (secondId === this.lines.length - 1) {
      arrow.end = line2.getArrowSlope(arrowLen, false)
    }

    // 产生分割点拖动,箭头不再自动变化
    this.updateIsControlPointsUpdated()
    this.style.resetControlStyle()

    return arrow
  }

  // 拖动分割点
  splitLine() {
    const id = this.touchingIndex
    if (this.lastSplitIndex !== id) {
      // 之前是防止多次点击，后面看是否可以去掉
      // 分割曲线
      this.lastSplitIndex = id
      this.splitCurveLineByIndex(id)
      this.touchingIndex = id + 1

      this.isSplitMoved = false // 标记分割点是否被移动
    }
  }

  // 合并曲线
  mergeLine() {
    if (this.isSplitMoved) return // 移动过则不需要合并

    const lastId = this.lastSplitIndex
    if (typeof lastId === 'number') {
      const line1 = this.lines[lastId]
      const line2 = this.lines[lastId + 1]

      if (!line1 || !line2) return

      const { p1, cp1: oldCp1 } = line1.getCurveLinePoints() // cp1 已经缩短一半
      const cp1 = mathHelper.getSymmetryPoint(p1, oldCp1)
      const cp1s = oldCp1
      const { p2, cp2: oldCp2 } = line2.getCurveLinePoints()
      const cp2 = mathHelper.getSymmetryPoint(p2, oldCp2)
      const cp2s = oldCp2

      const line = this.createLine({ p1, p2, cp1, cp2, cp1s, cp2s })
      line.updateControlPointsRDis(cp1, cp2, cp1s, cp2s)

      this.lines.splice(lastId, 2, line)
      this.lastSplitIndex = null
      this.touchingIndex = this.touchingIndex - 1 // 恢复拆分时的特殊处理
      this.style.applyChildStyle()
    }
  }

  // 拖动控制点
  dragControlPoint(move, arrowLen, isStartMove) {
    // 确保控制点已经更新过
    this.updateIsControlPointsUpdated()
    const id = this.touchingIndex || 0
    const curveLen = this.lines.length
    const { controlSlope, arrowSlope } = this.lines[id].dragCurveLineControlPoint(
      move,
      arrowLen,
      isStartMove
    )

    // first curve start angle / last curve end angle, 返回需要设置的 new angle
    if ((id === 0 && isStartMove) || (id === curveLen - 1 && !isStartMove)) {
      return arrowSlope
    }

    let secondLine
    if (isStartMove) {
      if (id < 1) return // 异常情况,直接返回
      secondLine = this.lines[id - 1]
    } else {
      if (id > curveLen) return // 异常情况,直接返回
      secondLine = this.lines[id + 1]
    }
    // 计算出第二条曲线控制线应该移动的距离，在重画该曲线
    secondLine.dragCurveLineControlByAnglePoint(
      controlSlope + Math.PI,
      !isStartMove
    )

    this.lastSplitIndex = null
  }

  // 获取前第一个线段
  getFirstLine() {
    return this.lines[0]
  }

  // 获取前最后一个线段
  getLastLine() {
    return this.lines[this.lines.length - 1]
  }

  getCenterPoint() {
    const lines = this.lines
    const tmp = lines.length % 2

    if (tmp) {
      // 奇数
      const index = Math.floor(lines.length / 2)
      const line = lines[index]
      return line.getCurveLineCenterPoint()
    } else {
      const index = lines.length / 2 - 1
      const line = lines[index]
      return line.getCurveLineEndPoint()
    }
  }

  // 修改第一个和最后一个节点, 初始时,需要更新所有节点
  apply(start, end, { initAngles, isInited }) {
    // 控制线
    if (this.lines.length === 1) {
      const line = this.lines[0]
      const { p1, p2, cp1, cp2, cp1s, cp2s } = initAngles
        ? getInitCurveLinePoints(start, end, initAngles)
        : line.getUpdateCurveLinePoints(start, end)

      line.updateCurveLine(p1, p2, cp1, cp2, cp1s, cp2s)
    } else if (isInited) {
      // 重绘第一条曲线
      const firstLine = this.getFirstLine()
      const { p2: oldP2 } = firstLine.getCurveLinePoints()
      const fPs = firstLine.getUpdateCurveLinePoints(start, oldP2)
      firstLine.updateCurveLine(
        fPs.p1,
        fPs.p2,
        fPs.cp1,
        fPs.cp2,
        fPs.cp1s,
        fPs.cp2s
      )

      // 重绘最后一条曲线
      const lastLine = this.getLastLine()
      const { p1: oldP1 } = lastLine.getCurveLinePoints()
      const lPs = lastLine.getUpdateCurveLinePoints(oldP1, end)
      lastLine.updateCurveLine(
        lPs.p1,
        lPs.p2,
        lPs.cp1,
        lPs.cp2,
        lPs.cp1s,
        lPs.cp2s
      )
    } else {
      // 初始化所有曲线参数
      const lines = this.getAllCurves()

      lines.forEach((l) => {
        const { p1: oldP1, p2: oldP2 } = l.getCurveLinePoints()
        const ps = l.getUpdateCurveLinePoints(oldP1, oldP2)
        l.updateCurveLine(ps.p1, ps.p2, ps.cp1, ps.cp2, ps.cp1s, ps.cp2s)
      })
    }
  }

  draw(isShowBackGround) {
    this.lines.forEach((line, index) => {
      line.draw(isShowBackGround, index !== 0)
    })
  }

  // 动态调整控制点大小
  setControlPointCurveHeadSize(size) {
    this.lines.forEach((line) => {
      line.updateControlPointCurveHeadSize(size)
    })
  }

  isEnterBackground(x, y) {
    let isEnter = false

    for (const l of this.lines) {
      if (l.isEnterBackground(x, y)) {
        isEnter = true
        break
      }
    }

    return isEnter
  }

  isTouchingControlPoint(point, gap) {
    let target
    for (let i = 0; i < this.lines.length; i++) {
      if ((target = this.lines[i].isTouchingControlPoint(point, gap))) {
        this.touchingIndex = i
        return target
      }
    }

    return target
  }

  isTouchingDragPoint(point, gap) {
    let target
    for (let i = 1; i < this.lines.length; i++) {
      target = this.lines[i].isTouchingOnDragPoint(point, gap)
      if (target) {
        this.touchingIndex = i
        return target
      }
    }

    return target
  }

  isTouchingSplitPoint(point, gap) {
    let target
    for (let i = 0; i < this.lines.length; i++) {
      target = this.lines[i].isTouchingOnSplitPoint(point, gap)
      if (target) {
        this.touchingIndex = i
        return target
      }
    }

    return target
  }
}

export default CompoundCurveLines
