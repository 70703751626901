import { DefaultAlign, NoteFontSizes } from 'constant'

class Style {
  constructor(target) {
    this.target = target
  }
  apply(style = {}) {
    this.backgroundColor = style.backgroundColor || '#fff'
    this.fontColor = style.fontColor || '#1A1A1A'
    this.fontSize = style.fontSize || NoteFontSizes[NoteFontSizes.length - 4]
    this.fontFamily = style.fontFamily || 'Source Han Sans CN'
    this.fontStyle = style.fontStyle || 'normal'
    this.fontVariant = 'normal'
    this.fontWeight = style.fontWeight || 'normal'
    this.underline = style.underline || 'none'
    this.deleteline = style.deleteline || 'none'
    this.lineWidth = style.lineWidth || 2

    this.paddingLeft = style.paddingLeft || 20
    this.paddingTop = style.paddingTop || 10
    // this.contentLeft = style.contentLeft || 0 // 内容x开始位置
    this.contentTop = style.contentTop || 0 // 内容y开始位置

    this.lineColor = style.lineColor || '#000'
    this.borderOpacity = style.borderOpacity !== void 0 ? style.borderOpacity : 1
    this.lineDash = style.lineDash || []
    this.textOpacity = style.textOpacity || 1
    this.backgroundOpacity = style.backgroundOpacity || 1
    this.textAlign = style.textAlign || DefaultAlign // 整体内容的排列方式 left  middel right
    this.textAngle = style.textAngle || 0
    this.boundaryLineWidth = style.boundaryLineWidth || 8 // 当元素被框选时boundary作为元素的边界线展示
    this.boundaryColor = style.boundaryColor || 'rgba(160, 194, 253, 0.8)' // 当元素被框选时boundary作为元素的边界线展示

    // note 特殊样式
    this.maxFontSize = NoteFontSizes[NoteFontSizes.length - 1]
    this.alignX = this.getSplitAlign(this.textAlign).alignX || 'center'
    this.alignY = this.getSplitAlign(this.textAlign).alignY || 'center'

    // colorText 样式
    this.tagFontSize = 18
    this.tagFontFamily = 'Source Han Sans CN'
    this.tagFontStyle = 'normal'

    // Note阴影
    const scale = this.target.board.getScale()
    const sHeight = this.target.getShadowHeight()
    this.bottomOpacity = 0.5
    this.bottomShadowBlur = scale * sHeight
    this.bottomShadowOffsetY = scale * sHeight
    this.bottomShadowColor = 'rgba(0,0,0,1)'

    // 内容编辑的时候
    this.setLineHeight()
    this.setStyleMap()
    this._apply()
  }

  setStyleMap() { // 特殊样式处理，通常为关联需求
    this.styleMap = {
      'fontSize': (v) => {
        this.fontSize = v
        this.setLineHeight()
      }
    }
  }

  updateAutoFontSize(fontSize) {
    this.changeStyle({ fontSize })
    this.maxFontSize = fontSize
  }

  changeStyle(config) {
    for (const [key, value] of Object.entries(config)) {
      if (this[key] !== void 0) {
        this.styleMap[key] ? this.styleMap[key](value) : (this[key] = value)
      }
    }
    return this
  }

  getSplitAlign(type) {
    const [alignY, alignX] = type.split('-')
    return { alignY, alignX }
  }

  updateNoteAlign(type) {
    this.textAlign = type
    const { alignY, alignX } = this.getSplitAlign(type)
    this.alignY = alignY
    this.alignX = alignX
    return type
  }

  getLineHeightByFontSize(fontSize) {
    const fs = typeof fontSize === 'number' ? fontSize : parseInt(fontSize)
    return Math.floor(fs * 4 / 3)
  }

  setLineHeight() {
    this.lineHeight = Math.floor(this.fontSize * 4 / 3)
  }

  setTextStyle(text) {
    return text.getStyle().setFont(this.font).setFillStyle(this.fontColor).setOpacity(this.textOpacity).setTextAlign('left').setTextBaseline('top')
  }

  setTagTextStyle(tag) {
    return tag.getStyle().setFont(this.tagFont)
  }

  get font() {
    return `${this.fontStyle} ${this.fontVariant} ${this.fontWeight} ${this.fontSize}px \"${this.fontFamily}\"`
  }

  get tagFont() {
    return `${this.tagFontStyle} ${this.tagFontSize}px \"${this.tagFontFamily}\"`
  }

  _apply() {
    const { border, background, boundary, texts, junction, funcLock, tags, shadow } = this.target
    background.getStyle()
      .setLineDash(this.lineDash)
      .setLineWidth(this.lineWidth || 1)
      .setStrokeStyle(this.lineWidth > 0 ? this.lineColor : 'transparent')
      .setFillStyle(this.backgroundColor)
      .setStrokeOpacity(this.borderOpacity)
      .setOpacity(this.backgroundOpacity)
    // 边界线目前只在框选的时候才会出现，还未发现自定义样式，后续需求变动，可添加样式配置
    boundary.getStyle().setLineWidth(this.boundaryLineWidth).setStrokeStyle(this.boundaryColor)
    shadow.getStyle().setShadowBlur(this.bottomShadowBlur).setShadowColor(this.bottomShadowColor)
      .setOpacity(this.bottomOpacity).setShadowOffsetY(this.bottomShadowOffsetY)
    junction.style.apply()
    border.style.apply()
    funcLock.style.apply()
    texts.forEach(t => this.setTextStyle(t))
    tags.forEach(t => this.setTagTextStyle(t))
    this.target._apply() // 调用此方法 针对修改text样式后，需要重新计算文本的大小和高度
  }
}

Style.TagPaddingX = 10
Style.TagPaddingY = 20

export default Style

