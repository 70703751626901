import React from 'react'
import IconButton from 'components/comm/IconButton'
import withClickModal from 'components/comm/hoc/withClickModal'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AliBtnAttrs } from 'constant'

const MCCssWrapper = styled.div`
    .modContent {
      width: 85px;
      background-color: #fafbfc;
      padding: 0;
    }

    .item {
      &:hover {
        background-color: #eaeaea;
      }
    }

    .select {
      border: 1px solid #1071e5;
    }
`

function CustomBtn(props) {
  // eslint-disable-next-line react/prop-types
  const { alignType, ...rest } = props
  const tar = AliBtnAttrs.find((e) => e.icon === alignType)
  const ele = tar || AliBtnAttrs[0]
  return (
    <IconButton name={`ali-${alignType || ele.icon}`} toolTip={'对齐'} {...rest} />
  )
}

function CustomModalContent(props) {
  // eslint-disable-next-line react/prop-types
  const { alignType, onAlignChanged } = props

  return (
    <div className='flexWrap modContent'>
      {AliBtnAttrs.map((e, index) => {
        const selected = e.icon === alignType
        return (
          <IconButton
            key={index}
            name={`ali-${e.icon}`}
            toolTip={e.toolTip}
            className={`item ${selected ? 'select' : ''}`}
            onClick={() => { onAlignChanged && onAlignChanged(e.icon) }}
          />
        )
      }
      )}
    </div>
  )
}

const AlignmentBtn = withClickModal(CustomBtn, CustomModalContent)

function FixAlignmentBtn({ alignType, onAlignChanged }) {
  const btnProps = { alignType }
  const modProps = { alignType, onAlignChanged }
  return (
    <MCCssWrapper>
      <AlignmentBtn btnProps={btnProps} modProps={modProps} />
    </MCCssWrapper>
  )
}

FixAlignmentBtn.propTypes = {
  alignType: PropTypes.oneOf(AliBtnAttrs.map(e => e.icon)),
  onAlignChanged: PropTypes.func
}

export default FixAlignmentBtn
