import styled from 'styled-components'
// import chehui from 'assets/img/icon/chehui.png'

export const NavBarCssWrapper = styled.div`
  border-bottom: 1px solid #D2D2D2;
  box-shadow: 0px 1px 5px rgb(4 0 0 / 10%);
  .dropdown {
    border-radius: 2px;
  }

  .navBarHead {
    width:100%;
    height:60px;
    // border:1px solid red;
    display: flex;
  }

  .navBarHead .iconBtn{
    width:60px;
    height:100%;
    border-right:1px solid #D2D2D2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navBarHead .iconBtn div{
    width: 18px;
    height: 18px;
  }

  .navBarHead .box{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .navBarHead .box .leftBox{
    display: flex;
    align-items: center;
  }

  .navBarHead .box .leftBox .shapeDiv{
    margin-left:58px;
    display: flex;
  }

  .navBarHead .box .leftBox .shapeDiv .shapes{
    margin-right:25px;
    margin-top: 6px;
  }
  .navBarHead .box .leftBox .shapeDiv .shapes:hover{
    cursor:pointer;
  }
  .navBarHead .box .leftBox .shapeDiv .shapes .shapesIcon{
    width:25px;
    height:20px;
    display: flex;
    align-items: center;
    justify-content:center;
    margin: 0 15px;
  }
  .navBarHead .box .leftBox .shapeDiv .shapes .shapesIcon img{
    
  }
  .navBarHead .box .leftBox .shapeDiv .shapes .shapesTitle{
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1C1C1C;
    display: flex;
    align-items: center;
    justify-content:center;
  }
  .navBarHead .box .leftBox .text{
    margin-left:23px;
    margin-right:27px;
  }

  .navBarHead .box  .leftBox .text .name{
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #1C1C1C;
  }
  .navBarHead .box .leftBox  .text .saveTime{
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #909090;
  }

  .navBarHead .box .rightBox{
    display:flex;
    align-items: center;
  }
  .navBarHead .box .rightBox .export{
    width: 80PX;
    height: 32px;
    background: #FFF;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    margin-right:30px;
    display:flex;
    align-items: center;
    justify-content: center;
  }
  .navBarHead .box .rightBox .export:hover{
    cursor:pointer;
  }
  .navBarHead .box .rightBox .export .icon{
    margin-right:9px;
    width: 18px;
    height: 18px;
    margin-top: -7px;
  }
  .navBarHead .box .rightBox .export .text{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #1C1C1C;;
  }
  .navBarHead .box .rightBox .user{
    // width: 35px;
    // height: 35px;
    background: #C9C9C9;
    border-radius: 50%;
    margin-right:30px;
  }
  .navBarHead .box .rightBox .user:hover{
    cursor:pointer;
  }
  .navBarHead .box .rightBox .user img {
    // width: 35px;
    // height: 35px;
    border-radius: 50%;
  }

  .navBarHead  .posiBtn{
    width: 150px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 1px 1px 5px 0px rgba(4, 0, 0, 0.1);
    border-radius: 5px;
    position:fixed;
    z-index:22;
    display:flex;
    align-itmes:center;
    top:81px;
    left:20px;
  }
  .navBarHead  .posiBtn .boxBtn{
    display:flex;
    align-items:center;
    justify-content:center;
  }
  .navBarHead  .posiBtn .boxBtn .btnIcon{
    width:18px;
    height: 18px;
  }
  .navBarHead  .posiBtn .save{
    width:50px;
    height: 40px;
    border-right: 1px solid #e3e3e3;
  }
  .navBarHead  .posiBtn .undo{
    width:50px;
    height: 40px;
  }
  .navBarHead  .posiBtn .undo:hover{
    width:50px;
    height: 40px;
    
  }
  .navBarHead  .posiBtn .redo{
    width:50px;
    height: 40px;
  }
`
