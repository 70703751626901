/*
  用于绘制带圆弧的折线
 */
import Shape from './index.js'

class FoldLine extends Shape {
  constructor(ctx, middles, sx, sy, ex, ey, ox, oy) { // middles: 中间点集合
    super(ctx, sx, sy, ox, oy)
    this.ex = ex
    this.ey = ey
    this.middles = middles // 中间点的集合
  }
  process() {
    const { ctx, middles, sx, sy, ex, ey } = this
    const start = { x: sx, y: sy }; const end = { x: ex, y: ey }
    let next = middles[0] || end; let pre = start

    ctx.beginPath()
    ctx.moveTo(start.x, start.y)

    middles.forEach((p, i) => {
      next = middles[i + 1] ?? end
      if (p.distance(next) < 8) {
        ctx.arcTo(p.x, p.y, next.x, next.y, 0.5)
      } else {
        ctx.arcTo(p.x, p.y, next.x, next.y, p.distance(pre) < 8 ? 0.5 : 5)
      }
      pre = p
    })
    // 判断开始点和结束点的位置
    ctx.lineTo(end.x, end.y)
  }

  setEnd(end) {
    this.ex = end.x
    this.ey = end.y
    return this
  }
  setStart(start) {
    this.sx = start.x
    this.sy = start.y
    return this
  }
  setMiddles(value) {
    this.middles = value || []
    return this
  }

  patchShape(move) {
    this.sx += move.x
    this.sy += move.y
    this.ex += move.x
    this.ey += move.y

    this.middles.forEach(m => {
      m.x += move.x
      m.y += move.y
    })
  }

  getShapePatch() {
    let x = this.sx < this.ex ? this.sx : this.ex
    let y = this.sy < this.ey ? this.sy : this.ey

    this.middles.forEach(m => {
      if (m.x < x) {
        x = m.x
      }

      if (m.y < y) {
        y = m.y
      }
    })

    return { x, y }
  }
}
export default FoldLine
