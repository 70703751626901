/*
  表示决定的icon 圆形状
 */
import Rect from '../../shapes/rect'
class CircleV2 extends Rect {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.width = width || 100
    this.height = height || 100
  }

  offset(offsetX, offsetY) {
    this.sx += offsetX
    this.sy += offsetY
  }

  process() {
    const { ctx, width, height, sx, sy } = this
    const center = { x: sx, y: sy }
    ctx.beginPath()
    ctx.ellipse(center.x, center.y, width / 2, height / 2, 0, 0, 2 * Math.PI)
  }
}
export default CircleV2
