import { Tooltip } from 'antd'
import React from 'react'
import PropTypes from 'prop-types'

const ColorButtonWithTooltip = (props) => {
  const { color = 'black', toolTip, onClick } = props

  return (
    <Tooltip title={toolTip} arrowPointAtCenter>
      <div onClick={onClick} style={{
        backgroundColor: color,
        height: '24px',
        width: '24px',
        cursor: 'pointer',
        borderRadius: '12px'
      }}>
      </div>
    </Tooltip>
  )
}

ColorButtonWithTooltip.propTypes = {
  color: PropTypes.string,
  toolTip: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool
}

export default ColorButtonWithTooltip
