import Grid from './grid'

const DotColor = '#c4c4c4'

class DotGrid extends Grid {
  // eslint-disable-next-line no-useless-constructor
  constructor(ctx, board, sx, sy, width, height, gridConfig) {
    super(ctx, board, sx, sy, width, height, gridConfig)
  }

  getViewScaleRatio() {
    const gc = this.board
    const scale = gc.transform.scale

    const base = 0.5 * this.board.$dpr
    let number = 0
    let i = 0
    let a = 0
    for (let cur = base; ; cur = cur / 2) {
      if (scale > cur) {
        if (scale > 0.5 * this.board.$dpr) {
          a = (0.7 * this.board.$dpr) - scale
        } else if (scale > 0.25 * this.board.$dpr) {
          a = (0.5 * this.board.$dpr) - scale
        } else if (scale > 0.125 * this.board.$dpr) {
          a = (0.3 * this.board.$dpr) - scale
        } else if (scale > 0.0625 * this.board.$dpr) {
          a = (0.125 * this.board.$dpr) - scale
        }
        number = a
        break
      }
      i++
    }

    return { ratio: Math.pow(2, i), number: number }
  }

  getNormalCoord(v, isX, ratio = 1) {
    const gs = this.gridSize * ratio
    const initV = isX ? this._sx : this._sy // + 2
    return v - ((v - initV + gs / 2) % gs) + gs / 2
  }

  getSnapPoint(refPoint, left, right, top, bottom) {
    const newP = { ...refPoint }
    const move = { x: 0, y: 0 }
    const x1 = this.getNormalCoord(left, true)
    const x2 = this.getNormalCoord(right, true)

    if (Math.abs(x1 - left) < Math.abs(x2 - right)) {
      move.x = (x1 - left)
      newP.x += (x1 - left)
    } else {
      move.x = (x2 - right)
      newP.x += (x2 - right)
    }

    const y1 = this.getNormalCoord(top, false)
    const y2 = this.getNormalCoord(bottom, false)

    if (Math.abs(y1 - top) < Math.abs(y2 - bottom)) {
      move.y = (y1 - top)
      newP.y += (y1 - top)
    } else {
      move.y = (y2 - bottom)
      newP.y += (y2 - bottom)
    }

    return { snapP: newP, move }
  }

  process() { // 绘制
    const { showGrid, gridSize } = this

    // // 1. 绘制背景1
    // this.ctx.lineWidth = lineWidth
    // this.ctx.fillStyle = backGroundLay1
    // this.ctx.fillRect(this._sx - borderWidth, this._sy - borderWidth, width + 2 * borderWidth, height + 2 * borderWidth)

    // // 2. 绘制背景色2
    // this.ctx.fillStyle = backGroundLay2
    // this.ctx.fillRect(this._sx, this._sy, width, height)

    // 3. 绘制点
    if (showGrid) {
      const { ratio, number } = this.getViewScaleRatio()
      const { tLPoint: tl, bRPoint: br } = this.board.operateBoard.getViewEdgePoints()
      const tLPoint = this.board.getDrawCoordinate(tl.x, tl.y - 100)
      const bRPoint = this.board.getDrawCoordinate(br.x, br.y)

      const startX = this.getNormalCoord(tLPoint.x, true, ratio)
      const endX = this.getNormalCoord(bRPoint.x, true, ratio)
      const startY = this.getNormalCoord(tLPoint.y, false, ratio)
      const endY = this.getNormalCoord(bRPoint.y, false, ratio)
      const scale = this.board.transform.scale
      const drawGridSize = gridSize * ratio
      const radius = scale > 0.7 * this.board.$dpr ? ratio : ratio + number
      this.ctx.save()
      this.ctx.beginPath()
      this.ctx.fillStyle = DotColor
      for (let i = startX; i < endX; i += drawGridSize) { // 行
        const scaleX = i * scale
        for (let j = startY; j < endY; j += drawGridSize) {
          const scaleY = j * scale
          if (this.board.transform.lastScaleBoard) { // 动画缩放中最后一次缩放
            if (scaleY % 1 !== 0) j = parseFloat((Math.ceil(scaleY) / scale).toFixed(5))
            if (scaleX % 1 !== 0) i = parseFloat((Math.ceil(scaleX) / scale).toFixed(5)) // 非整数需要转换下坐标，避免图形不在像素点上，导致模糊
          }
          this.ctx.moveTo(i, j)
          this.ctx.arc(i, j, radius, 0, Math.PI * 2)
        }
      }
      this.ctx.fill()
    }
  }
}

export default DotGrid
