import {
  CHANGE_NODE_STYLE,
  CHANGE_NODE_LOCK,
  CHANGE_NODE_ATTR
} from '../mutation-type'

const changeNodeStyle = (value) => {
  return {
    type: CHANGE_NODE_STYLE,
    value
  }
}

const changeNodeAttr = (value) => {
  return {
    type: CHANGE_NODE_ATTR,
    value
  }
}

const changeNodeLock = (value) => {
  return {
    type: CHANGE_NODE_LOCK,
    value
  }
}

export {
  changeNodeLock,
  changeNodeStyle,
  changeNodeAttr
}
