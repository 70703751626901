import { getFilteredTagsByTagIds } from 'utils/noteHelper'
import VNode from '..'
import Text from '../../shapes/text'
import Shadow from '../../shapes/shadow'
import ColorText from './colorText'
import Style from './style'
import Border from './border'
import utils from 'drawBoard/utils'

class VNote extends VNode {
  constructor($ctx, board, { type, action, x, y, id, value, tagIds = [], width, height, shapeInfo }) {
    super($ctx, board, { type, action, x, y, id, value, width, height, shapeInfo })
    this.style = new VNote.Style(this)
    this.subName = 'note'
    this.border = new Border(this.$ctx, this)
    this.tagIds = tagIds
    this.tagsNum = 0
    this.tagHeight = 0
    this.tags = this.getTags(tagIds)
    this.shadow = new Shadow($ctx, 0, 0, 0, 0)

    // 限制
    this.minWidth = 200
    this.minHeight = 80
  }
  apply() { // 初始化设置node相关属性位置
    const { x, y, height } = this
    this.background.setStart(x, y)
    this.boundary.setStart(x, y)
    this.shadow.setStart(x, y + height - this.getShadowHeight())
    this.calculateSize()
    this.border.apply()
    this.funcLock.apply() // 功能锁

    this.updateTextPos()

    this.tags.forEach(t => {
      t.setStart(t.rSx + x, t.rSy + y + height)
    })

    this.junction.apply()
    this.isInit = false
  }

  getContainerHeight() {
    const { height, tagHeight, tagsNum, style } = this
    return height - tagHeight * tagsNum - style.paddingTop * 2
  }

  draw() { // 绘制节点
    if (this.isFrameSelected && !this.isMoving) this.boundary.stroke()
    this.shadow.fill()
    this.background.fill()

    for (let i = 0, length = this.values.length; i < length; i++) {
      this.texts[i].fill(this.style)
    }

    this.tags.forEach(tag => {
      tag.fill()
    })

    if (!this.isMoving && !this.funcLock.isLocked) {
      this.junction.draw()
    }
    this.isSelected && this.funcLock.draw() // 绘制功能锁

    if (this.isClickSelected && !this.funcLock.isLocked) {
      this.border.draw() // 选中高亮
    }
  }

  updateTextPos() {
    const { x, y, width, style } = this
    const { lineHeight, paddingLeft, paddingTop, alignX, alignY } = style
    const containerHeight = this.getContainerHeight()
    let emptyHeight = containerHeight - this.getTextHeight(this.texts.length)
    emptyHeight = emptyHeight < 0 ? 0 : emptyHeight

    let textY = y + paddingTop
    if (alignY === 'top') {
      textY += 0
    } else if (alignY === 'center') {
      textY += emptyHeight / 2
    } else if (alignY === 'bottom') {
      textY += emptyHeight
    }

    this.texts.forEach((text, i) => {
      let textX = x
      if (alignX === 'left') {
        textX = x + paddingLeft
      } else if (alignX === 'center') {
        textX = x + (width - text.getWidth()) / 2
      } else if (alignX === 'right') {
        textX = x + width - text.getWidth() - paddingLeft
      }

      if (i > 0) {
        textY += lineHeight
      }
      text.setStart(textX, textY)
    })
  }

  checkNewSizeIsValid(cWidth, cHeight) {
    const ccHeight = cHeight || this.height
    const isWidthValid = cWidth >= this.minWidth
    const isValid = cHeight ? (isWidthValid || ccHeight >= this.minHeight) : isWidthValid
    if (!isValid) return { isValid }
    const { value, style } = this
    const { lineHeight, paddingLeft, font } = style
    const values = []
    const containerHeight = this.getContainerHeight()
    const totalRowCount = Math.floor((containerHeight / lineHeight))
    let patchRowNum = 0

    let aw = 0
    let cursor = 0
    const cw = cWidth - paddingLeft * 2
    let yNumber = 1

    for (let i = 0, length = value.length; i < length; i++) {
      const c = value[i]
      const w = utils.measureTextWidth(c, font)

      const isEnter = c === '\n'
      const isNext = aw + w > cw || isEnter
      if (isNext) {
        yNumber++
        values.push({ text: value.substring(cursor, i), width: aw })
        cursor = isEnter ? i + 1 : i
        aw = isEnter ? 0 : w

        if (yNumber >= totalRowCount) {
          if (cHeight) {
            return { isValid: false }
          } else {
            patchRowNum = yNumber - totalRowCount
          }
        }
      } else {
        aw += w
      }
      if (i === length - 1) {
        values.push({ text: value.substring(cursor, length), width: aw })
      }
    }

    const pathHeight = patchRowNum * lineHeight
    return { isValid, pathHeight }
  }

  getPatchNoteHeight(count) {
    return count * this.style.lineHeight
  }

  // 判断新的大小是否可以包含所有文字
  checkNewSizeConfigIsValid(config) {
    if (config.width && config.height) {
      return this.checkNewSizeIsValid(config.width, config.height)
    } else if (config.width) {
      return this.checkNewSizeIsValid(config.width)
    } else if (config.height) {
      const minHeight = this.getTextHeight(this.texts.length) + this.style.paddingTop * 2
      return { isValid: config.height >= minHeight }
    } else {
      return { isValid: true }
    }
  }

  updateTagIds(tagIds) {
    this.tagIds = tagIds
    this.tags = this.getTags(tagIds)
  }

  getTags(tagIds) {
    const tags = getFilteredTagsByTagIds(tagIds)
    return tags.map(t => (new ColorText(this.$ctx, 0, 0, t.text, { backgroundColor: t.color })))
  }
  updateTagsRelativePos() {
    const tagsLen = this.tags.length

    this.tagsNum = tagsLen > 0 ? 1 : 0
    if (tagsLen === 0) return
    const tagHeight = this.tags[0].getHeight()
    this.tagHeight = tagHeight

    const padX = VNote.Style.TagPaddingX
    const padY = VNote.Style.TagPaddingY

    let startX = padX
    let startY = -padY

    this.tags.forEach(t => {
      const curWidth = t.getWidth()
      if (startX + curWidth > this.width) {
        startX = padX
        startY -= tagHeight
        this.tagsNum++
      }

      t.setRelativeStart(startX, startY)
      startX += curWidth
    })
  }

  getValues() {
    const value = this.value // 当前的值
    const values = this.values // 多行文本
    const { style } = this
    const contentWidth = this.maxWidth - style.paddingLeft * 2
    let length
    let width = 0
    let cursor = 0
    let i, c, w
    values.length = 0
    for (i = 0, length = value.length; i < length; i++) {
      c = value[i]
      // 如果有换行
      if (c === '\n') {
        values.push({ text: value.substring(cursor, i), width })
        cursor = i + 1
        width = 0 // 换行后重置宽度
      } else {
        w = utils.measureTextWidth(c, style.font)
        if ((width + w) > (contentWidth)) {
          values.push({ text: value.substring(cursor, i), width })
          cursor = i
          width = w
        } else {
          width += w
        }
      }
    }
    values.push({ text: value.substring(cursor), width })
    this.textHeight = this.getTextHeight(this.values.length)
    return { values }
  }

  getTextHeight(rowNumber) {
    const { fontSize, lineHeight } = this.style
    return fontSize + (rowNumber - 1) * lineHeight
  }

  updateTexts() {
    this.texts = []
    // 根据现有需求 暂时去掉字体高度和宽度
    const { values } = this.getValues()
    values.forEach(v => {
      const text = new Text(this.$ctx, 0, 0, v.text)
      this.style.setTextStyle(text)
      this.texts.push(text)
    })
    if (this.isInit) { // 如果是初始化
      this.setIsIntelHeight()
    }
    // 在初始化或者文本的高度小于节点的高度时，节点的高度由内容自动撑起来
    if (this.isIntelHeight && this.isEditing) {
      const restContentHeight = this.getContainerHeight() - this.textHeight

      if (restContentHeight < 0) { // 内容高度大于等于节点高度，自动撑起节点，内容在增加
        const move = { x: this.x, y: this.y, gapX: 0, gapY: -restContentHeight }
        if (move.gapY) this.operateNode._resizeOperation(this, move)
      }
    }
  }
  _apply() { // 在修改完node样式时，根据新的样式重新计算生成text
    this.tags.forEach(tag => {
      this.style.setTagTextStyle(tag)
    })

    this.updateTagsRelativePos()
    this.updateTexts()
    this.updateTextPos()
  }
  getShadowHeight() {
    return this.height / 20
  }
  calculateSize() {
    const { paddingTop } = this.style
    const containerHeight = this.getContainerHeight()
    const shadowHeight = this.getShadowHeight()
    this.style.contentTop = (containerHeight - this.textHeight) / 2 + paddingTop
    this.background.setWidth(this.width).setHeight(this.height)
    this.boundary.setWidth(this.width).setHeight(this.height)
    this.picture && this.picture.setWidth(this.width).setHeight(this.height)
    this.shadow.setWidth(this.width).setHeight(shadowHeight)
    this.updateShadowSize()
  }
  onscale() { // 画板缩放后的回调 需要对特别图案进行处理
    this.border.onscale()
    this.junction.onscale()
    this.updateShadowSize()
  }
  updateShadowSize() {
    const scale = this.board.getScale()
    this.style.bottomShadowBlur = this.getShadowHeight() * scale
    this.style.bottomShadowOffsetY = this.getShadowHeight() * scale
    this.style._apply()
  }
  setIsIntelHeight() { // 设置高度根据内容自适应开关
    const contentHeight = this.getContainerHeight()
    return this.isIntelHeight = this.textHeight <= contentHeight
  }
  getTopCenterPos() {
    return {
      x: this.x + this.width / 2,
      y: this.y - 30
    }
  }
  getBottomCenterPos() {
    return {
      x: this.x + this.width / 2,
      y: this.y + this.height + 130
    }
  }
}

VNote.Style = Style
export default VNote
