/*
  表示多个文件的icon
 */
// import Document from './document'
import Process from './process.js'
class MultiDocument extends Process {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    // this.document = new Document(ctx, 0, 0, 0, 0, ox, oy)
    this.setWidth(width).setHeight(height)
    this.arcRadius = 1.5
  }
  process() { // 绘制菱形
    const { ctx, _sx, _sy, _right, _bottom, height, width, arcRadius } = this
    const gapX = width / 20
    const gapY = height / 12
    ctx.beginPath()
    ctx.lineWidth = 1
    const fls = { x: _sx, y: _bottom - height / 3 } // frist line start point
    ctx.moveTo(_sx, _bottom - height / 3)
    ctx.arcTo(_sx, _sy, _right - arcRadius - gapX * 2, _sy, arcRadius)
    ctx.arcTo(_right - gapX * 2, _sy, _right - gapX * 2, _sy + arcRadius, arcRadius)
    ctx.lineTo(_right - gapX * 2, _sy + gapY)
    // 绘制两条弧线的左侧连接线
    ctx.moveTo(fls.x - 0.25, fls.y - 0.3)
    ctx.lineTo(fls.x + gapX, fls.y + gapY / 2)
    // ctx.stroke()
    // 绘制第二条弧线
    const sls = { x: fls.x + gapX, y: fls.y + gapY } // send line Point start
    ctx.moveTo(sls.x, sls.y)
    ctx.arcTo(sls.x, _sy + gapY, _right - gapX, _sy + gapY, arcRadius)
    ctx.arcTo(_right - gapX, _sy + gapY, _right - gapX, _sy + arcRadius + gapY, arcRadius)
    ctx.lineTo(_right - gapX, _sy + gapY * 2)
    // 绘制第二条弧线和文档icon的左侧连接线
    ctx.moveTo(sls.x - 0.25, sls.y - 0.35)
    ctx.lineTo(sls.x + gapX, sls.y + gapY / 2)
    ctx.stroke()
    // this.document.setStart(_sx + gapX * 2, _sy + gapY * 2).setWidth(width - gapX * 2).setHeight(height - gapY * 2)
    // this.document.process()

    const cLeft = _sx + gapX * 2
    const cTop = _sy + gapY * 2
    const cWidth = width - gapX * 2
    const cHeight = height - gapY * 2
    const cRight = cLeft + cWidth
    const cBottom = cTop + cHeight

    const gap = height * 1 / 5
    const curLineEnd = { x: cLeft, y: cBottom - gap }
    const curLineStart = { x: cRight, y: cBottom }
    const point2 = { x: cLeft + Math.ceil(width / 2), y: cBottom + gap * 2 }
    const point1 = { x: cRight - Math.ceil(width / 2), y: cBottom - gap * 3 }
    // 绘制贝塞尔
    ctx.beginPath()
    ctx.moveTo(curLineEnd.x, curLineEnd.y + 1 / 2)
    ctx.arcTo(cLeft, cTop, cRight, cTop, arcRadius)
    ctx.arcTo(cRight, cTop, cRight, cBottom, arcRadius)
    ctx.lineTo(curLineStart.x, curLineStart.y + 1 / 2)
    ctx.bezierCurveTo(point1.x, point1.y, point2.x, point2.y, curLineEnd.x, curLineEnd.y)
  }

  getShapePatch() {
    return {
      x: this._sx,
      y: this._sy
    }
  }

  patchShape(move) {
    this._sx += move.x
    this._right += move.x
    this._sy += move.y
    this._bottom += move.y
  }
}

export default MultiDocument
