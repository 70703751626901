import { initSelectObject } from '../state'
import {
  CHANGE_SELECT_OBJECT,
  CHANGE_SELECT_OBJECT_VERSION,
  CHANGE_NODE_STYLE,
  CHANGE_NODE_LOCK,
  CHANGE_NODE_ATTR
} from '../mutation-type'

const configReducer = (state = initSelectObject, action) => {
  switch (action.type) {
    case CHANGE_SELECT_OBJECT: { // 更新选中的对象
      return {
        ...initSelectObject,
        activeNode: action.value || initSelectObject.activeNode,
        activeNodeVersion: 0
      }
    }

    case CHANGE_SELECT_OBJECT_VERSION: { // 更新选中的对象版本
      return {
        ...state,
        activeNodeVersion: ++state.activeNodeVersion
      }
    }

    case CHANGE_NODE_STYLE: // 更新选中的对象属性
      return {
        ...state,
        activeNode: {
          ...state.activeNode,
          style: {
            ...state.activeNode.style,
            ...action.value
          }
        }
      }

    case CHANGE_NODE_LOCK: // 更新选中的对象的锁定状态
      return {
        ...state,
        activeNode: {
          ...state.activeNode,
          funcLock: {
            lockType: action.value
          }
        }
      }

    case CHANGE_NODE_ATTR:
      return {
        ...state,
        activeNode: {
          ...state.activeNode,
          ...action.value
        }
      }
    default:
      return state
  }
}

export default configReducer
