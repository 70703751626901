/*
  表示文件的icon
 */
import Process from './process.js'
class Document extends Process {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.setWidth(width).setHeight(height)
    this.arcRadius = 1.5
  }
  initial(sx, sy, ox, oy) { // 初始化画布
    return this.init(sx, sy, ox, oy)._horizontal()._vertical()
  }
  process() { // 绘制菱形
    const { ctx, _left, _top, _right, _bottom, height, width, arcRadius } = this
    const gap = height * 1 / 5
    const curLineEnd = { x: _left, y: _bottom - gap }
    const curLineStart = { x: _right, y: _bottom }
    const point2 = { x: _left + Math.ceil(width / 2), y: _bottom + gap * 2 }
    const point1 = { x: _right - Math.ceil(width / 2), y: _bottom - gap * 3 }
    // 绘制贝塞尔
    ctx.beginPath()
    ctx.moveTo(curLineEnd.x, curLineEnd.y + 1 / 2)
    ctx.arcTo(_left, _top, _right, _top, arcRadius)
    ctx.arcTo(_right, _top, _right, _bottom, arcRadius)
    ctx.lineTo(curLineStart.x, curLineStart.y + 1 / 2)
    ctx.bezierCurveTo(point1.x, point1.y, point2.x, point2.y, curLineEnd.x, curLineEnd.y)
  }
  _isQuickInPath(x, y) { // 判断点是否存在于矩形内
    return x >= this._left && x <= this._right && y >= this._top && y <= this._bottom
  }
  _isQuickInStroke(x, y) { // 判断点是否存在线条上
    const { lineWidth } = this.sty
    const { _left, _right, _top, _bottom } = this
    return (
      (0 === Math.round((x - _left) / lineWidth) || 0 === Math.round((x - _right) / lineWidth)) &&
      (0 === Math.round((y - _top) / lineWidth) || 0 === Math.round((y - _bottom) / lineWidth)))
  }
}

export default Document
