import { useState } from 'react'
import { useBoardContext } from 'views/board/hooks'

export const useLock = (activeNode) => {
  const { board } = useBoardContext()
  // lock
  const { funcLock } = activeNode
  const [isLock, setIsLock] = useState(funcLock.lockType.all)
  const updateLockStatus = () => {
    const status = !isLock
    setIsLock(status)
    board.api.changeNodeLock({
      all: status,
      content: status,
      size_position: status,
      style: status
    })
  }

  return { updateLockStatus, isLock }
}
