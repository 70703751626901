import React, { useState, useRef, useEffect, useCallback } from 'react'
import { LeftPanelsCssWrapper } from './style'
import List from './list'
import IconButtonWithTooltip from 'components/antd/ToolTip/IconButtonWithTooltip'
import { useBoardContext } from 'views/board/hooks'

import Upload from 'utils/upload'
import { UPLOAD_PIC } from '../../api'

const upload = new Upload({
  fileSize: 20 * 1024 * 1024, // 上传图片最大大小
  fileType: '|jpg|jpeg|png|webp'
})
const TabItems = [
  {
    id: 'menu-left-Shape',
    src: 'shapePng',
    name: '形状'
  },
  {
    id: 'menu-left-Line',
    src: 'lianjiexian',
    name: '连接线'
  },
  {
    id: 'menu-left-Text',
    name: '文本',
    src: 'textPng'
  },
  {
    id: 'menu-left-Note',
    name: '便签',
    src: 'note'
  },
  // {
  //   id: 'menu-left-Frame',
  //   name: '容器',
  //   src: 'frame'
  // },
  {
    id: 'menu-left-Upload',
    name: '上传',
    src: 'uploadPng'
  }
]

export default function LeftPanels(props) {
  const [showId, setShowId] = useState('')
  const fileEl = useRef(null)
  const expandPanelEl = useRef(null)
  const { board } = useBoardContext()

  useEffect(() => {
    const click = (event) => {
      if (expandPanelEl.current.needClose) {
        showId && setShowId('')
      }
      expandPanelEl.current.needClose = true
    }
    document
      .getElementById('drawBoardContainer')
      .addEventListener('click', click)

    return () => {
      document
        .getElementById('drawBoardContainer')
        ?.removeEventListener('click', click)
    }
  })

  const changeShowContent = (showId) => {
    expandPanelEl.current.needClose = true
    if (showId === 'menu-left-Upload') {
      fileEl.current.click()
      // TODO 完善图片上传
    }
    setShowId(showId)
  }

  const handleImageChange = (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    e.target.value = ''
    const handleUpload = upload.handleBeforeUpload(file)

    if (handleUpload.status) {
      upload.uploadPicFile(file, UPLOAD_PIC).then((res) => {
        if (res.status === 200) {
          board.api.setPicture(res.data.url)
        } else {
          console.log('上传错误!')
        }
      })
    }
  }

  const closeExpandTool = () => {
    setShowId('')
  }

  const handleMove = useCallback((event) => {
    event.stopPropagation()
    event.preventDefault()
  }, [])

  return (
    <LeftPanelsCssWrapper onMouseMove={handleMove}>
      <div className='tab'>
        {TabItems.map((item) => (
          <div
            className={'category'}
            key={item.id}
            id={item.id}
            onClick={() => changeShowContent(item.id)}
          >
            <button className={showId === item.id ? 'active' : 'tabBtn'}>
              <IconButtonWithTooltip
                name={item.src}
                fill='#000'
                selected={true}
                toolTip={item.name}
                widthInPx={20}
                placement={'left'}
                tooltipOffset={[-10, 0]}
              />
            </button>
          </div>
        ))}
      </div>

      <div
        ref={expandPanelEl}
        className={ showId === 'menu-left-Shape' ? 'tabcontent' : 'hidden' }
      >
        <List
          expandPanelEl={expandPanelEl.current}
          closeExpandTool={closeExpandTool}
        />
      </div>

      <div className='insertFile'>
        <input
          onChange={(e) => {
            handleImageChange(e)
          }}
          ref={fileEl}
          type='file'
          accept='image/*'
          style={{ display: 'none' }}
        />
      </div>
    </LeftPanelsCssWrapper>
  )
}
