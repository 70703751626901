/* eslint-disable eqeqeq */
import Helper from 'utils/helper'
class OperateGroup {
  constructor(ctx, board, groups) {
    this.ctx = ctx
    this.board = board
    this.init(groups)
  }

  init(data) {
    this.groups = this.board.groups = {}
    if (data && Object.prototype.toString(data) === '[object Object]' && Object.keys(data).length) {
      for (const c of Object.values(data)) {
        this._addOperation([this.board._gcGroup(c)])
      }
    }
  }

  _addOperation(groups) {
    groups.forEach((group) => {
      this.groups[group.id] = group
    })
    return groups[0]
  }

  _clear() {
    for (const id in this.groups) {
      this.groups[id].unselect('')
    }
  }

  add(group) {
    const groups = Array.isArray(group) ? group : [group]
    return this._addOperation(groups)
  }

  apply() {
    for (const g of Object.values(this.groups)) {
      g.apply()
    }
  }

  eventdrag() {
    for (const group in this.groups) {
      this.groups[group].eventdrag()
    }
  }

  eventup() {
    for (const group in this.groups) {
      this.groups[group].eventup()
    }
  }

  eventmove(point, mouse, link, node) { // point: 鼠标对应的画点 mouse: 鼠标点
    for (const group of Object.values(this.groups)) {
      group.eventmove(point, mouse, link)
    }
  }

  drag(x, y) {
    this.board.selector.selected.map((selNode) => {
      const group = this.getGroup(selNode.id)
      group && group.drag(x, y)
    })
  }

  draw() {
    for (const group of Object.values(this.groups)) {
      group.draw()
    }
  }

  delete(sels) {
    sels.forEach((sel) => {
      const group = this.getGroup(sel.id)
      group && delete this.board.groups[group.id]
    })
  }

  multiUnSelect(aasetIds) {
    aasetIds.forEach((id) => {
      this.getGroup(id)?.unselect('')
    })
  }

  // 对元素进行编组
  setGroup() {
    const sels = this.board.selector.selected
    const members = {}

    sels.forEach((n) => {
      const group = this.getGroup(n.id)
      // 目前还没有二级组合概念，如果框选的元素已经有了组需要先解组再组合
      group && delete this.board.groups[group.id]
      members[n.id] = true
    })

    // 计算组合的x,y坐标以及宽高
    const { left, right, top, bottom } = Helper.calculatePosition(sels)

    // 创建组合
    this.add(
      this.board._gcGroup({
        type: 'group',
        id: Helper.produceNanoId(),
        x: left,
        y: top,
        width: right - left,
        height: bottom - top,
        members
      })
    ).setSelectType('click')
    // 隐藏框选
    this.board.box.hide()
  }
  // 通过节点id 找到组
  getGroup(id) {
    let group
    for (const groupId in this.groups) {
      if (this.groups[groupId].members[id]) {
        group = groupId; break
      }
    }
    return this.groups[group]
  }

  getGroupMembers(id) {
    const members = new Set([id])
    const group = this.getGroup(id)
    if (group) {
      for (const n in group.members) { members.add(n) }
    }
    return Array.from(members)
  }

  // 解除组合
  disaggregateGroup() {
    this.board.selector.selected.forEach((n) => {
      const group = this.board.operateGroup.getGroup(n.id)
      group && delete this.board.groups[group.id]
    })
    // 解除框选后重新调整框选的状态
    this.board.box.calculateSize()
    this.board.box.show()
  }

  selectGroup(group) {
    // 处理一个组合选中后，再选中一个新的组合需要先清空选中
    !this.board.box.isVisible && this.board.selector._clear()
    // 处理组合节点点击的情况
    const selects = []
    for (const n of Object.values(this.board.nodes)) {
      if (group.members[n.id]) selects.push(n)
    }
    for (const k of Object.values(this.board.links)) {
      if (group.members[k.id]) selects.push(k)
    }
    // 处理组合节点+未组合节点+框选+点击组合节点的情况
    this.board.selector.selected.forEach((n) => {
      if (n.selectType != 'click' && !group.members[n.id]) { selects.push(n) }
    })
    group.selectType != 'frame' && group.setSelectType('click')
    this.board.selector.multiSelect(selects)
    selects.forEach((n) => {
      n.setSelectType('frame')
    })
  }
}
export default OperateGroup
