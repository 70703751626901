import VNode from '.'
import VNote from './note'

const createNode = (ctx, board, data = {}) => {
  const { x, y, shapeInfo } = data
  if (shapeInfo) {
    // 计算节点初始大小
    let { width } = shapeInfo
    const { ratio } = shapeInfo
    width = width * board.grid.baseSize
    data = { ...data, width, height: width * ratio }
  }
  let node, defaultStyle
  if (data.subName) {
    switch (data.subName) {
      case 'note': {
        node = new VNote(ctx, board, data)
        defaultStyle = board.style.note
        break
      }
      default: {
        node = new VNode(ctx, board, data)
        defaultStyle = board.style.node
      }
    }
  } else {
    node = new VNode(ctx, board, data)
    defaultStyle = board.style.node
  }

  // layout 关联很多位置属性特殊处理
  node.isInit = true
  node.layoutX = x
  node.layoutY = y
  node.style.apply(data.style || defaultStyle) // 如果是复制和初始化已经存在的节点，会自带样式，需保持配置
  node.apply()
  return node
}

export default createNode
