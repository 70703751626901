
/*
  表示决定的icon 五角星
 */
import Process from './process'
class FiveStar extends Process {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.setWidth(width).setHeight(height)
  }
  initial(sx, sy, ox, oy) { // 初始化画布
    return this.init(sx, sy, ox, oy)._horizontal()._vertical()
  }
  process() {
    const { _left, _top, _right, _bottom, width, height } = this

    const halfX = _left + width / 2
    const halfY = _top + height / 2

    // （x，y）起点坐标，R 大圆半径， r小圆半径， rot起始旋转角度
    const x = halfX; const y = halfY; const R1 = (_right - _left) / 2; 
    const R2 = (_bottom - _top) / 2; const r2 = R2 / 3

    const arcRadius1 = height > 65 && width > 60 ? 4 : 1
    const arcRadius2 = height > 65 && width > 60 ? 2 : 1

    this.ctx.beginPath()

    const a1 = {
      x: Math.cos((18 + 72 * 0) / 180 * Math.PI) * R1 + x,
      y: -Math.sin((18 + 72 * 0) / 180 * Math.PI) * R1 + y
    }
    const a2 = {
      x: Math.cos((54 + 72 * 0) / 180 * Math.PI) * r2 + x,
      y: -Math.sin((54 + 72 * 0) / 180 * Math.PI) * r2 + y
    }
    const b1 = {
      x: Math.cos((18 + 72 * 1) / 180 * Math.PI) * R2 + x,
      y: -Math.sin((18 + 72 * 1) / 180 * Math.PI) * R2 + y
    }
    const b2 = {
      x: Math.cos((54 + 72 * 1) / 180 * Math.PI) * r2 + x,
      y: -Math.sin((54 + 72 * 1) / 180 * Math.PI) * r2 + y
    }
    const c1 = {
      x: Math.cos((18 + 72 * 2) / 180 * Math.PI) * R1 + x,
      y: -Math.sin((18 + 72 * 2) / 180 * Math.PI) * R1 + y
    }
    const c2 = {
      x: Math.cos((54 + 72 * 2) / 180 * Math.PI) * r2 + x,
      y: -Math.sin((54 + 72 * 2) / 180 * Math.PI) * r2 + y
    }
    const d1 = {
      x: Math.cos((18 + 72 * 3) / 180 * Math.PI) * R2 + x,
      y: -Math.sin((18 + 72 * 3) / 180 * Math.PI) * R2 + y
    }
    const d2 = {
      x: Math.cos((54 + 72 * 3) / 180 * Math.PI) * r2 + x,
      y: -Math.sin((54 + 72 * 3) / 180 * Math.PI) * r2 + y
    }
    const e1 = {
      x: Math.cos((18 + 72 * 4) / 180 * Math.PI) * R2 + x,
      y: -Math.sin((18 + 72 * 4) / 180 * Math.PI) * R2 + y
    }
    const e2 = {
      x: Math.cos((54 + 72 * 4) / 180 * Math.PI) * r2 + x,
      y: -Math.sin((54 + 72 * 4) / 180 * Math.PI) * r2 + y
    }

    const middle = {
      x: (a1.x + a2.x) / 2,
      y: (a1.y + a2.y) / 2
    }

    this.ctx.moveTo(middle.x, middle.y)
    this.ctx.arcTo(middle.x, middle.y, a2.x, a2.y, arcRadius2)
    this.ctx.arcTo(a2.x, a2.y, b1.x, b1.y, arcRadius1)
    this.ctx.arcTo(b1.x, b1.y, b2.x, b2.y, arcRadius2)
    this.ctx.arcTo(b2.x, b2.y, c1.x, c1.y, arcRadius1)
    this.ctx.arcTo(c1.x, c1.y, c2.x, c2.y, arcRadius2)
    this.ctx.arcTo(c2.x, c2.y, d2.x, d2.y, arcRadius1)
    this.ctx.arcTo(d1.x, d1.y, d2.x, d2.y, arcRadius2)
    this.ctx.arcTo(d2.x, d2.y, e1.x, e1.y, arcRadius1)
    this.ctx.arcTo(e1.x, e1.y, e2.x, e2.y, arcRadius2)
    this.ctx.arcTo(e2.x, e2.y, a1.x, a1.y, arcRadius1)
    this.ctx.arcTo(a1.x, a1.y, middle.x, middle.y, arcRadius2)
    this.ctx.arcTo(middle.x, middle.y, middle.x, middle.y, arcRadius2)
  }
}
export default FiveStar
