import React from 'react'
import SimpleSelect from 'components/antd/CustomSelect/simpleSelect'
import CustomSizeSelect from 'components/antd/CustomSelect/sizeSelect'
import { getNoteFontSizes } from 'constant'
import PropTypes from 'prop-types'

const fontFamilies = [
  '宋体',
  '黑体'
]

const fontSizes = getNoteFontSizes()

export const FontSelect = () => {
  const handleChange = (value) => {
    console.log(`selected ${value}`)
  }

  return (
    <SimpleSelect
      values={fontFamilies}
      curValue={fontFamilies[0]}
      handleChange={handleChange}
    />
  )
}

export const FontSizeSelect = (props) => {
  const { size, onChange, maxSize } = props

  return (
    <CustomSizeSelect
      values={fontSizes}
      curValue={size || fontSizes[0]}
      onChange={onChange}
      maxValue={maxSize}
    />
  )
}

FontSizeSelect.propTypes = {
  onChange: PropTypes.func,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  maxSize: PropTypes.number
}

