import ShadowStyle from './shadowStyle'
import utils from 'drawBoard/utils'
class FillStrokeStyle extends ShadowStyle {
  constructor() {
    super()
    this.fillStyle = FillStrokeStyle.DEFAULT_FILL_STYLE
    this.strokeStyle = FillStrokeStyle.DEFAULT_STROKE_STYLE
  }
  static DEFAULT_FILL_STYLE = "#000000"
  static DEFAULT_STROKE_STYLE = "#000000"

  getFillStyle () {
    return this.fillStyle;
  }
  setFillStyle (fillStyle) {
    return (this.fillStyle = fillStyle), this;
  }
  setOpacity(opacity = 1){
    if (this.fillStyle === 'transparent') return this
    if (~this.fillStyle.indexOf('rgba')) {
      this.fillStyle = this.fillStyle.substring(0, this.fillStyle.lastIndexOf(',')) + ',' + opacity + ')';
    } else {
      this.fillStyle = utils.HEX2RGBA(this.fillStyle, opacity);
    }
    return this;
  }
  setStrokeOpacity(opacity = 1) {
    if (this.strokeStyle === 'transparent') return this
    if (~this.strokeStyle.indexOf('rgba')) {
      this.strokeStyle = this.strokeStyle.substring(0, this.strokeStyle.lastIndexOf(',')) + ',' + opacity + ')';
    } else {
      this.strokeStyle = utils.HEX2RGBA(this.strokeStyle, opacity);
    }
    return this;
  }
  getStrokeStyle () {
    return this.strokeStyle;
  }
  setStrokeStyle (strokeStyle) {
    return (this.strokeStyle = strokeStyle), this;
  }
}
export default FillStrokeStyle