import React, { useState } from 'react'
import Navbar from 'components/navbar'
import LeftPanels from 'components/leftPanels'
import RightPanels from 'components/rightPanels'
import Footer from 'components/footer'
import Floats from 'components/floats'
import { AppContainerCssWrapper } from './style'
import { ReactComponent as IconDefs } from 'components/comm/IconButton/icon-defs.svg'
import Board from 'views/board'
import { ThemeProvider } from 'styled-components'
import GlobalCSS from './global.css'

// Define what props.theme will look like
const theme = {
  main: {
    appBackgroundColor: '#f7f7f7', // #eff0f3
    drawBoardBackgroundColor: '#f2f3f5', //
    navBarBackgroundColor: '#fff', //
    canvasBackgroundColor: '#ffffff',
    borderColor: '#dcdcdc',
    itemHoverColor: '#dfe3e7',
    inputHeight: '25px',
    splitLineColor: '#CECECE'
  }
}

const MOCK_TITLE = ['判断组合', '线性组合', '分支组合', '时间轴组合', '排列组合', '组织架构']
const MOCK_LIST = new Array(6).fill({ title: '', content: [], checked: false }).map((item, index) => {
  return {
    title: MOCK_TITLE[index],
    content: [{
      list: [
        {
          title: '是否型判断（单循环）'
        },
        {
          title: '条件型判断（多循环）'
        }
      ],
      arr: [
        {}, {}, {}, {}, {}, {}, {}
      ]
    }],
    checked: index === 0 && true
  }
})

export default function Index() {
  const [listShapes, setListShapes] = useState([
    {
      title: '判断组合',
      checked: false,
      src: 'panduan'
    },
    {
      title: '线性组合',
      content: [],
      checked: false,
      src: 'xianxing'
    },
    {
      title: '分支组合',
      content: [],
      checked: false,
      src: 'fenzhi'
    }
  ])
  const [shapeList, setShapeList] = useState(MOCK_LIST)
  const [shapeDiv, setShapeDiv] = useState(false)
  const onListShapesFn = (index) => {
    const newArr = listShapes.map((val, idx) => {
      if (idx === index) {
        val.checked = true
      } else {
        val.checked = false
      }
      return val
    })

    const newList = shapeList.map((val, idx) => {
      if (idx === index) {
        val.checked = true
      } else {
        val.checked = false
      }
      return val
    })
    setShapeList(newList)
    setListShapes(newArr)
    setShapeDiv(true)
  }

  const onClose = () => {
    if (shapeDiv) {
      setShapeDiv(false)
    }
  }

  // 7月7号的版本控制：
  const edition_seven_seven = true

  return (
    <ThemeProvider theme={theme}>
      <GlobalCSS />
      <AppContainerCssWrapper onClick={() => onClose()}
      >
        <IconDefs />
        <Navbar listShapes={listShapes} onListShapesFn={onListShapesFn} edition_seven_seven={edition_seven_seven}/>
        <div className='flex1 flexColumn'>
          {/* <Toolbox /> */}
          <div className='flex1 flex'>
            <LeftPanels />
            <div id='drawBoardContainer' className='flex1 drawBoard flex'>
              <Board />
            </div>
            <RightPanels edition_seven_seven={edition_seven_seven}/>
          </div>
        </div>

        <Footer />
        <Floats />
      </AppContainerCssWrapper>
    </ThemeProvider>
  )
}

