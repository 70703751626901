/*
  表示手动输入的icon
 */
import Process from './process'

class ManualInput extends Process {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.width = width || 160
    this.height = height || 120
  }
  process() {
    const { ctx, _sx, _sy, _right, _bottom, width, height, arcRadius } = this
    const gap = height / 5
    const angle = Math.atan(gap / width)
    const fc = { x: _sx, y: _sy + gap } // first corner 第一个拐角的点
    const fp = { x: _sx + Math.cos(angle) * arcRadius, y: _sy + gap - Math.sin(angle) * arcRadius } // first point 第一个弧角开始点
    const start = { x: fc.x, y: fc.y + arcRadius }
    ctx.beginPath()
    ctx.moveTo(start.x, start.y)
    ctx.arcTo(fc.x, fc.y, fp.x, fp.y, arcRadius)
    ctx.arcTo(_right, _sy, _right, _sy + arcRadius, arcRadius)
    ctx.arcTo(_right, _bottom, _right - arcRadius, _bottom, arcRadius)
    ctx.arcTo(_sx, _bottom, _sx, _bottom - arcRadius, arcRadius)
    ctx.lineTo(start.x, start.y)
  }

  getShapePatch() {
    return {
      x: this._sx,
      y: this._sy
    }
  }

  patchShape(move) {
    this._sx += move.x
    this._right += move.x
    this._sy += move.y
    this._bottom += move.y
  }
}
export default ManualInput
