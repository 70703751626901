import Utils from '../utils'
class Animate {
  constructor(func, duration, interval) {
    this.func = func
    this.duration = isNaN(duration) ? 0 : duration
    this.interval = interval
    this._requestId = void 0
    this._loopBind = this._loop.bind(this)
    this._init()
  }
  get _loop() {
    return Utils.noop
  }
  _init() {
    this._timestart = this._timestamp = this._nextstamp = this._pausestamp = this._pauserange = 0
    return this
  }
  _callback(timestamp) {
    this.func(timestamp, timestamp - this._timestamp)
    this._timestamp = timestamp
  }
  start() {
    return this._init().resume()
  }
  pause() {
    return (
      this.isRunning() && (this.stop()._pausestamp = this._realstamp), this
    )
  }
  resume() {
    this.isStopped() && this._resume()
    return this
  }
  get _resume() {
    return Utils.noop
  }
  stop() {
    this.isRunning() && this._stop()
    return this
  }
  get _stop() {
    return Utils.noop
  }
  isRunning() {
    return void 0 !== this._requestId
  }
  isStopped() {
    return void 0 === this._requestId
  }
  isPaused() {
    return 0 !== this._pausestamp
  }
  inProgress() {
    return this._timestamp < this.duration
  }
}

export default Animate
