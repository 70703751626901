export const FontSize = [
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  14,
  18,
  24,
  30,
  36,
  48,
  60,
  72,
  96
]

export const DashLines = [
  {
    icon: 'line',
    value: []
  },
  {
    icon: 'dash-line-1',
    value: [4]
  },
  {
    icon: 'dash-line-2',
    value: [10, 4, 4, 4]
  },
  {
    icon: 'dash-line-3',
    value: [10, 4, 2, 4]
  }
]

