import Shape from './index.js'

class Line extends Shape {
  constructor(ctx, sx, sy, ex, ey, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.setEndX(ex).setEndY(ey)
  }
  process() {
    var ctx = this.ctx
    ctx.beginPath()
    ctx.moveTo(this._sx, this._sy)
    ctx.lineTo(this._ex, this._ey)
  }
  collidesWithRect(rect) { // 目前针对于直角线, 方法比较蠢，待更新，后续再拓展各种角度
    const { _left, _right, _top, _bottom } = rect
    const { _sx, _sy, _ex, _ey } = this
    const crossVertical = _sx === _ex && _sx > Math.min(_left, _right) && _sx < Math.max(_left, _right) &&
                          Math.min(_sy, _ey) < Math.min(_top, _bottom) && Math.max(_sy, _ey) > Math.max(_top, _bottom)
    const crossHorizon = _sy === _ey && _sy > Math.min(_top, _bottom) && _sy < Math.max(_top, _bottom) &&
                         Math.min(_sx, _ex) < Math.min(_left, _right) && Math.max(_sx, _ex) > Math.max(_left, _right)
    if (crossVertical || crossHorizon) return true
    if (rect._isQuickInPath(_sx, _sy) || rect._isQuickInPath(_ex, _ey)) return true
    return false
  }
  collidesWithArc(point) {} // point 圆心，可用此判断点是否在线上
  setOriginX(ox) {
    return (
      // eslint-disable-next-line no-useless-call
      this.setOriginX.call(this, ox),
      (this._ex = this.ex - ox),
      this
    )
  }
  setOriginY(oy) {
    return (
      // eslint-disable-next-line no-useless-call
      this.setOriginY.call(this, oy),
      (this._ey = this.ey - oy),
      this
    )
  }
  getEndX() {
    return this.ex
  }
  setEndX(ex) {
    // eslint-disable-next-line no-sequences
    return (this.ex = ex), (this._ex = ex - this.ox), this
  }
  getEndY() {
    return this.ey
  }
  setEndY(ey) {
    // eslint-disable-next-line no-sequences
    return (this.ey = ey), (this._ey = ey - this.oy), this
  }
  setStart(sx, sy) {
    this.setStartX(sx).setStartY(sy)
    return this
  }
  setEnd(ex, ey) {
    this.setEndX(ex).setEndY(ey)
    return this
  }

  patchShape(move) {
    this._sx += move.x
    this._sy += move.y
    this._ex += move.x
    this._ey += move.y
  }

  getShapePatch() {
    const x = this._sx < this._ex ? this._sx : this._ex
    const y = this._sy < this._ey ? this._sy : this._ey

    return { x, y }
  }
}

export default Line
