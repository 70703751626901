/* eslint-disable no-dupe-class-members */
/* eslint-disable no-cond-assign */
import Utils from '../utils'
import Text from '../shapes/text'
import Style from './style'
import Border from './border'
import * as shapes from '../shapes/process'
import Junction from './junction' // 连接线和图形的会交点
import FuncLock from './funcLock' // 功能锁
import Picture from '../shapes/picture'
import Helper from 'utils/helper'
import { CREATE_ACTIONS } from './constant'
const resizeCursors = ['', 'nw', 'ne', 'se',  'sw', 'n', 'e', 's','w']
class VNode {
  constructor($ctx, board, { type, action, x, y, id, value, width, height, shapeInfo, picUrl, carrier }) {
    this.board = board
    this.name = 'node'
    // TODO 保存功能完善后需要在保存前进行id校验
    this.id = id || Helper.produceNanoId()
    this.type = type // 节点类型
    this.$ctx = $ctx
    this.style = new VNode.Style(this)
    this.x = x || 0
    this.y = y || 0
    this.width = width || 0
    this.height = height || 0
    this.value = value || ''
    this.action = action || CREATE_ACTIONS.menuToCreate // 节点创建方式
    this.preValue = value // 记录修改前的值，用于对比node值变化
    this.values = [] // 节点的内容
    this.texts = [] // 根据内容生成的节点中的文字对象
    this.background = new shapes[shapes[type] ? type : 'process'](this.$ctx, 0, 0, width, height)
    this.boundary = new shapes[shapes[type] ? type : 'process'](this.$ctx, 0, 0, width, height) // 图形边界线，目前只有在框选图形是才会出现
    this.border = new Border(this.$ctx, this)
    this.shapeInfo = shapeInfo // 节点形状的属性，大小，比例等
    this.carrier = carrier // 载体，连接线上的文字节点，载体 为连接线
    this.isMouseIn = false
    this.mouseInWhich = '' // 鼠标移入到节点内的某个元素
    this.selectType = '' // 选择类型 click 点选, frame 框选
    this.junction = new Junction(this)
    this.isMoving = false
    this.hackerIsAvtive = undefined
    this.isInit = false // 节点是否是初始化时，初始化时
    this.isIntelHeight = true // 高度是否根据内容变化自动调整
    this.funcLock = new FuncLock($ctx, this, board)
    this.isEditing = false // 是否在修改节点文本
    // 处理图片属性
    if (this.isImageType) {
      Helper.loadImage(picUrl).then((img) => {
        this.picture = new Picture(this.$ctx, 0, 0, 0, 0, img)
        this.apply()
        this.calculateSize()
        this.board.draw()
      })
    }
    this.picUrl = picUrl
    this.hyperlink = ''
    this.hyperlinkState = false
  }
  apply() { // 初始化设置node相关属性位置
    const { x, y, style } = this
    this.background.setStart(x, y)
    this.boundary.setStart(x, y)
    this.picture && this.picture.setStart(x, y)
    this.calculateSize()
    this.border.apply()
    this.funcLock.apply() // 功能锁
    for (let i = 0, length = this.values.length; i < length; i++) {
      const textX = x + (this.width - this.values[i].width) / 2
      const textY = y + style.contentTop + i * style.lineHeight
      this.texts[i].setStart(textX, textY)
      this.texts[i].setAngle(this.style.textAngle)
    }
    this.junction.apply()
    this.isInit = false
  }
  draw() { // 绘制节点
    if (this.isFrameSelected && !this.isMoving) this.boundary.stroke()

    if (this.isImageType) {
      this.picture && this.picture.fill()
    } else {
      this.background.fillStroke()
      for (let i = 0, length = this.values.length; i < length; i++) {
        this.texts[i].fill(this.style)
      }
    }
    if (!this.isMoving && !this.funcLock.isLocked) {
      this.junction.draw()
    }

    this.isSelected && this.funcLock.draw() // 绘制功能锁

    if (this.isClickSelected && !this.funcLock.isLocked) {
      this.border.draw() // 选中高亮
    }
  }
  hideText() {
    this.style.textOpacity = 0
    this.style._apply()
  }
  showText() {
    this.style.textOpacity = 1
    this.style._apply()
  }
  getValue() {
    return this.value
  }
  setValue(value) {
    this.operateNode.setValue(this, value)
  }
  getValues() {
    const value = this.value // 当前的值
    const values = this.values // 多行文本
    let length
    const style = this.style
    let width = 0
    let cursor = 0
    let i, c, w
    values.length = 0
    for (i = 0, length = value.length; i < length; i++) {
      c = value[i]
      // 如果有换行
      if (c === '\n') {
        values.push({ text: value.substring(cursor, i), width })
        cursor = i + 1
        width = 0 // 换行后重置宽度
      } else {
        w = Utils.measureTextWidth(c, style.font)
        if ((width + w) > (this.maxWidth - style.paddingLeft * 2)) {
          values.push({ text: value.substring(cursor, i), width })
          cursor = i
          width = w
        } else {
          width += w
        }
      }
    }
    values.push({ text: value.substring(cursor), width })
    this.textWidth = this.maxWidth
    this.textHeight = style.fontSize + (this.values.length - 1) * style.lineHeight
    return { values }
  }
  _apply() { // 在修改完node样式时，根据新的样式重新计算生成text
    const texts = this.texts
    // 根据现有需求 暂时去掉字体高度和宽度
    const { values } = this.getValues()
    const length = values.length
    while (texts.length < length) {
      const text = new Text(this.$ctx, 0, 0)
      this.style.setTextStyle(text)
      texts.push(text)
    }
    for (let i = 0; i < length; i++) {
      texts[i].setText(values[i].text)
    }
    if (this.carrier) { // 如果是连接线上的文本节点，节点大小计算方式与普通节点不同
      this.setWidth(this.textWidth + this.style.paddingLeft * 2)
      this.setHeight(this.textHeight + this.style.paddingTop * 2)
    } else {
      if (this.isInit) { // 如果是初始化
        if (this.isCopyTextToCreate) { // 当是粘贴文字进来时
          this.setWidth(this.textWidth + this.style.paddingLeft * 2)
          this.setHeight(this.textHeight + this.style.paddingTop * 2)
        }
        this.setIsIntelHeight()
      }
      // 在初始化或者文本的高度小于节点的高度时，节点的高度由内容自动撑起来
      let move, curHeight
      if (this.isIntelHeight && this.isEditing) {
        const _height = this.textHeight + this.style.paddingTop * 2
        if (_height >= this.height) { // 内容高度大于等于节点高度，自动撑起节点，内容在增加
          curHeight = _height
        } else if (Math.abs(_height - this.height) <= this.style.lineHeight) { // 内容在减少
          if (this.height >= this.baseHeight) { // 节点高度已经超出图形基础高度，在内容减少的时候，取基础高度为最低高度
            curHeight = Math.max(_height, this.baseHeight)
          } else {
            curHeight = _height
          }
        }
        move = { x: this.x, y: this.y, gapX: 0, gapY: curHeight - this.height }
        if (move.gapY) this.operateNode._resizeOperation(this, move)
      }
    }
  }
  calculateSize() {
    const { paddingTop } = this.style
    const middle = { x: (this.width - this.textWidth) / 2, y: (this.height - this.textHeight) / 2 }
    this.style.contentTop = this.height > this.textHeight + paddingTop ? middle.y : paddingTop
    this.background.setWidth(this.width).setHeight(this.height)
    this.boundary.setWidth(this.width).setHeight(this.height)
    this.picture && this.picture.setWidth(this.width).setHeight(this.height)
  }
  distance(n) { // 判断一个图形和另一个图形的距离
    return Math.sqrt(Math.pow(this.cx - n.cx, 2) + Math.pow(this.cy - n.cy, 2))
  }
  isNearOther(n, gap = 30) { // 是否靠近或者重叠其他的节点
    if (n) return this.distance(n) - (this.width / 2 + n.width / 2) < gap
  }
  onmouseleave() { // 鼠标离开时
    this.junction.onmouseleave()
    this.border.onmouseleave()
  }
  onscale() { // 画板缩放后的回调 需要对特别图案进行处理
    this.border.onscale()
    this.junction.onscale()
  }
  eventmove(link) { // 判断鼠标是否进入 point: 鼠标对应的画点 mouse: 鼠标点
    const { boardX, boardY } = this.board.mouse
    this.updateMoveState(false)
    this.isMouseIn = false

    // 锁定图案在节点外部
    if (this.isSelected && this.funcLock.eventmove(boardX, boardY)) {
      this.mouseInWhich = 'funcLock'
      this.board.$setCursor('pointer')
    } else if (this.junction.isTouchBoundary = this.background._isPointInStroke(boardX, boardY)) { // 触碰到图形边线
      this.mouseInWhich = 'boundary'
      this.junction.setTouchPoint(boardX, boardY)
      // 当touch到连接线，而且连接线被选中的情况下，显示连接线的光标
      // ;(link && link.isSelected) || this.board.$setCursor('crosshair')
    } else if (this.isMouseIn = this.boundary._isPointInPath(boardX, boardY)) { // 鼠标进入形状内部
      this.mouseInWhich = 'body'
      this.board.$setCursor('move')
      if (this.hyperlink && this.hyperlinkState) {
        this.board.$setCursor('pointer')
      }
    } else { // 当鼠标游离到特殊范围之外，重置回默认形状
      this.board.$setCursor('default')
    }
    // 由于junction和border调节点位于节点内外部，位置出现重叠，需单独处理
    let dir
    if (this.isClickSelected && (dir = this.border.eventmove(boardX, boardY))) { // 鼠标touch到调节节点大小的锚点
      this.mouseInWhich = 'border'
      this.board.$setCursor(`${resizeCursors[dir]}-resize` || 'default')
    } else if (!(link && link.isSelected)) {
      if (this.junction.eventmove({x: boardX, y: boardY})) { // 当节点被选择后，连接处的锚点会向外扩散，不在节点上，需单独处理
        this.mouseInWhich = 'border'
       this.board.$setCursor('default')
      }
    }
    return this.junction.isMouseIn
  }
  eventup() {
    if (this.isMoving) { // 对图形节点进行移动或缩放等操作时需要隐藏一些属性，结束后属性需要重新展示
      this.updateMoveState(false)
      this.draw()
    }
  }
  isHasLinkInJunction(jp) {
    let end = {}
    const links = this.board.links

    for (const link of Object.values(links)) {
      if (link.first === this || link.last === this) { // 等于当前选中节点
        if (link.start.distance({ x: jp.sx, y: jp.sy }) < 0.5) return false// 在touch圆点附近已有连接线
      }
    }

    switch (jp.index) {
      case 0:
        end = { x: jp.sx - 80, y: jp.sy }
        break
      case 1:
        end = { x: jp.sx, y: jp.sy - 80 }
        break
      case 2:
        end = { x: jp.sx + 80, y: jp.sy }
        break
      case 3:
        end = { x: jp.sx, y: jp.sy + 80 }
        break
    }
    return end
  }
  eventdown(ev) {
    this.beforeOffset()
    this.jump()
    if (this.junction.mouseInPoint) { // 鼠标是否touch到连接处
      if (this.funcLock.isLocked) return
      if (!this.border.mouseInPoint) { // 在非（touch到调整边框锚点)
        this.operateLink.createPreviewLink(this, this.junction.mouseInPoint, this.board.lineType)
      }
    } else if (this.mouseInWhich === 'funcLock') {
      this.funcLock.unlock()
      this.operateNode.lock([this], this.funcLock.lockType)
    } else {
      const group = this.board.operateGroup.getGroup(this.id)
      // 处理多选节点反选
      if (ev.metaKey && this.isFrameSelected) {
        this.board.selector.unSelect(this.board.operateGroup.getGroupMembers(this.id))
        this.draw()
        this.board.box.calculateSize()
      } else {
        if (this.isMouseIn) {
          group ? this.board.operateGroup.selectGroup(group) : this.board.selector.select(this, ev)
        } else if (!this.border.mouseInPoint) {
          this.board.selector._clear()
        }
      }
    }
  }
  drag(x, y) {
    this.updateMoveState(true)
    this.offset(x, y)
  }

  updateMoveState(isMoving) {
    if (isMoving !== this.isMoving) {
      // 通知节点位置更新
      this.isMoving = isMoving
      this.board.operateNode.noticeNodeUpdate()
    }
  }

  move(x, y) {
    this.offset(x, y)
  }
  setLayout(x, y) {
    this.layoutX = x
    this.layoutY = y
  }
  resize(move) { // 使用边框的八个锚点来调整节点的大小
    this.operateNode.resize(this, move)
  }
  select() {
    this.setSelectType(this.selectType || 'click') // 默认是点击选中
    if (this.isClickSelected) {
      this.junction.onSpread(1)
    }
  }
  setSelectType(v) { // 选择类型 click 点选, frame 框选
    this.selectType = v
    return this
  }

  setIsIntelHeight() { // 设置高度根据内容自适应开关
    return this.isIntelHeight = (this.textHeight + this.style.paddingTop * 2) <= this.height
  }

  jump() {
    // 点击节点同时按住 command + shift(mac) 跳转
    if (this.hyperlink && this.hyperlinkState) {
      // 每次跳转到新窗口后重置状态
      this.hyperlinkState = false
      window && window.open(this.hyperlink, '_blank')
    }
  }
  unselect() {
    this.junction.onSpread(0)
    this.setSelectType('')
  }
  beforeOffset() { // 使用_x, _y 记录下偏移前的初始值
    this._x = this.x
    this._y = this.y
  }
  offset(x, y) {
    this.layoutX += x
    this.layoutY += y
  }
  setLayout(x, y) {
    this.layoutX = x
    this.layoutY = y
  }
  setWidth(value) {
    this.width = value
    this.right = this.x + this.width
    this.cx = this.x + this.width / 2 // 中点
  }
  setHeight(value) {
    this.height = value
    this.bottom = this.y + this.height
    this.cy = this.y + this.height / 2
  }

  setLink(hyperlink) {
    this.hyperlink = hyperlink
  }
  getTopCenterPos() {
    return {
      x: this.x + this.width / 2,
      y: this.y
    }
  }
  getBottomCenterPos() {
    return {
      x: this.x + this.width / 2,
      y: this.y + this.height + 100
    }
  }
  get maxWidth() { // 获取节点最大宽度
    return ((this.isInit && this.isCopyTextToCreate) || this.carrier) ? this.style.maxWidth : this.width
  }
  get isImageType() {
    return this.type === 'image'
  }
  get isTextType() {
    return this.type === 'text'
  }
  get isCopyTextToCreate() {
    return this.action === CREATE_ACTIONS.copyTextToCreate
  }
  get isNode() {
    return this.name === 'node'
  }
  get isSelected() {
    return !!this.selectType
  }
  get isClickSelected() { // 是否点击选中
    return this.selectType === 'click'
  }
  get isFrameSelected() { // 是否是框选
    return this.selectType === 'frame'
  }
  get isTouchBody() {
    return this.mouseInWhich === 'body'
  }
  get baseHeight() {
    return this.shapeInfo ? this.shapeInfo.width * this.shapeInfo.ratio * this.board.grid.baseSize : this.height
  }
  get layoutX() {
    return this.x
  }
  set layoutX(x) {
    this.x = x
    this.left = x
    this.right = x + this.width
    this.cx = x + this.width / 2 // 中点
  }
  get layoutY() {
    return this.y
  }
  set layoutY(y) {
    this.y = y
    this.top = y
    this.bottom = y + this.height
    this.cy = y + this.height / 2
  }
  get operateLink() {
    return this.board.operateLink
  }
  get operateNode() {
    return this.board.operateNode
  }
}
VNode.id = 0
VNode.Style = Style
export default VNode
