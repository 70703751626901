import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CssWrapper } from '../styles'
import { FontSelect, FontSizeSelect } from '../common/fontSizeSelect'
import PropTypes from 'prop-types'

import IconButtonWithTooltip from 'components/antd/ToolTip/IconButtonWithTooltip'
import { IconButtonWithColor } from 'components/comm/Buttons'
import { useBoardContext } from 'views/board/hooks'
// import NoteTag from './noteTag'
// import Helper from 'utils/helper'
// import { NoteTagKey } from 'views/board/install/dataHelper'
// import { getTagsByTagIds } from 'utils/noteHelper'
import AlignmentBtn from '../common/alignmentBtn'
import { NoteBgColors } from 'constant'
import { ButtonWithColorPick } from '../common/colorButton'
import { useLock } from '../hooks/useLock'

export default function NoteBar(props) {
  const { position: p, activeNode } = props
  const { style } = activeNode
  const { backgroundColor, fontSize, fontWeight, maxFontSize, textAlign } = style
  const [width, setWidth] = useState(400)
  const containerRef = useRef(null)
  const { board } = useBoardContext()

  useEffect(() => {
    if (containerRef.current && containerRef.current.offsetWidth) {
      setWidth(containerRef.current.offsetWidth)
    }
  }, [containerRef])

  const changeNodeStyle = useCallback((config) => {
    board && board.api.changeNodeStyle(config)
  }, [board])

  // bold
  const handleBoldChange = useCallback(() => {
    const newVal = fontWeight === 'bold' ? 'normal' : 'bold'
    changeNodeStyle({ fontWeight: newVal })
  }, [fontWeight, changeNodeStyle])

  // tags
  // const [tags, setTags] = useState([])
  // useEffect(() => {
  //   const allTags = getTagsByTagIds(tagIds)
  //   setTags(allTags)
  // }, [])

  // const updateTags = useCallback((newTags) => {
  //   const selectedIds = newTags.filter(e => e.isSelected).map(e => e.id)
  //   const selectNote = board.selector?.selected[0]
  //   if (!selectNote) return
  //   Helper.localStorageSetItem(NoteTagKey, newTags)
  //   board.operateNode.setNoteTagIds(selectNote, selectedIds)
  //   setTags(newTags)
  // }, [board])

  // align
  const [alignType, setAlignType] = useState(textAlign)
  const updateAlign = useCallback((type) => {
    const selectNote = board.selector?.selected[0]
    if (!selectNote) return
    board.operateNode.setNoteAlign(selectNote, type)
    setAlignType(type)
  }, [board])

  // fontSize
  const mFontSize = useMemo(() => {
    return typeof fontSize === 'number' ? fontSize.toString() : fontSize
  }, [fontSize])

  const updateFontSize = useCallback((fontSize) => {
    const selectNote = board.selector?.selected[0]
    if (!board || !selectNote) return
    changeNodeStyle({ fontSize })
  }, [board, changeNodeStyle])

  // lock
  const { isLock, updateLockStatus } = useLock(activeNode)

  return (
    <CssWrapper
      ref={containerRef}
      style={{ left: `${p.x - width / 2}px`, top: `${p.y}px` }} >
      <div className='group'>
        <FontSelect />
        <FontSizeSelect
          size={mFontSize}
          maxSize={maxFontSize}
          onChange={updateFontSize}
        />
      </div>
      <div className='group'>
        <IconButtonWithTooltip toolTip='加粗' name='bold'
          selected={fontWeight === 'bold'}
          onClick={handleBoldChange}
        />
        <AlignmentBtn alignType={alignType} onAlignChanged={updateAlign}/>
        {/* <IconButtonWithTooltip toolTip='超链接' name='hyperlink'/> */}
      </div>

      <div className='group'>
        <IconButtonWithColor
          link='link'
          name='text-color'
          showToolTip={false}
          widthInPx={24}
          onColorChange={(v) => { changeNodeStyle({ fontColor: v }) }}
        />
        <ButtonWithColorPick
          colors={NoteBgColors}
          color={backgroundColor}
          width={158}
          onColorChange={(v) => { changeNodeStyle({ backgroundColor: v }) }}
        />
        {/* <IconButtonWithTooltip toolTip='emoji' name='emoji'/> */}
        {/* <NoteTag tags={tags} setTags={updateTags} /> */}
        <IconButtonWithTooltip
          toolTip={isLock ? 'unLock' : 'lock'}
          name={isLock ? 'iconfont-Lock' : 'iconfont-unLock'}
          onClick={updateLockStatus}
        />
        {/* <IconButtonWithTooltip toolTip='more' name='more'/> */}
      </div>
    </CssWrapper>
  )
}

NoteBar.propTypes = {
  activeNode: PropTypes.object,
  position: PropTypes.object
}
