import Utils from '../utils'
import Helper from 'utils/helper'
const events = {
  eventdown: function(ev, type) {
    ev.preventDefault()
    this.mouse.type = type
    const mouse = { x: ev.$x, y: ev.$y }
    const point = this.getDrawCoordinate(mouse.x, mouse.y) // 对应画布坐标换算出的点
    const sels = [...this.selector.selected]
    this.$menu.hide()
    switch (type) {
      case this.mouse.left: // 判断是鼠标左键点击
        if (this._link) {
          this.operateLink.eventdown(ev, this._link, this._node, point)
        } else if (this._node) {
          this.operateNode.eventdown(ev, this._node)
        } else { // 点击空白处
          this.selector._clear()
          this.box.eventdown(point)
          this.operateGroup._clear()
        }
        this.nodeGridSnap.saveMouseDownPos(this, point)
        this.operateBoard.eventdown(ev, sels)
        break
      case this.mouse.right:
        this.mouse.setEventType('mouseRightDown')
        break
    }
  },
  eventmove: Utils.nextTick(function(ev, type) {
    const mouse = { x: ev.$x, y: ev.$y }
    const move = this.getDrawDistance(ev.$movementX, ev.$movementY) // 鼠标一步的移动距离
    const _move = this.getDrawDistance(ev.$moveX, ev.$moveY) // 以鼠标按下去的点为起始点，移动距离是当前点和起始点的差值
    this.mouse.setPosition(mouse).setBoardPosition(mouse)
    const position = { x: this.mouse.boardX, y: this.mouse.boardY } // 鼠标点对应的画点
    if (_move.x === 0 && _move.y === 0) return // 当鼠标非常细微的移动，系统实时计算得出的移动距离约为0, 同样也会触发eventmove-mouse.left
    switch (type) {
      case this.mouse.none:
        this.mouse.setEventType('mousemove') // 设置down - move - up连贯事件类型
        if (this.operateNode.onDragInShape()) return // 从左侧菜单拖动图形到画板执行
        this._eventmove(ev)
        break
      case this.mouse.left: // 拖动元素
        this.mouse.setEventType('mouseLeftDrag') // 设置down - move - up连贯事件类型
        if (this.operateNode.onDragInShape()) return // 从左侧菜单拖动图形到画板执行
        if (this.mouse.type !== type) return
        if (this.operateLink.previewLink) { // 优先级最高，脱离所有规则
          const { point: newPoint } = this.nodeGridSnap.getLinkLineSnapByDragHead(this, position, true)
          this.operateLink.setPreviewLink(newPoint, newPoint)
        } else if (this._link && this._link.isSelected) { // 当连接线已经选择选中的情况下，拖动连接线优先级高
          this.operateLink.eventdrag(this._link, move)
          this.operateGroup.eventdrag(this._link)
        } else if (this._node) { // 鼠标touch node的情况下
          this.operateNode.eventDrag(ev, this._node, move)
          this.operateGroup.eventdrag(this._node)
        } else if (this._link) { // touch 到连接线
          this.operateLink.eventdrag(this._link, move)
          this.operateGroup.eventdrag(this._link)
        } else { // 空白处 设置选择范围的矩形
          this.box.eventdrag(position)
        }
        break
      case this.mouse.right:
        // todo 移动画布
        this.$setCursor('grab')
        this.mouse.setEventType('mouseRightDrag')
        this.operateBoard.moveBoard(move)
        this.redrawFloatMenu()
        break
      case this.mouse.center: // 按住鼠标滚动
        // todo 移动画布
        this.$setCursor('grab')
        this.mouse.setEventType('mouseCenterDrag')
        this.operateBoard.moveBoard(move)
        this.redrawFloatMenu()
        break
    }
  }),
  _eventmove: function(ev) {
    this.preNode = this._node
    this.preLink = this._link
    this._node = this._link = null
    const mouse = { x: ev.$x, y: ev.$y }
    const point = this.getDrawCoordinate(mouse.x, mouse.y)
    this._node = this.operateNode.eventmove(this.preLink)
    this._group = this.operateGroup.eventmove(point, mouse, this.preLink, this.preNode)
    this._link = this.operateLink.eventmove(this._node)
    if (!this._link && !this._node) this.$setCursor('default')
    if (this.preNode !== this._node || this.preLink !== this._link) {
      // 当两个节点非常接近时，鼠标touch从其中一个节点到另一个节点，离开时寻找到节点就不会再执行后面的节点eventmove，所以前一个节点的状态无法及时销毁
      if (this.preNode?.isNearOther(this._node)) this.preNode.onmouseleave()
      this.draw()
    }
  },
  eventup: function(ev) {
    const newNode = this.operateNode.dragInShape
    this.operateBoard.eventup(ev)
    this.box.eventup()
    this.operateNode.eventup(ev, this._node || newNode)
    this.operateLink.eventup(ev)
    if (this._node) { this._node.eventup(); this.operateGroup.eventup(this._node) }
    if (this._link) { this._link.eventup(); this.operateGroup.eventup(this._link) }
    this.mouse.reset()
  },
  keydown: function(ev) {
    // ev.preventDefault();
    var key = ev.key
    if (~this._arrowKey.indexOf(key)) {
      ev.preventDefault()
      if (ev.altKey) this._altArrow(key, ev)
      else this._ctrlArrow(key, ev.ctrlKey, ev)
    } else if (~this._hotKey.indexOf(key)) {
      ev.preventDefault()
      this._hotkey(key, ev)
    } else if (ev.metaKey || ev.ctrlKey || ev.altKey || ev.shiftKey) {
      this._hotkeyCtrl(key, ev)
    } else { // 点击除特殊按键外，则激活选中节点输入文字
      ev.preventDefault()
      if (this.selector.ctrl && this.selector.ctrl.isNode) {
        this.operateNode.onkeydown(this.selector.ctrl)
      }
    }
  },
  keyup: function(ev) {
    ev.preventDefault()
    var key = ev.key
    this._hotKeyUp(key)
  },
  _hotKeyUp(key, ev) {
    switch (key) {
      case 'Shift':
      case 'Meta':
        this.operateNode.setLinkState(false)
        break
    }
  },
  _altArrow: function(key) {
    switch (key) {
      case 'ArrowLeft':
        break
      case 'ArrowRight':
        break
      case 'ArrowUp':
        break
      case 'ArrowDown':
        break
    }
  },
  _ctrlArrow: function(key, ctrlKey) {
    var selector = this.selector
    var last = selector.last
    var sels = selector.selected
    var next
    if (last === void (0)) return
    switch (key) {
      case 'ArrowLeft':
        this.operateNode.move(sels, {
          x: -this.grid.baseSize,
          y: 0
        })
        break
      case 'ArrowRight':
        this.operateNode.move(sels, {
          x: this.grid.baseSize,
          y: 0
        })
        break
      case 'ArrowUp':
        this.operateNode.move(sels, {
          x: 0,
          y: -this.grid.baseSize
        })
        break
      case 'ArrowDown':
        this.operateNode.move(sels, {
          x: 0,
          y: this.grid.baseSize
        })
        break
    }
    if (next) {
      if (ctrlKey && next.isSelected) {
        selector.select(last, true)
        this.draw()
      } else selector.select(next, ctrlKey)
    }
  },
  _hotkey: function(key) { // 常用键判断 tab enter...
    const selector = this.selector; const sels = selector.selected
    switch (key) {
      case 'Tab':
        break
      case 'Enter':
        break
      case ' ': // space 空格键
        this.operateBoard.interruptAddDragInShape()
        break
      case 'Backspace':
      case 'Delete':
        this.box.hide()
        this.operateGroup.delete(sels)
        this.operateLink.remove(sels.filter(n => n.name === 'link'))
        this.operateNode.remove(sels.filter(n => n.name === 'node'))
        break
      case 'Escape':
        selector._clear()
        this.box.hide()
        this.operateBoard.interruptAddDragInShape()
        break
    }
  },
  _hotkeyCtrl: function(key, ev = {}) { // 组合键判断
    const shiftKey = ev.shiftKey
    const selector = this.selector; const selNodes = selector.selected
    if (shiftKey) this.operateNode.setLinkState(true)
    // ctrl + v 不去阻止默认事件，避免paste 事件监听不到
    if (key !== 'v') { ev.preventDefault && ev.preventDefault() }
    this.operateBoard.interruptAddDragInShape() // 所有的热键都会阻断拖动图形到画板的操作
    switch (key) { // TODO: 以后有空编写搜索功能
      case 'a': // 全选
        this.box.allSelect()
        break
      case 'c': // 复制
        this.copiedNodes = [...selNodes]
        break
      case 'v': // 粘贴
        if (this.copiedNodes.length) {
          this.operateBoard.onpaste(this.copiedNodes)
        } else {
          Helper.clipboardRead((clipboardItems) => {
            const type = clipboardItems[0].types[0]
            // 区分图片和文本复制
            if (type.includes('image')) {
              this.picture.clipboardUpload(clipboardItems)
            } else if (type.includes('text')) {
              this.operateNode.onpasteExternalText(clipboardItems[0])
            }
          }, () => {
            Helper.getPasteTypeFile() && this.picture.uploadImg(Helper.getPasteTypeFile(), 'paste')
          })
        }
        break // TODO: 复制和粘贴操作可以考虑进入 hacker 的逻辑，使用 execCommand 方式
      case 'q':
        this.selector.select(this.root)
        break
      case 'o': // open file
        if (this.operation.hasChanged() && confirm('需要保存您所做的更改吗？')) this._hotkeyCtrl('s')
        else this.input.click()
        break
      case 's':
        // this.operation.stamp()
        this.save() // TODO: 需要处理 Ctrl+S/O 的数据格式压缩问题
        break
      case 'x': // 剪切
        if (selNodes.length) {
          this._hotkeyCtrl('c', ev)
          this._hotkey('Delete')
        }
        break
      case 'z':
        if (shiftKey) {
          this.operation.next()
        } else {
          this.operation.prev()
        }
        break
      case 'y':
        this.operation.next()
        break
      case '/':
      case '÷':
        break
      case '=':
        this.api.handleScale(ev, 1, true)
        break
      case '-':
        this.api.handleScale(ev, -1, true)
        break
      case 'b': // 文字加粗
        this.api.changeNodeStyle({
          fontWeight: Helper.isSame('fontWeight', 'bold', selNodes) ? 'normal' : 'bold'
        })
        break
      case 'i':
        this.api.changeNodeStyle({
          fontStyle: Helper.isSame('fontStyle', 'italic', selNodes) ? 'normal' : 'italic'
        })
        break
      case 'u':
        this.api.changeNodeStyle({
          underline: Helper.isSame('underline', 'underline', selNodes) ? 'none' : 'underline'
        })
        break
      case 'l':
        break
      case 'h':
        break
      case 'f':
        break
      case 'g':
        if (shiftKey) {
          this.operateGroup.disaggregateGroup()
        } else {
          this.operateGroup.setGroup()
        }
        this.draw()
        break
    }
  },
  mousedown: function(ev) {
    if (ev.target !== this.$wrapper) return
    this.$focusBoard()
    this.eventdown(ev, ev.buttons)
  },
  mousemove: function(ev) {
    // todo 目前事件系统需要确定范围，此逻辑优化
    if (ev.target !== this.$wrapper) return
    this.eventmove(ev, ev.buttons)
  },
  mouseup: function(ev) {
    if (ev.target !== this.$wrapper) return
    this.eventup(ev)
  },
  dblclick: function() {
    if (this._node) {
      this.operateNode.ondblclick(this._node)
    } else if (this._link) { // 点击连接线 添加文字
      this.operateLink.ondblclick(this._link)
    } else { // 双击空白处
    }
  },
  focus() {
    this.hacker.isActivated && this.hacker._onblur()
    this.$focusBoard()
  },
  wheel: function(ev) {
    ev.preventDefault()
    const mouse = {
      x: ev.$x,
      y: ev.$y
    }
    const move = {
      x: -ev.deltaX,
      y: -ev.deltaY
    }

    const stepSize = ev.wheelDelta / 120
    const scale = this.transform.scale
    if (Utils.isMac()) { // mac 系统
      if (ev.ctrlKey) {
        this.operateBoard.scaleCanvasByStep(mouse, stepSize)
      } else {
        this.operateBoard.moveBoard({x: move.x / scale, y: move.y / scale})
      }
    } else { // 其他系统
      this.operateBoard.scaleCanvasByStep(mouse, stepSize)
    }

    this.redrawFloatMenu()
  },
  contextmenu() {
    console.log('右键菜单')
  },
  drop: function(ev) {
    const files = ev.dataTransfer.files
    this.picture.uploadImg(files[0], 'drag')
    ev.stopPropagation()
    ev.preventDefault()
  },
  dragover: function(ev) {
    const mouse = { x: ev.$x, y: ev.$y }
    const point = this.getDrawCoordinate(mouse.x, mouse.y) // 鼠标对应的画点
    this.mouse.setPosition(mouse).setBoardPosition(point)
    ev.stopPropagation()
    ev.preventDefault()
  }

}
export default events
