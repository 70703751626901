import Lock from '../shapes/lock'
import Locker from '../shapes/locker'

class FuncLock {
  constructor(ctx, target, board) {
    this.target = target
    this.board = board
    this.ctx = ctx
    this.lock = new Lock(ctx, 0, 0)
    this.locker = new Locker(ctx, 0, 0)
    this.style = new Style(this)
    this.isMouseIn = false
    this.lockType = { all: false, content: false, size_position: false, style: false } // 锁定的类型 sap: size and position, style, content
    this.preLockType = null
  }
  get isLockedPas() { // pas: position and size
    return this.lockType.size_position
  }
  get isLockedStyle() {
    return this.lockType.style
  }
  get isLockedContent() {
    return this.lockType.content
  }
  get isLocked() {
    return Object.values(this.lockType).some(t => t)
  }
  apply() {
    const { left, top, right, bottom, width, height, name } = this.target
    if (name === 'link') {
      this.lock.setStart(right + 10, bottom).setWidth(15).setHeight(20)
    } else {
      this.lock.setStart(right + 10, top).setWidth(15).setHeight(20)
    }
    this.locker.setStart(left, top).setWidth(width).setHeight(height)
  }
  draw() {
    if (this.isLocked) {
      this.lock.fill()
      this.locker.stroke()
    }
  }
  setLock(v) {
    for (const [key, value] of Object.entries(v)) {
      this.lockType[key] = value
    }
    const isLockedAll = !Object.values(this.lockType).some(t => !t)
    if (isLockedAll) { // 锁定所有功能
      this.style.setAllLockStyle()
    } else {
      this.style.setLockStyle()
    }
  }
  unlock() {
    for (const key of Object.keys(this.lockType)) {
      this.lockType[key] = false
    }
  }
  eventmove(x, y) {
    if (!this.isLocked) return false
    this.isMouseIn = this.lock._isQuickInPath(x, y)
    return this.isMouseIn
  }
}
class Style {
  constructor(target) {
    this.target = target
  }
  apply(style = {}) {
    this.backgroundColor = style.backgroundColor || '#fcce14'
    this.lineColor = style.lineColor || '#fcce14'
    this.lockerColor = '#e81313' // 锁定分为锁定所有功能和锁定部分功能, 样式不同，此颜色为全锁定的样式
    this.lineWidth = style.lineWidth || 1
    this.lineDash = style.lineDash || [5, 5]
    this._apply()
  }
  setAllLockStyle() { // 设置锁定所有时的样式
    const { lock, locker } = this.target
    locker.getStyle().setStrokeStyle(this.lockerColor).setLineDash([])
    lock.getStyle().setFillStyle(this.lockerColor).setStrokeStyle(this.lockerColor)
  }
  setLockStyle() { // 设置锁定部分的样式
    this._apply()
  }
  _apply() {
    const { lock, locker } = this.target
    locker.getStyle().setStrokeStyle(this.lineColor).setLineWidth(this.lineWidth).setLineDash(this.lineDash)
    lock.getStyle().setFillStyle(this.backgroundColor).setStrokeStyle(this.lineColor)
  }
}
export default FuncLock
