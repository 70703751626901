import Line from './line'
class BezierLine extends Line {
  constructor(ctx, sx = 0, sy = 0, ex = 0, ey = 0, cp1x = 0, cp1y = 0, cp2x = 0, cp2y = 0, ox = 0, oy = 0) {
    super(ctx, sx, sy, ex, ey, ox, oy)
    this.setControlPoint1X(cp1x)
      .setControlPoint1Y(cp1y)
      .setControlPoint2X(cp2x)
      .setControlPoint2Y(cp2y)
  }
  process() { // 绘制bezier曲线，根据传入的点数进行判断
    var ctx = this.ctx
    ctx.beginPath()
    ctx.moveTo(this._sx, this._sy)
    if (this.cp1x === void 0) {
      ctx.lineTo(this._ex, this._ey)
    } else {
      if (this.cp2x === void 0) {
        ctx.quadraticCurveTo(this._cp1x, this._cp1y, this._ex, this._ey)
      } else {
        ctx.bezierCurveTo(
          this._cp1x,
          this._cp1y,
          this._cp2x,
          this._cp2y,
          this._ex,
          this._ey
        )
      }
    }
  }
  setOriginX(ox) {
    return (
      Line.prototype.setOriginX.call(this, ox),
      (this._cp1x = this.cp1x - ox),
      (this._cp2x = this.cp2x - ox),
      this
    )
  }
  setOriginY(oy) {
    return (
      Line.prototype.setOriginY.call(this, oy),
      (this._cp1y = this.cp1y - oy),
      (this._cp2y = this.cp2y - oy),
      this
    )
  }
  getControlPoint1X() {
    return this.cp1x
  }
  setControlPoint1X(cp1x) {
    this.cp1x = cp1x
    this._cp1x = cp1x - this.ox
    return this
  }
  getControlPoint1Y() {
    return this.cp1y
  }
  setControlPoint1Y(cp1y) {
    this.cp1y = cp1y
    this._cp1y = cp1y - this.oy
    return this
  }
  setControlPoint1(cp1x, cp1y) {
    return this.setControlPoint1X(cp1x).setControlPoint1Y(cp1y)
  }
  getControlPoint2X() {
    return this.cp2x
  }
  setControlPoint2X(cp2x) {
    this.cp2x = cp2x
    this._cp2x = cp2x - this.ox
    return this
  }
  getControlPoint2Y() {
    return this.cp2y
  }
  setControlPoint2Y(cp2y) {
    this.cp2y = cp2y
    this._cp2y = cp2y - this.oy
    return this
  }
  setControlPoint2(cp2x, cp2y) {
    return this.setControlPoint2X(cp2x).setControlPoint2Y(cp2y)
  }
  factorial(num) { // 递归阶乘
    if (num <= 1) {
      return 1
    } else {
      return num * this.factorial(num - 1)
    }
  }
  bezier(arr, t) {
    var x = 0
    var y = 0
    var n = arr.length - 1
    var self = this
    arr.forEach(function(item, index) {
      if (!index) {
        x += item.x * Math.pow((1 - t), n - index) * Math.pow(t, index)
        y += item.y * Math.pow((1 - t), n - index) * Math.pow(t, index)
      } else {
        x += self.factorial(n) / self.factorial(index) / self.factorial(n - index) * item.x * Math.pow((1 - t), n - index) * Math.pow(t, index)
        y += self.factorial(n) / self.factorial(index) / self.factorial(n - index) * item.y * Math.pow((1 - t), n - index) * Math.pow(t, index)
      }
    })
    return {
      x: x,
      y: y
    }
  }
  getMiddlePoint(arr) {
    return this.bezier(arr, 0.5)
  }
  getLastPoint(arr) {
    return this.bezier(arr, 1)
  }

  patchShape(move) {
    this._sx += move.x
    this._sy += move.y
    this._ex += move.x
    this._ey += move.y
    this._cp1x += move.x
    this._cp1y += move.y
    this._cp2x += move.x
    this._cp2y += move.y
  }

  getShapePatch() {
    const xArr = [this._ex, this._cp1x, this._cp2x]
    const yArr = [this._ey, this._cp1y, this._cp2y]
    let x = this.sx
    let y = this.sy

    xArr.forEach(ex => {
      if (ex < x) x = ex
    })

    yArr.forEach(ey => {
      if (ey < y) y = ey
    })
    return { x, y }
  }
}

export default BezierLine
