import Line from '../../shapes/line'

class _Line extends Line {
  constructor(ctx, start, end) {
    super(ctx, start.x, start.y, end.x, end.y)
    this.setStartPoint(start)
    this.setEndPoint(end)
    this.isVertical && (this.isDownAtInit = this.start.y < this.end.y)
    this.isHorizon && (this.isRightAtInit = this.start.x < this.end.x)
  }
  process() {
    const { ctx, start, end } = this
    ctx.beginPath()
    ctx.moveTo(start.x, start.y)
    ctx.lineTo(end.x, end.y)
  }
  offset(x, y) {
    const { start, end } = this
    start.offset(x, y)
    end.offset(x, y)
    this.setStart(start.x, start.y)
    this.setEnd(end.x, end.y)
  }
  hasTail(point) { // 判断一个点是否是该线的尾点
    return this.start === point || this.end === point
  }
  isJoinedAsLine(line) { // 判断两条线段是否有重叠
    let flag
    if (this.isVertical !== line.isVertical) return false
    const { sx, ex, sy, ey } = this
    if (this.isVertical) {
      for (const p of [line.sx, line.ex]) {
        if (p >= Math.min(sx, ex) && p <= Math.max(sx, ex)) { flag = true; break }
      }
    } else {
      for (const p of [line.sy, line.ey]) {
        if (p >= Math.min(sy, ey) && p <= Math.max(sy, ey)) { flag = true; break }
      }
    }
    return flag
  }
  setStartPoint(v) {
    this.start = v
    this.setStart(v.x, v.y)
    return this
  }
  setEndPoint(v) {
    this.end = v
    this.setEnd(v.x, v.y)
    return this
  }
  setControlPoint(v) { // 设置可以拖动连接线的控制点
    this.controlPoint = v
  }
  get kx() {
    return this.start.x
  }
  get ky() {
    return this.start.y
  }
  get jx() {
    return this.end.x
  }
  get jy() {
    return this.end.y
  }
  get isVertical() {
    const { kx, jx, ky, jy } = this
    return Math.abs(kx - jx) < 2 && parseInt(ky) !== parseInt(jy)
  }
  get isHorizon() {
    const { ky, jy } = this
    return Math.abs(ky - jy) < 2
  }
  get length() {
    const { kx, ky, jx, jy } = this
    const length = Math.sqrt(Math.pow(kx - jx, 2) + Math.pow(ky - jy, 2))
    return length
  }

  patchShape(move) {
    this.start.x += move.x
    this.start.y += move.y
    this.end.x += move.x
    this.end.y += move.y
  }

  getShapePatch() {
    const x = this.start.x < this.end.x ? this.start.x : this.end.x
    const y = this.start.y < this.end.y ? this.start.y : this.end.y

    return { x, y }
  }
}

export default _Line

