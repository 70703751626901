export const initState = {
  title: '流程图',
  canvas: {
    color: '',
    unit: '',
    infinite: true,
    size: { width: 0, height: 0 }
  },
  canvasScale: 0,
  page: {
    size: {
      type: 'A3', // A3, A4, custom
      width: 297,
      height: 420
    },
    auto: false,
    tiling: false
  },
  grid: {
    isShow: true, // show grid
    align: true, // snap to grid
    alignNode: true, // snap to node
    size: 10
  }
}

export const initSelectObject = {
  activeNode: {
    funcLock: {
      lockType: {}
    },
    style: {
      lineWidth: -1,
      lineDash: []
    }
  },
  activeNodeVersion: 0,
  lockStatus: {}
}

// 对应工具栏中的默认状态
export const initSystemConfig = {
  lineType: 'straight', // 'curve'
  floatMenu: {
    type: null,
    position: { x: -1000, y: -1000 },
    show: true
  }
}
