/*
  此类作为背景层的控制使用，包含三层
  从内向外依次为：canvas 画布绘制的纯色背景
 */
class Board {
  constructor(target) {
    this.target = target;
    this.imgCache = new Map()
    this.box = target.$canvas // 背景图的载体
  }
  static setOnOperation(onOperation) {
    this.prototype.onOperation = onOperation;
  }

  static OPERATION = {
    CHANGEBOARD: "_changeBoardOperation",
  };

  initStyle() {
    const { backgroundImage } = this.target.style;
    this.isInit = true; // isInit: 是否是初始化
    this._changeBoardOperation(this.target.style);
    this.isInit = false;
  }

  changeBoard(config) {
    const preConfig = this.saveOldData(this.target);
    this._changeBoardOperation(config);
    this.onOperation(this.target, Board.OPERATION.CHANGEBOARD);
  }

  _changeBoardOperation(config) {
    if (this.isInit) {
      this.setBgColor(config);
      this.setBgImage(config);
      this.setWatermark(config);
    } else {
      config.backgroundColor && this.setBgColor(config);
      config.backgroundImage && this.setBgImage(config);
      config.watermarkDisplay && this.setWatermark(config);
    }
    this.isInit || Object.assign(this.target.style, config);
  }

  saveOldData(ctx) {
    const { backgroundColor, backgroundImage, watermark } = ctx.style;
    return {
      backgroundColor: backgroundColor,
      backgroundImage: backgroundImage,
      watermark: watermark,
    };
  }
  // 设置背景颜色
  setBgColor(config) {
    const { backgroundColor } = config;
    if (backgroundColor) {
      this.box.style.background = backgroundColor
      config.backgroundImage = 'none'
    }
  }
  // 设置背景图片
  setBgImage(config) {
    const image = config.backgroundImage;
    const style = this.box.style
    if (image) {
      if (image === 'None') {
        style.background = "";
      } else {
        /* 此处使用异步的原因： 直接指定背景图片不生效，先清空，
           浏览器渲染完后再重新设置就会生效，和浏览器的渲染机制有关系，背景图片在设置之前就已经加载完毕
        */
        setTimeout(_ => style.background = `url("${image}") no-repeat center/cover`, 0)
        style.background = "#eee"
        this.imgCache.get(image) || this.loadImg(image).then(img => {
          this.imgCache.set(image, img)
        })
      }
    }
  }
  // 设置水印
  setWatermark(config) {
    const { watermarkDisplay } = config;
    if (watermarkDisplay) {
      if (!config.watermark) config.watermark = "树图网 www.shutu88.com";
      this.target.watermark.setText(config.watermark);
      this.target.watermark.apply();
    }
  }
  // 设置线型
  setLineType(config) {
    const { lineType = 'straight' } = config;
    this.target.lineType = lineType
  }
  loadImg(url) {
    return new Promise((rs, rj) => {
      const img = new Image()
      img.src = url
      img.addEventListener('load', _ => {
        rs(img)
      })
    })
  }
}

export default Board;
