import React, { useCallback, useRef } from 'react'

import { NavBarCssWrapper } from './styles'
import { changeGridSetting, changePageSetting, changeCanvasSetting } from 'store/actions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import IconButton from 'components/comm/IconButton'
import { useBoardContext } from 'views/board/hooks'
import user_large_solid from 'assets/img/sharp/user-large-solid.png'

import Upload from 'utils/upload'
import { UPLOAD_PIC } from '../../api'
import { saveDoc } from '../../utils/api'

const upload = new Upload({
  fileSize: 20 * 1024 * 1024, // 上传图片最大大小
  fileType: '|jpg|jpeg|png|webp'
})

function Navbar(props) {
  const {
    edition_seven_seven,
    onListShapesFn, listShapes,
    grid, onChangeGridSetting
  } = props

  const fileEl = useRef(null)
  const { board } = useBoardContext()

  const handleChangeGridSetting = useCallback(
    (...args) => {
      board.api.setGrid(...args)
      onChangeGridSetting(...args)
    },
    [board]
  )

  const disableDropdown = () => {
    // dismiss menu by disabling each drop down menu in UI
    const items = document.getElementsByClassName('dropdown')

    for (var i = 0; i < items.length; i++) {
      items[i].style.display = 'none'
    }

    setTimeout(() => {
      for (var i = 0; i < items.length; i++) {
        items[i].style.display = ''
      }
    })
  }

  const handleAction = useCallback((type) => {
    switch (type) {
      case 'undo':
        board.api.back()
        break
      case 'redo':
        board.api.forward()
        break
      default: {
        console.log('>>> default', type)
      }
    }
  }, [board])

  const handClick = (ev, e) => {
    ev.preventDefault()
    disableDropdown()

    switch (e.key) {
      case 'image': {
        fileEl.current.click()
        break
      }
      case 'view_grid_show': {
        handleChangeGridSetting({ ...grid, isShow: !grid.isShow })
        break
      }
      case 'view_grid_align': {
        handleChangeGridSetting({ ...grid, align: !grid.align })
        break
      }

      case 'view_catch_object': {
        handleChangeGridSetting({ ...grid, alignNode: !grid.alignNode })
        break
      }

      case 'file_save': {
        saveFn()
        break
      }

      case 'file_export': {
        board.api.saveAsImage()
        break
      }

      default: {
        console.log('default:', e.key)
      }
    }
  }

  const saveFn = () => {
    saveDoc(board.save())
  }

  const handleImageChange = (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    e.target.value = ''
    const handleUpload = upload.handleBeforeUpload(file)

    if (handleUpload.status) {
      upload.uploadPicFile(file, UPLOAD_PIC).then(res => {
        if (res.status === 200) {
          board.api.setPicture(res.data.url)
        } else {
          console.log('上传错误!')
        }
      })
    }
  }

  const handleMove = useCallback((event) => {
    event.stopPropagation()
    event.preventDefault()
  }, [])

  return (
    <NavBarCssWrapper onMouseMove={handleMove}>
      <div className='navBarHead'>
        <div className='iconBtn'>
          <div>
            <IconButton
              name={'zidingyi'}
              showToolTip={false}
            />
          </div>
        </div>
        <div className='box'>
          <div className='leftBox'>
            <div className='text'>
              <div className='name'>无标题白板</div>
              <div className='saveTime'>自动保存于 22:00:21</div>
            </div>

            {edition_seven_seven && <div className='shapeDiv'>
              {listShapes.map((item, index) => {
                return (
                  <div className='shapes' key={index} onClick={() => onListShapesFn(index)}>
                    <div className='shapesIcon'>
                      <IconButton
                        name={item.src}
                        showToolTip={false}
                      />
                    </div>
                    <div className='shapesTitle'>
                      {item.title}
                    </div>
                  </div>
                )
              })}
            </div>}
          </div>
          <div className='rightBox'>
            <div className='export' onClick={(ev) => handClick(ev, { name: '导出', key: 'file_export' })}>
              <div className='icon'>
                <IconButton
                  name={'export'}
                  showToolTip={false}
                  widthInPx={18}
                  heightInPx={18}
                />
              </div>
              <div className='text'>导出</div>
            </div>
            <div className='user'>
              <img src={user_large_solid} alt='' />
            </div>
          </div>
        </div>

        <div className='posiBtn'>
          <div className='save boxBtn' onClick={(ev) => handClick(ev, { name: '保存', key: 'file_save', sk: 'ctr+s' })}>
            <div className='btnIcon'>
              <IconButton
                name={'save'}
                toolTip={'保存'}
                navText={'Ctrl+s'}
                navStyle={true}
              />
            </div>
          </div>
          <div className='undo boxBtn'
            onClick={() => { handleAction('undo') }}
          >
            <div className='btnIcon'>
              <IconButton
                name={'undoundo'}
                toolTip={'撤销'}
                navText={'Ctrl+Z'}
                navStyle={true}
              />
            </div>
          </div>
          <div
            className='redo boxBtn'
            onClick={() => { handleAction('redo') }}
          >
            <div className='btnIcon'>
              <IconButton
                name={'redoredo'}
                toolTip={'重做'}
                navText={'Ctrl+Shift+Z'}
                navStyle={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='insertFile'>
        <input
          onChange={(e) => { handleImageChange(e) }}
          ref={fileEl} type='file' accept='image/*' style={{ display: 'none' }} />
      </div>
    </NavBarCssWrapper>
  )
}

Navbar.propTypes = {
  canvas: PropTypes.object,
  page: PropTypes.object,
  grid: PropTypes.object,
  onChangePageSetting: PropTypes.func,
  onChangeGridSetting: PropTypes.func,
  onChangeCanvasSetting: PropTypes.func,
  listShapes: PropTypes.array,
  onListShapesFn: PropTypes.func,
  edition_seven_seven: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    canvas: state.canvasConfig.canvas,
    page: state.canvasConfig.page,
    grid: state.canvasConfig.grid
  }
}
const mapDispatchToProps = dispatch => ({
  onChangeCanvasSetting: v => dispatch(changeCanvasSetting(v)),
  onChangePageSetting: v => dispatch(changePageSetting(v)),
  onChangeGridSetting: v => dispatch(changeGridSetting(v))
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
