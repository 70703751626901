import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const CssWrapper = styled.div`
  display: flex;
  cursor: pointer;

  .svgIcon {
    pointer-events: none;
  }

  .svgIcon2 {
    pointer-events: none;
    fill: #c2c2c2;
  }

  .svgIcon2:hover {
    pointer-events: none;
    fill: #000;
  }
`

const IconButton = ({ name, shapeProps, widthInPx = 27, heightInPx, fill, selected, data = {}, className, onClick, toolTip, showToolTip = true, links, navText, direction, navStyle, ...rest }) => {
  const DefaultSelectedColor = navStyle ? '#000' : '#1071e5'

  const [hovered, setHovered] = useState(false)
  const fillStyle = hovered || selected || data.selectNode ? { fill: fill || DefaultSelectedColor } : {}

  const handleMouseMove = (type) => {
    if (type === 'enter') {
      setHovered(true)
    } else {
      setHovered(false)
    }
  }
  const _shapeProps = useRef(JSON.stringify(shapeProps))

  return (

    <CssWrapper
      onClick={onClick}
      {...rest}
      className={className || ''}
      onMouseEnter={() => handleMouseMove('enter')}
      onMouseOut={() => handleMouseMove('out')}
      style={direction === 'left' ? { display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-2px' } : { }}
    >
      <div className='tooltip' style={navStyle ? { fontSize: '14px' } : {}}>
        <div className='dragItem flex' key='svg' shapeprops={_shapeProps.current}>
          {!links && <svg
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            className={navStyle ? 'svgIcon2 ' : 'svgIcon '}
            style={fillStyle}
            width={widthInPx}
            height={heightInPx || widthInPx}
          >
            <use xlinkHref={`#icon-${name}-svg`} />
          </svg>}
        </div>
      </div>
    </CssWrapper >
  )
}

IconButton.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  fill: PropTypes.string,
  selected: PropTypes.bool,
  widthInPx: PropTypes.number,
  heightInPx: PropTypes.number,
  toolTip: PropTypes.string,
  showToolTip: PropTypes.bool,
  data: PropTypes.any,
  shapeProps: PropTypes.any,
  links: PropTypes.bool,
  navText: PropTypes.string,
  navStyle: PropTypes.bool,
  direction: PropTypes.string
}

export default IconButton
