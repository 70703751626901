/* eslint-disable no-sequences */
/*
  表示决定的icon 圆弧菱形
 */
import Process from './process'
class Decision extends Process {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.width = width
    this.height = height
    this.arc1 = width * 4
    this.arc2 = height * 4
  }
  process() {
    const { ctx, _left, _top, _right, _bottom, width, height } = this
    const halfX = _left + width / 2
    const halfY = _top + height / 2
    // const angle = Math.atan(width / height)
    // const sinV = Math.sin(angle) * arcRadius
    // const cosV = Math.cos(angle) * arcRadius
    let arc2 = this.arc2 / height
    let arc1 = this.arc1 / width
    if (width < 28) arc1 = 0, arc2 = 1
    if (height < 28) arc2 = 0, arc1 = 1

    const start = { x: (_left + halfX) / 2, y: (_top + halfY) / 2 }
    const fc = { x: _left, y: halfY } // first corner 第一个拐角的点
    const sc = { x: halfX, y: _top }
    const tc = { x: _right, y: halfY }
    const lc = { x: halfX, y: _bottom }

    ctx.beginPath()
    ctx.moveTo(start.x, start.y)
    ctx.arcTo(sc.x, sc.y, tc.x, tc.y, arc2)
    ctx.arcTo(tc.x, tc.y, lc.x, lc.y, arc1)
    ctx.arcTo(lc.x, lc.y, fc.x, fc.y, arc2)
    ctx.arcTo(fc.x, fc.y, start.x, start.y, arc1)
    ctx.lineTo(start.x, start.y)
  }
}
export default Decision
