import Circle from '../comm/customCircle'
import PartialLine from '../comm/partialLine'
import Line from '../../shapes/line'
import mathHelper from '../../../utils/mathHelper'

// 复合曲线
class CompoundLine {
  /**
   * @param {*} config 主要用于创建初始的配置参数, 可以为空
   */
  constructor(ctx, board, style, config) {
    this.ctx = ctx
    this.board = board
    this.style = style

    // 直线头节点
    this.straightHead = this.createCircle() // 头部调节点

    // 直线中点载体
    this.centerSplit = this.createCircle()

    // 直线
    this.straightLine = this.createPartialLine(config)
    this.background = this.createLine(config)

    // 直线拐角填补圆
    this.straightPatchCircle = this.createCircle()
    this.backgroundPatchCircle = this.createCircle()
  }

  createCircle() {
    return new Circle(this.ctx, 0, 0, 0, 0, 0, 0)
  }

  createPartialLine(config) {
    const { start = { x: 0, y: 0 }, end = { x: 0, y: 0 }} = config
    return new PartialLine(this.ctx, start.x, start.y, end.x, end.y)
  }

  createLine(config) {
    const { start = { x: 0, y: 0 }, end = { x: 0, y: 0 }} = config
    return new Line(this.ctx, start.x, start.y, end.x, end.y)
  }

  getBasicSize() {
    return Math.min(this.board.getDrawLength(this.style.headWidth))
  }

  // 中心点和straightHead
  updateLineHeadSize(size) {
    this.straightHead.setWidth(size).setHeight(size)
    this.centerSplit.setWidth(size).setHeight(size)

    return this
  }

  getPatchedLinePoints(move) {
    const { start, end } = this.getLineTwoEndPoints()
    const nStart = mathHelper.getPointPatchedPos(start, move)
    const nEnd = mathHelper.getPointPatchedPos(end, move)
    return { start: nStart, end: nEnd }
  }

  offset(x, y) {
    const { start, end } = this.getPatchedLinePoints({ x, y })
    this.updateStraightLine(start, end)
  }

  draw(isSelected, isShowHead) {
    this.straightLine.stroke()

    if (isSelected) {
      this.background.stroke()
      this.backgroundPatchCircle.fill()

      this.centerSplit.strokeFill()

      if (isShowHead) {
        this.straightHead.strokeFill()
      }
    }

    if (isShowHead) {
      this.straightPatchCircle.fill()
    }
  }

  getLineTwoEndPoints() {
    const { sx, sy, ex, ey } = this.straightLine
    return {
      start: { x: sx, y: sy },
      end: { x: ex, y: ey }
    }
  }

  getLineCenterPoint() {
    const cS = this.centerSplit
    const cp = { x: cS.sx, y: cS.sy }
    return cp
  }

  // 更新直线
  updateStraightLine(start, end, option = { startArrowPoint: null, endArrowPoint: null }) {
    this.straightLine.setStart(start.x, start.y, option.startArrowPoint).setEnd(end.x, end.y, option.endArrowPoint)
    this.background.setStart(start.x, start.y).setEnd(end.x, end.y)

    const mid = { x: (start.x + end.x) / 2, y: (start.y + end.y) / 2 }

    const size = this.getBasicSize()
    // 头节点
    this.straightHead.setWidth(size).setHeight(size).setStart(start.x, start.y)
    // 更新中点
    this.centerSplit.setWidth(size).setHeight(size).setStart(mid.x, mid.y)

    // 更新patch节点
    this.straightPatchCircle.setStart(start.x, start.y)
    this.backgroundPatchCircle.setStart(start.x, start.y)
  }

  isEnterBackground(x, y) {
    return this.background._isQuickInStroke(x, y, 3)
  }

  isTouchingSplitPoint({ x, y }, gap = 0) {
    const circle = this.centerSplit
    const halfW = circle.width / 2

    const isIn = circle._isQuickInPath(x + halfW, y + halfW, gap)
    if (isIn) {
      return 'split'
    }
  }

  // 是否进入中点(已经分割过了)
  isTouchingOnDragPoint({ x, y }, gap = 0) {
    const circle = this.straightHead
    const halfW = circle.width / 2

    const isIn = circle._isQuickInPath(x + halfW, y + halfW, gap)
    if (isIn) {
      return 'dragPoint'
    }
  }
}

export default CompoundLine
