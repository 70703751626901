import React from 'react'
import { SelectNum, SelectS } from 'components/comm/Select'
import IconButton from 'components/comm/IconButton'
import { FontSize } from '../config'
import InsertLink, { InsertTypeConfig } from '../insertLink'
import PropTypes from 'prop-types'
import { IconButtonWithColor2 } from 'components/comm/Buttons'
import { ToolsTextStyle } from './style'

const ToolsText = (props) => {
  const {
    selectObject
  } = props
  const { activeNode } = selectObject
  const { edition_seven_seven, listChecked1, listChecked0, fontWeight, handleChangeStyle, fontStyle, underline, fontSize, fontColor, fontFamily, handLinkChange, onChangeChecked } = props
  return (
    <ToolsTextStyle>
      <div className='toolsText'>
        <div className='font'>
          <div className='bold box'>
            <IconButton name='bold' toolTip='粗体'
              selected={fontWeight === 'bold'}
              onClick={() => { handleChangeStyle({ fontWeight: fontWeight === 'bold' ? 'normal' : 'bold' }) }}
            />
          </div>
          <div className='italic box'>
            <IconButton name='italic' toolTip='斜体'
              selected={fontStyle === 'italic'}
              onClick={() => { handleChangeStyle({ fontStyle: fontStyle === 'italic' ? 'normal' : 'italic' }) }}
            />
          </div>
          <div className='underline box'>
            <IconButton name='underline' toolTip='下划线'
              selected={underline === 'underline'}
              onClick={() => { handleChangeStyle({ underline: underline === 'underline' ? 'none' : 'underline' }) }}
            />
          </div>
        </div>
        <div className='font2'>
          <div className='name'>字号</div>
          <div className='num'>
            <SelectNum
              values={FontSize}
              curValue={fontSize}
              onValueChange={(value) => { handleChangeStyle({ fontSize: value }) }}
            />
          </div>
        </div>
        <div className='font2'>
          <div className='name'>文本颜色</div>
          <div className='num boxXZ'>
            <IconButtonWithColor2
              name='text-color'
              showToolTip={false}
              link='link'
              color={fontColor}
              widthInPx={24}
              onColorChange={(value) => handleChangeStyle({ fontColor: value })}
            />
          </div>
        </div>
        {edition_seven_seven && <div className='font2'>
          <div className='name'>选择字体</div>
          <div className='num num3'>
            <SelectS values={[fontFamily || '微软雅黑']} />
          </div>
        </div>}
        {edition_seven_seven && <div className='alignment'>
          <div className='alignmentTop'>
            <div className='icon' onClick={() => onChangeChecked(0)}>
              <IconButton
                name={listChecked0 ? 'topBtn' : 'bottomBtn'}
                showToolTip={false}
              />
            </div>
            <div className='name'>对齐方式</div>
          </div>
          {
            listChecked0 && <div className='btn btnLink'>
              <div className='btnIconGN'>
                <IconButton
                  name={'juleft'}
                  showToolTip={false}
                  heightInPx={18}
                />
              </div>
              <div className='btnIconGN'>
                <IconButton
                  name={'juzhong'}
                  showToolTip={false}
                  heightInPx={18}
                />
              </div>
              <div className='btnIconGN'>
                <IconButton
                  name={'juright'}
                  showToolTip={false}
                  heightInPx={18}
                />
              </div>
            </div>
          }
          {/* <AlignmentBtn alignType={alignType} onAlignChanged={(value) => handleChangeStyle({ textAlign: value })} /> */}
        </div>}
        {edition_seven_seven && <div className='alignment'>
          <div className='alignmentTop'>
            <div className='icon' onClick={() => onChangeChecked(1)}>
              <IconButton
                name={listChecked1 ? 'topBtn' : 'bottomBtn'}
                showToolTip={false}
              />
            </div>
            <div className='name'>链接</div>
          </div>
          {listChecked1 && <div className='btn btnLink'>
            <InsertLink data={{ InsertTypeConfig, selectNode: activeNode }} onChange={handLinkChange} showToolTip={false}/>
          </div>}
        </div>}
      </div>
    </ToolsTextStyle>
  )
}

export { ToolsText }

ToolsText.propTypes = {
  fontColor: PropTypes.string,
  fontSize: PropTypes.number,
  fontFamily: PropTypes.array,
  fontWeight: PropTypes.string,
  fontStyle: PropTypes.string,
  underline: PropTypes.string,
  handLinkChange: PropTypes.func,
  listChecked0: PropTypes.bool,
  listChecked1: PropTypes.bool,
  selectObject: PropTypes.object,
  handleChangeStyle: PropTypes.func,
  onChangeChecked: PropTypes.func,
  edition_seven_seven: PropTypes.bool
}
