/*
  添加内容输入层 textarea
  pintevent: none   auto-默认值  none-鼠标捕捉不到元素，可以透过元素捕捉到下层元素
  _bind: 输入层覆盖的元素，输入层的大小和 _bind 保持一致
  oncontextmenu: 用户右击鼠标时触发打开上下文菜单
 */
class Hacker {
  constructor(board) {
    this.init();
    this.initCallbacks();
    this.board = board
    this.preValue = '' // 没有修改前的值
  }
  static unit = 'px'

  init () {
    this.border = document.getElementById("action-wrapper");
    this.textarea = document.getElementById("draw-action")
    this.border.style.display = 'initial'
    this.deactivate();
  }

  initCallbacks () {
    var border = this.border;
    border.onmousedown = border.onmousemove = border.onmouseup =
      border.ontouchstart = border.ontouchmove = border.ontouchend =
        border.onmouseout = border.ontouchcancel = border.ondblclick = function (ev) {
          ev.stopPropagation(); // 取消事件传递
        };
    border.oncontextmenu = function (ev) {
      ev.stopPropagation();
      ev.preventDefault(); // TODO: 使用 oncontextmenu(x, y) 回调出去，调用 Menu 菜单
    };
    border.onkeydown = (ev) => {
      var key = ev.key;
      if (this.isActivated) {
        ev.stopPropagation();
        switch (key) {
          case "s":
          case "o":
            if (ev.ctrlKey) ev.preventDefault(); // 取消 ctrl + s || o 的默认事件
            break;
          case "Tab":
            ev.preventDefault()
            break;
          case "Escape": // 按下esc保存输入的值同步到绑定的节点
            this.setBindValue()
            break;
          case "Enter":
            ev.preventDefault();
            var value = this.getValue(), start = this.selectionStart,
                end = this.selectionEnd;
              this.setValue(value.substr(0, start) + "\n" + value.substr(end));
              this.selectionEnd = start + 1;
              this._oninput()
            break;
        }
      }
    }
    border.oninput = this._oninput.bind(this)
    this.textarea.onblur = this._onblur.bind(this)
  }
  _oninput () {
    var bind = this._bind;
    this.board.operateNode._setValueOperation(bind, this.getValue())
    this.resize()
    // 实时布局
    this.oninput(bind);
  }
  _onblur () {
    this.setBindValue()
  }
  setBindValue() {
    this.deactivate()
    const value = this.getValue()
    this._bind.showText()
    this._bind.draw()
    this._bind.setValue(value)
  }
  bind (node) { // 绑定对应的node节点
    if (!node) return
    this.deactivate();
    this._bind = node;
    return this
  }

  activate (node) { // 激活输入层
    if (!node) return
    this.beforeActivate(node);
    this._bind = node
    this._isActivated = true
    this.border.style.opacity = "1"
    this.border.style.pointerEvents = "auto"
    this._bind.hideText()
    this._bindParams(node)
    this._bindStyles(node)
    this.callDraw()
  }
  deactivate () {
    this._isActivated = false;
    this.border.style.opacity = "0";
    this.border.style.pointerEvents = "none";
    this._bind && this._bind.showText()
  }
  get isActivated() {
    return this._isActivated;
  }
  _bindParams () {
    this.follow();
    this.setValue(this._bind.getValue());
    this.resize()
    this.textarea.select();
  }
  resize() {
    const {textHeight, style, width} = this._bind
    const { transform, $dpr } = this.board
    const scale = transform.scale / $dpr
    this.setWidth((width - (style.paddingLeft) * 2) * scale)
    this.setHeight(Math.max((textHeight + 5) * scale, 30 * scale))
    this.follow()
  }
  setStyle(styleConfig){
    for(let style in styleConfig){
      this.textarea.style[style] = styleConfig[style]+Hacker.unit
    }
  }
  _bindStyles () {
    let { transform, $dpr } = this.board
    let { scale } = transform
    scale = scale / $dpr
    var border = this.border.style, textarea = this.textarea.style, _style = this._bind.style;
    const alignX = this._bind.alignX || 'center'
    border.border = 'none'
    textarea.backgroundColor = 'transparent'
    textarea.textAlign = alignX
    textarea.padding = '0'
    textarea.color = _style.color;
    textarea.font = _style.font;
    textarea.lineHeight = Math.max(_style.lineHeight * scale, 12) + Hacker.unit;
    textarea.fontSize = _style.fontSize * scale + Hacker.unit;
  }

  setStart (x, y) {
    let { $dpr,transform } = this.board
    const { scale, offsetX, offsetY } = transform
    this.border.style.left = (x * scale + offsetX) / $dpr + Hacker.unit;
    this.border.style.top = (y * scale + offsetY) / $dpr + Hacker.unit;
  }
  follow () {
    if (!this.isActivated) return
    const {x, y, style} = this._bind
    this.setStart(x + style.paddingLeft, y + style.contentTop - 3);
  }
  setWidth (width) {
    this.textarea.style.width = (this.width = width) + Hacker.unit;
  }
  setHeight (height) {
    this.textarea.style.height = (this.height = height) + Hacker.unit;
  }
  getValue () {
    return this.textarea.value;
  }
  setValue (value) {
    this.textarea.value = value;
  }
  get selectionStart() {
    return this.textarea.selectionStart;
  }
  set selectionStart(selectionStart) {
    this.textarea.selectionStart = selectionStart;
  }
  get selectionEnd() {
    return this.textarea.selectionEnd;
  }
  set selectionEnd(selectionEnd) {
    this.textarea.selectionEnd = selectionEnd;
  }

  callDraw () {}
  oninput () {}
  beforeActivate () {}
}

export default Hacker