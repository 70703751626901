import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CssWrapper } from '../styles'
import PropTypes from 'prop-types'

import IconButtonWithTooltip from 'components/antd/ToolTip/IconButtonWithTooltip'
import { useBoardContext } from 'views/board/hooks'

import { ButtonWithColorPick } from '../common/colorButton'
import { useLock } from '../hooks/useLock'

export default function FrameBar(props) {
  const { position: p, activeNode } = props
  const { style } = activeNode
  const { backgroundColor } = style
  const [width, setWidth] = useState(400)
  const containerRef = useRef(null)
  const { board } = useBoardContext()

  useEffect(() => {
    if (containerRef.current && containerRef.current.offsetWidth) {
      setWidth(containerRef.current.offsetWidth)
    }
  }, [containerRef])

  const changeNodeStyle = useCallback((config) => {
    board && board.api.changeNodeStyle(config)
  }, [board])

  // lock
  const { isLock, updateLockStatus } = useLock(activeNode)

  return (
    <CssWrapper
      ref={containerRef}
      style={{ left: `${p.x - width / 2}px`, top: `${p.y}px` }} >
      <div className='group'>
        <ButtonWithColorPick
          colors={'red'}
          color={backgroundColor}
          width={158}
          onColorChange={(v) => { changeNodeStyle({ backgroundColor: v }) }}
        />
        <IconButtonWithTooltip
          toolTip={isLock ? 'unLock' : 'lock'}
          name={isLock ? 'iconfont-Lock' : 'iconfont-unLock'}
          onClick={updateLockStatus}
        />
      </div>
    </CssWrapper>
  )
}

FrameBar.propTypes = {
  activeNode: PropTypes.object,
  position: PropTypes.object
}
