/*
  用于折线的分段设计和操作
 */
import Line from './sLine'
import Rect from '../../shapes/rect'
import Point from '../../shapes/point'
class OperateLine {
  constructor(target) {
    this.target = target
    this.lines = this.target.lines
    this.isMovingLine = false
    this.preLine = null
  }
  setLines() { // 根据已有的点数据生成线段
    this.lines.length = 0
    this.points.forEach((point, ind) => {
      if (this.points[ind + 1]) {
        this.lines.push(new Line(this.ctx, point, this.points[ind + 1]))
      }
    })
    const len = this.lines.length
    this.lines.first = this.lines[0]
    this.lines.second = this.lines[1]
    this.lines.secondToLast = this.lines[len - 2]
    this.lines.last = this.lines[len - 1]
  }
  setSplitTail() { // 设置拖动线段后，下一次线段走线的起始点（头部和尾部）
    const { points, lines } = this
    const index = (points.length - 2) || 0
    this.target.splitedEnd = {
      point: points[index],
      line: lines[lines.length - 2] || lines[0],
      before: [...this.points.slice(0, -2)]
    }
    this.target.splitedStart = {
      point: points[1] || points[0],
      line: lines[1] || lines[0],
      after: [...this.points.slice(2)]
    }
  }
  setSplitPoints() { // 根据已有的线段设置线段上的分割点
    let split
    this.splitPoints.length = 0
    this.lines.forEach(line => {
      split = new Rect(this.ctx, 0, 0, 0, 0)
      split.setCarrier(line)
      line.setControlPoint(split)
      this.splitPoints.push(split)
    })
    this.setSplitSize()
  }
  // 调整二分点大小
  setSplitSize() {
    let center
    const w = this.board.getDrawLength(this.target.style.splitWidth)
    this.splitPoints.forEach(s => {
      center = s.carrier.start.center(s.carrier.end)
      s.setWidth(w).setHeight(w)
      s.setStart(center.x - w / 2, center.y - w / 2)
    })
    return this
  }
  resetPoints(ps = []) { // 去除多余的画点，当三个点在一条直线时，需要去除中间点
    let next; let cur; let pre; const len = ps.length; let flag = false; let condition1; let condition2
    for (let i = 1; i < len; i++) {
      pre = ps[i - 1]
      cur = ps[i]
      next = ps[i + 1]
      if (next === void 0) break

      condition1 = Math.abs(parseInt(pre.x) - parseInt(cur.x)) < 1 && Math.abs(parseInt(cur.x) - parseInt(next.x)) < 1
      condition2 = Math.abs(parseInt(pre.y) - parseInt(cur.y)) < 1 && Math.abs(parseInt(cur.y) - parseInt(next.y)) < 1
      if (condition1 || condition2) {
        condition1 && (i === 1 ? (next.x = pre.x) : (pre.x = next.x))
        condition2 && (i === 1 ? (next.y = pre.y) : (pre.y = next.y))
        ps.splice(i, 1)
        flag = true
        i--
      }
    }
    return flag
  }
  addTailPoint(line, isFirst) { // 由于拖动头尾的线条时会添加点，使得头尾部不会同步移动距离，所以需要在头尾部添加额外的点
    let x, y, x2, y2
    let gap = 25
    const { start, end } = this.points
    const isUp = parseInt(line.start.y) < parseInt(line.end.y)
    const isRight = parseInt(line.start.x) < parseInt(line.end.x)

    if (this.points.length < 3) {
      if (line.isVertical) {
        x = start.x
        y = start.y + (isUp ? gap : -gap)
        x2 = end.x
        y2 = end.y + (isUp ? -gap : gap)
      } else {
        x = start.x + (isRight ? gap : -gap)
        y = start.y
        x2 = end.x + (isRight ? -gap : gap)
        y2 = end.y
      }
      const p1 = new Point(x, y)
      const p2 = new Point(x, y)
      const p3 = new Point(x2, y2)
      const p4 = new Point(x2, y2)
      this.points.splice(1, 0, p1, p2, p3, p4)
      line.setStartPoint(p2).setEndPoint(p3)
    } else {
      const index = isFirst ? 1 : this.points.length - 1
      const point = isFirst ? start : end
      gap = isFirst ? -25 : 25
      if (line.isVertical) {
        x = point.x
        y = point.y + (isUp ? -gap : gap)
      } else {
        x = point.x + (isRight ? -gap : gap)
        y = point.y
      }
      const p1 = new Point(x, y)
      const p2 = new Point(x, y)
      this.points.splice(index, 0, p1, p2)
      isFirst ? line.setStartPoint(p2) : line.setEndPoint(p1)
    }

    this.target.drawPoints = [...this.points]
  }

  setMinimumLength(line, move) { // 当移动靠近尾部的线条时，控制线条不能穿过节点
    const { lines, points } = this
    const { first, secondToLast, last } = this.lines
    const { start, second, nextToEnd, end } = points
    let axis; let dir; const len = this.lines.length
    if (len === 1) return

    const isSecond = line.start === lines.second.start && line.end === lines.second.end
    const isSecondToLast = line.start === secondToLast.start && line.end === secondToLast.end

    const secondAction = () => {
      axis = line.isVertical ? 'x' : 'y'
      dir = line.isVertical ? first.isRightAtInit : first.isDownAtInit
      if ((dir && second[axis] + move[axis] < start[axis] + 10) ||
        (!dir && second[axis] + move[axis] > start[axis] - 10)
      ) {
        move[axis] = 0
      }
    }
    const secondToLastAction = () => {
      axis = line.isVertical ? 'x' : 'y'
      dir = line.isVertical ? last.isRightAtInit : last.isDownAtInit
      if ((dir && nextToEnd[axis] + move[axis] > end[axis] - 10) ||
        (!dir && nextToEnd[axis] + move[axis] < end[axis] + 10)
      ) {
        move[axis] = 0
      }
    }
    if (len === 3) {
      if (line.start === lines.second.start && line.end === lines.second.end) {
        secondAction()
        secondToLastAction()
      }
    } else {
      if (isSecond) { // 根据移动的范围预判线条的长度
        secondAction()
      } else if (isSecondToLast) {
        secondToLastAction()
      }
    }
  }
  resetPointsCallback() {
    this.target.drawPoints = [...this.points]
    if (this.points.length < 3) {
      this.target.setIsUsedSplit(false)
      this.layout.getLength().initStore() // 重新计算总的线长
    }
  }
  eventdrag(move, line) {
    if (!line || !this.lines.length) return
    let x = 0; let y = 0; const t = this.target
    const { first, last } = this.lines
    const isFirstLine = (first.start === line.start)
    const isLastLine = (last.end === line.end)
    isFirstLine && line.setEndPoint(first.end)
    isLastLine && line.setStartPoint(last.start)

    this.setMinimumLength(line, move)
    line.isVertical ? (x = move.x) : (y = move.y)
    if (x === 0 && y === 0) return

    if (isFirstLine || isLastLine) {
      if (line.length < 50) return
      this.addTailPoint(line, isFirstLine)
      this.preLine = line
    }
    line.offset(x, y) // 移动当前线段
    t.setIsUsedSplit(true)

    if (this.preLine !== line && this.resetPoints(this.points)) {
      t.setTouchingTarget(null)
      this.resetPointsCallback()
    } else {
      this.setSplitSize()
    }
    this.isMovingLine = true
    t.setOperatingTarget(line) // 设置当前操作的对象
    this.setSplitTail() // 设置下次连接线移动的起始点
  }
  eventup() {
    if (this.isMovingLine) {
      this.isMovingLine = false
      this.preLine = null
      if (this.resetPoints(this.points)) {
        this.resetPointsCallback()
      }
    }
  }
  get splitPoints() {
    return this.target.splitPoints
  }
  get touchingTarget() {
    return this.target.touchingTarget
  }
  get points() {
    return this.target.points
  }
  get layout() {
    return this.target.layout
  }
  get ctx() {
    return this.target.ctx
  }
  get t() {
    return this.target
  }
  get board() {
    return this.target.board
  }
}

export default OperateLine
