/*
  功能： 画布设置水印
 */
import Text from './shapes/text'
import Rect from './shapes/rect'
import Utils from './utils'
class watermark {
  constructor(ctx, sx, sy, w, h) {
    this.ctx = ctx
    this.name = 'watermark'
    this.value = ''
    this.texts = []
    this.rect = new Rect(ctx, 0, 0, w, h)
    this.wc = document.createElement('canvas')
    this.wctx = this.wc.getContext('2d')
    this.width = 180
    this.height = 200
    this.style = new Style(this)
  }
  apply() {
    this.style.apply()
    this._apply()
  }
  _apply() {
    const { wc, ctx, width, height, rect, style, texts } = this
    const { sqrt, pow } = Math
    wc.width = sqrt(pow(width, 2) + pow(height, 2))
    wc.height = height
    this.applyText()
    style._apply()
    texts.forEach(it => it.fill())
    const pattern = ctx.createPattern(wc, 'repeat')
    rect.getStyle().setFillStyle(pattern)
  }
  applyText() {
    const { wctx, value, width } = this
    const { sin, tan, PI } = Math
    const { rotate, fontSize } = this.style
    this.texts.length = 0
    let w = 0
    let sy = parseInt(sin(rotate * PI / 180) * width, 10)
    const sx = -parseInt(sy / tan(((90 - rotate) * PI) / 180), 10)
    const lineHeight = fontSize * 1.4
    let lastSubStr = 0
    for (let i = 0; i < value.length; i++) {
      w += Utils.measureTextWidth(value[i])
      if (w > width - sx) { // 超出边界
        this.texts.push(new Text(wctx, -sx, -sy, value.substring(lastSubStr, i)))
        sy += lineHeight
        w = 0
        lastSubStr = i
      }
      if (i === value.length - 1) {
        this.texts.push(new Text(wctx, -sx, -sy, value.substring(lastSubStr, i + 1)))
      }
    }
  }
  draw() {
    this.rect.fill()
  }
  scaleRect(x, y, w, h) {
    this.rect.setStart(x, y)
    this.rect.setWidth(w).setHeight(h)
  }
  setWaterSize(width, height) {
    this.width = width
    this.height = height
  }
  setText(text) {
    this.value = text
    return this
  }
}
class Style {
  constructor(target) {
    this.target = target
  }
  apply(style = {}) {
    this.rotate = style.rotate || -30
    this.fontSize = style.fontSize || 16
    this.fontFamily = style.fontFamily || 'Open Sans'
    this.fontStyle = style.fontStyle || 'normal'
    this.fontVariant = style.fontVariant || 'normal'
    this.fontWeight = style.fontWeight || 'normal'
    this.color = style.color || '#000000'
    this.opacity = 0.1
  }
  _apply() {
    const { ctx, wctx, rect, wc, texts } = this.target
    const { font, rotate, color, opacity } = this
    wctx.fillStyle = 'rgb(25, 124, 124, 0.2)'
    wctx.rotate(-rotate * Math.PI / 180)
    wctx.font = font
    texts.forEach(t => {
      t.getStyle().setFont(font).setFillStyle(color).setOpacity(opacity)
    })
    const pattern = ctx.createPattern(wc, 'repeat')
    rect.getStyle().setFillStyle(pattern)
  }
  get font() {
    return `${this.fontStyle} ${this.fontVariant} ${this.fontWeight} ${this.fontSize}px ${this.fontFamily}`
  }
}
export default watermark
