/*
  用于流程图的锁定功能，
  包围节点，充当储物柜的功能
*/
import Rect from './rect'

class Locker extends Rect {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, width, height, ox, oy)
    this.arcRadius = 10
  }
  process() {
    const { _left, _top, width, height, ctx } = this
    ctx.beginPath()
    // 绘制带圆角的矩形
    ctx.setLineDash(this.sty.lineDash)
    ctx.lineDashOffset = -0

    ctx.moveTo(_left, _top)
    ctx.strokeRect(_left, _top, width, height)
    // 绘制4个叉号
    ctx.setLineDash([])
    // const radius = 5 // 叉号的半径
    // const ps = [{x: _left, y: _top}, {x: _right, y: _top}, {x: _right, y: _bottom}, {x: _left, y: _bottom}]
    // ps.forEach(p => {
    //   ctx.moveTo(p.x - radius, p.y - radius)
    //   ctx.lineTo(p.x + radius, p.y + radius)
    //   ctx.moveTo(p.x - radius, p.y + radius)
    //   ctx.lineTo(p.x + radius, p.y - radius)
    // })
  }
}

export default Locker
