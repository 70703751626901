import CompoundLine from './compoundLine'
import mathHelper from '../../../utils/mathHelper'

// 复合直线
class CompoundLines {
  constructor(ctx, board, style, data) {
    this.ctx = ctx
    this.board = board
    this.style = style

    this.lines = this.initGroupedEndPoints(data)
    this.touchingIndex = null
    this.lastSplitIndex = null
  }

  createLine(config) {
    const line = new CompoundLine(this.ctx, this.board, this.style, config)
    const { start, end } = config
    line.updateStraightLine(start, end)

    return line
  }

  getGroupedPoints(data) {
    const groups = []
    const { start, end, points = [] } = data
    const cps = [start, ...points, end]
    for (let i = 0; i < cps.length - 1; i++) {
      const start = cps[i]
      const end = cps[i + 1]
      groups.push({ start, end })
    }

    return groups
  }

  clean() {
    this.touchingIndex = null
    this.lastSplitIndex = null
  }

  // 初始化直线
  initGroupedEndPoints(data) {
    const groups = this.getGroupedPoints(data)
    const lines = []
    for (let i = 0; i < groups.length; i++) {
      const points = groups[i]
      const line = new CompoundLine(this.ctx, this.board, this.style, points)
      const { start, end } = points
      line.updateStraightLine(start, end)
      lines.push(line)
    }

    return lines
  }

  // 拖动中间节点
  dragDragPoint(move) {
    const arrow = {}
    const id = this.touchingIndex

    if (typeof id !== 'number' || id < 1) {
      return arrow
    }

    const firstId = id - 1
    const secondId = id
    const line1 = this.lines[firstId]
    const line2 = this.lines[secondId]

    const { start, end: midP } = line1.getLineTwoEndPoints()
    const { end } = line2.getLineTwoEndPoints()
    const movedMidP = mathHelper.getPointPatchedPos(midP, move)

    line1.updateStraightLine(start, movedMidP)
    line2.updateStraightLine(movedMidP, end)

    // start箭头需要变化, 不好用暂时不用
    if (firstId === 0) {
      arrow.start = mathHelper.getSlopeByTwoPoint(start, movedMidP)
    }

    // end箭头需要变化
    if (secondId === this.lines.length - 1) {
      arrow.end = mathHelper.getSlopeByTwoPoint(end, movedMidP)
    }

    this.style.resetControlStyle()
    return arrow
  }

  splitLineByIndex(index) {
    const oldLine = this.lines[index]
    const { start, end } = oldLine.getLineTwoEndPoints()
    const center = oldLine.getLineCenterPoint()

    const line1 = this.createLine({ start, end: center })
    const line2 = this.createLine({ start: center, end })

    this.lines.splice(index, 1, line1, line2)
    this.style.resetControlStyle()
  }

  splitLine() {
    const id = this.touchingIndex
    if (this.lastSplitIndex !== id) {
      // 之前是防止多次点击，后面看是否可以去掉
      this.lastSplitIndex = id
      this.splitLineByIndex(id)
      this.touchingIndex = id + 1
    }
  }

  mergeTwoLine2One(line1, line2, index) {
    const { start } = line1.getLineTwoEndPoints() // cp1 已经缩短一半
    const { end } = line2.getLineTwoEndPoints()

    const line = this.createLine({ start, end })
    this.lines.splice(index, 2, line)
  }

  checkIsNeedMerge(id) {
    if (id < 1) return false
    const line1 = this.lines[id - 1]
    const line2 = this.lines[id]

    if (!line1 || !line2) return false

    const { start, end: mid } = line1.getLineTwoEndPoints()
    const { end } = line2.getLineTwoEndPoints()

    const dis = mathHelper.getPointToLineDis(start, end, mid)
    return { isMerge: dis < 0.0001, line1, line2 }
  }

  mergeLine(isDragDrag, isDragSplit) {
    if (isDragDrag) {
      const index = this.touchingIndex
      const { isMerge, line1, line2 } = this.checkIsNeedMerge(index)
      if (isMerge) {
        this.mergeTwoLine2One(line1, line2, index - 1)
        this.style.applyChildStyle()
      }
      return
    }

    if (isDragSplit) {
      const index = this.lastSplitIndex + 1
      const { isMerge, line1, line2 } = this.checkIsNeedMerge(index)

      if (!isMerge) return

      if (isMerge) {
        this.mergeTwoLine2One(line1, line2, index - 1)
        this.lastSplitIndex = null
        this.touchingIndex = this.touchingIndex - 1 // 恢复拆分时的特殊处理
        this.style.applyChildStyle()
      }
    }
  }

  // 动态调整控制点大小
  setLineHeadSize(size) {
    this.lines.forEach((line) => {
      line.updateLineHeadSize(size)
    })
  }

  getCurDragPointPos() {
    const line = this.lines[this.touchingIndex]
    if (!line) return
    return line.getLineTwoEndPoints().start
  }

  getCurDragPointTwoEndPoints() {
    if (this.touchingIndex < 1) return
    const preLine = this.lines[this.touchingIndex - 1]
    const { start } = preLine.getLineTwoEndPoints()
    const line = this.lines[this.touchingIndex]
    const { end } = line.getLineTwoEndPoints()
    return { start, end }
  }

  getAllLines() {
    return this.lines
  }

  // 获取前第一个线段
  getFirstLine() {
    return this.lines[0]
  }

  // 获取前最后一个线段
  getLastLine() {
    return this.lines[this.lines.length - 1]
  }

  // 获取中心点
  getCenterPoint() {
    const lines = this.lines
    const tmp = lines.length % 2

    if (tmp) {
      // 奇数
      const index = Math.floor(lines.length / 2)
      const line = lines[index]
      return line.getLineCenterPoint()
    } else {
      const index = lines.length / 2 - 1
      const line = lines[index]
      return line.getLineTwoEndPoints().end
    }
  }

  // 修改第一个和最后一个节点
  apply(start, end, options) {
    const firstLine = this.getFirstLine()
    const { end: oldEnd } = firstLine.getLineTwoEndPoints()
    firstLine.updateStraightLine(start, oldEnd, { startArrowPoint: options.startArrowPoint })

    const lastLine = this.getLastLine()
    const { start: oldStart } = lastLine.getLineTwoEndPoints()
    lastLine.updateStraightLine(oldStart, end, { endArrowPoint: options.endArrowPoint })
  }

  draw(isShowBackGround) {
    this.lines.forEach((line, index) => {
      line.draw(isShowBackGround, index !== 0)
    })
  }

  isEnterBackground(x, y) {
    let isEnter = false

    for (const l of this.lines) {
      if (l.isEnterBackground(x, y)) {
        isEnter = true
        break
      }
    }

    return isEnter
  }

  isTouchingSplitPoint(point, gap) {
    let target
    for (let i = 0; i < this.lines.length; i++) {
      target = this.lines[i].isTouchingSplitPoint(point, gap)
      if (target) {
        this.touchingIndex = i
        return target
      }
    }

    return target
  }

  isTouchingDragPoint(point, gap) {
    let target
    // add for merge
    for (let i = 1, len = this.lines.length; i < len; i++) {
      target = this.lines[i].isTouchingOnDragPoint(point, gap)
      if (target) {
        this.touchingIndex = i
        return target
      }
    }

    return target
  }
}

export default CompoundLines
