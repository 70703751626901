class Style {
  constructor(target) {
    this.target = target
  }
  apply(style = {}) {
    this.backgroundColor = style.backgroundColor || '#fff'
    this.fontColor = style.fontColor || '#1A1A1A'
    this.fontSize = style.fontSize || 18
    this.fontFamily = style.fontFamily || 'Source Han Sans CN'
    this.fontStyle = style.fontStyle || 'normal'
    this.fontVariant = 'normal'
    this.fontWeight = style.fontWeight || 'normal'
    this.underline = style.underline || 'none'
    this.deleteline = style.deleteline || 'none'
    this.lineWidth = style.lineWidth || 2

    this.paddingLeft = style.paddingLeft || 20
    this.paddingTop = style.paddingTop || 10
    // this.contentLeft = style.contentLeft || 0 // 内容x开始位置
    this.contentTop = style.contentTop || 0 // 内容y开始位置

    this.lineColor = style.lineColor || '#000'
    this.borderOpacity = style.borderOpacity !== void 0 ? style.borderOpacity : 1
    this.lineDash = style.lineDash || []
    this.textOpacity = style.textOpacity || 1
    this.backgroundOpacity = style.backgroundOpacity || 1
    this.textAlign = style.textAlign || 'middle' // 整体内容的排列方式 left  middel right
    this.textAngle = style.textAngle || 0
    this.boundaryLineWidth = style.boundaryLineWidth || 8 // 当元素被框选时boundary作为元素的边界线展示
    this.boundaryColor = style.boundaryColor || 'rgba(160, 194, 253, 0.8)' // 当元素被框选时boundary作为元素的边界线展示
    this.configSpecialType() // 配置特殊类型节点的样式
    this.setLineHeight()
    this.setMaxWidth()
    // 内容编辑的时候
    this._apply()
    this.setStyleMap()
  }

  configSpecialType() {
    const t = this.target
    if (t.carrier) {
      this.paddingLeft = this.paddingTop = 3
      this.backgroundOpacity = 0
    } else if (t.isImageType) {
      this.paddingLeft = this.paddingTop = 0
    } else if (t.isTextType) {
      this.paddingLeft = this.paddingTop = 10
      this.backgroundOpacity = 0
      this.borderOpacity = 0
    }
  }
  setStyleMap() { // 特殊样式处理，通常为关联需求
    this.styleMap = {
      'fontSize': (v) => {
        this.fontSize = v
        this.setLineHeight()
      },
      'backgroundColor': (v) => {
        if (v === 'transparent') {
          this.backgroundOpacity = 0
        } else {
          this.backgroundOpacity = 1
          this.backgroundColor = v
        }
      },
      'lineColor': (v) => {
        if (v === 'transparent') {
          this.borderOpacity = 0
        } else {
          this.borderOpacity = 1
          this.lineColor = v
        }
      }
    }
  }
  changeStyle(config) {
    for (const [key, value] of Object.entries(config)) {
      if (this[key] !== void 0) {
        this.styleMap[key] ? this.styleMap[key](value) : (this[key] = value)
      }
    }
    return this
  }

  setLineHeight() {
    this.lineHeight = Math.floor(this.fontSize * 4 / 3)
  }

  setTextStyle(text) {
    return text.getStyle().setFont(this.font).setFillStyle(this.fontColor).setOpacity(this.textOpacity).setTextAlign('left').setTextBaseline('top')
  }

  setMaxWidth() { // 根据需求，不同类型的node最大宽度不一致
    const node = this.target
    this.maxWidth = Math.max(600, node.width) // 默认值
    if (node.isCopyTextToCreate) {
      this.maxWidth = node.board.grid.baseSize * 60
    }
  }

  get font() {
    return `${this.fontStyle} ${this.fontVariant} ${this.fontWeight} ${this.fontSize}px \"${this.fontFamily}\"`
  }

  _apply() {
    const { border, background, boundary, texts, junction, funcLock } = this.target
    background.getStyle()
      .setLineDash(this.lineDash)
      .setLineWidth(this.lineWidth || 1)
      .setStrokeStyle(this.lineWidth > 0 ? this.lineColor : 'transparent')
      .setFillStyle(this.backgroundColor)
      .setStrokeOpacity(this.borderOpacity)
      .setOpacity(this.backgroundOpacity)
    // 边界线目前只在框选的时候才会出现，还未发现自定义样式，后续需求变动，可添加样式配置
    boundary.getStyle().setLineWidth(this.boundaryLineWidth).setStrokeStyle(this.boundaryColor)
    junction.style.apply()
    border.style.apply()
    funcLock.style.apply()
    texts.forEach(t => {
      this.setTextStyle(t)
    })
    this.target._apply() // 调用此方法 针对修改text样式后，需要重新计算文本的大小和高度
  }
}

export default Style
