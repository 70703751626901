import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import useOutsideClick from './useOutsideClick'

const MoreColorList = [
  '#000000', '#F2F2FF', '#FBF0FF', '#FFF0FB', '#FFF0F0', '#FFF3D9', '#FCFCCA', '#E3FAE3', '#EDF5FF',
  '#F2F3F5', '#DEDEFF', '#F4D9FF', '#FFD6F5', '#FFD9D9', '#FFDDA6', '#FFF7A1', '#C3F7C8', '#CFE4FF',
  '#A9AFB8', '#A7A6FF', '#E08FFF', '#FF80DF', '#FF8F8F', '#FC9432', '#FFE342', '#54C45E', '#6DB1FF',
  '#6F7681', '#635DFF', '#BA23F6', '#D916A8', '#E81313', '#CC4E00', '#FCCE14', '#008A0E', '#1071E5',
  '#3A414A', '#21265C', '#4D0066', '#7D005D', '#860303', '#702B00', '#F2BA00', '#024D02', '#003D87',

  '#333333', '#FF8839', '#C92D39', '#FAFAFA', '#FF9E56', '#FFB584', '#F36100', '#FFEADB', '#FFF6F0',
  '#7AB648', '#FE5900', '#282C33', '#4C535D', '#806858', '#5D5C80'

]

export const ModalCssWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 35px;
  z-index: 1;
  min-width: 100px;
  box-shadow: 0px 0px 5px #9f8d8d;
  padding: 10px;
  background-color: white;

  .modalContent {
    padding: 15px 10px 15px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .modalItem {
    width: 24px;
    height: 24px;
    margin: 5px;
    cursor: pointer;
    border-radius: 20px;
  }
`

const ColorItem = (props) => {
  const { color, onColorChange } = props

  return (
    <div
      className='modalItem'
      style={{
        backgroundColor: color
      }}
      onClick={(e) => {
        onColorChange(color)
        e.stopPropagation()
      }}
    />
  )
}

ColorItem.propTypes = {
  color: PropTypes.string,
  onColorChange: PropTypes.func
}

const withMoreColorPick = (CustomBtn) => {
  const ColorCmp = (props) => {
    const { onColorChange, disabled, colors, width = 295, ...rest } = props
    const [isShow, setIsShow] = useState(false)
    const handleChangeColor = (color) => {
      onColorChange && onColorChange(color)
      setIsShow(false)
    }

    const handleClick = () => {
      !disabled && setIsShow(!isShow)
    }

    const handleOutClick = useCallback(() => {
      setIsShow(false)
    }, [])

    const wrapperRef = useRef(null)
    useOutsideClick(wrapperRef, handleOutClick)

    const selectColors = Array.isArray(colors) ? colors : MoreColorList

    return (
      <div ref={wrapperRef} style={{
        position: 'relative',
        height: '24px'
      }}>
        <CustomBtn onClick={handleClick} {...rest} />
        {isShow && (
          <ModalCssWrapper>
            <div className='modalContent' style={{ width }}>
              {selectColors.map((c, index) => (
                <ColorItem
                  key={index}
                  color={c}
                  onColorChange={handleChangeColor}
                />
              ))}
            </div>
          </ModalCssWrapper>
        )}
      </div>
    )
  }

  ColorCmp.propTypes = {
    width: PropTypes.number,
    name: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    onColorChange: PropTypes.func,
    colors: PropTypes.array
  }

  return ColorCmp
}

export default withMoreColorPick
