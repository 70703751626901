import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import IconButton from 'components/comm/IconButton'
import Expand from 'components/comm/Expand'
import styled from 'styled-components'
import shapes from './shapes.json'
import { useBoardContext } from 'views/board/hooks'
import closePng from 'assets/img/icon/close.png'
import Drag from 'components/drag'

export const ListCssWrapper = styled.div`
  .title{
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #1C1C1C;
  }

  .title .text{
    padding-left:20px;
  }

  .close{
    float: right;
    margin-right: 20px;
    position: relative;
    bottom: 3px;
    cursor: pointer;
    img{
      width:10px;
      height:10px;
    }
  }

  .borderline{
    width: 240px;
    height: 1px;
    background: #D9D9D9;
    margin-top:17px;
  }
  .row {
    margin-bottom: 5px;
  }

  .listHead {
    padding: 5px;
  }
  
  .listItem {
    width: 31px;
    display: flex;
    justify-content: center;
    margin:5px 0;
    border-radius: 50%;
    height: 31px;
    margin-right: 5px;
  }
  .listItem:hover{
    background:#ccc;
  }

  .iconSize {
    width: 27px;
    height: 27px;
  }
`

const TotalList = [
  // { text: '标准', data: shapes.StandList, isShowClose: false },
  { text: '流程图', data: shapes.FlowList, isShowClose: false },
  { text: '基础形状', data: shapes.ShapeList, isShowClose: false }
]

function list(props) {
  const { board } = useBoardContext()
  const { closeExpandTool, expandPanelEl } = props
  useEffect(_ => {
    if (board) {
      expandPanelEl && (expandPanelEl.close = closeExpandTool)
      Drag.getInstance(expandPanelEl, 'dragItem', board)
    }
  }, [board])

  return (
    <ListCssWrapper>
      <div className='title'>
        <div className='text'>
          <span>形状</span>
          <span className='close' onClick={() => closeExpandTool()}>
            < img src={closePng} />
          </span>
        </div>
        <div className='borderline'></div>
      </div>
      {
        TotalList.map((e, index) => (
          <Expand key={index} title={e.text} showClose={e.isShowClose} hasOverFlow={false} isOpenShow={true}>
            <div key={index} className='flexWrap row'>
              {e.data.map((e1, index1) => (
                <div key={index1} className='listItem flexRow dragItem' dragid={e1.id}>
                  <IconButton
                    className={'iconSize'}
                    toolTip={e1.text}
                    name={e1.icon}
                    widthInPx={23}
                    direction={'left'}
                  />
                </div>
              ))}
            </div>
          </Expand>
        ))
      }
    </ListCssWrapper>
  )
}

list.propTypes = {
  closeExpandTool: PropTypes.func,
  expandPanelEl: PropTypes.object
}

export default list

