import React, { useCallback, useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { RightToolbox } from './style'
import IconButton from 'components/comm/IconButton'
import { connect } from 'react-redux'
import { ToolsText } from './toolsText'
import { ToolsFrame } from './toolsFrame'
import { ToolsLink } from './toolsLink'
import {
  changeNodeStyle
} from 'store/actions'
import { useBoardContext } from 'views/board/hooks'
import { getArrowIconName, getArrowType, getArrowTypes } from 'utils/arrow'

function RightPanels(props) {
  const {
    edition_seven_seven,
    selectObject,
    onChangeNodeStyle
    // onChangeNodeLock
  } = props
  const { activeNode } = selectObject
  const { board } = useBoardContext()
  const activeNodeStyle = activeNode.style
  // const lockStatus = activeNode.funcLock.lockType
  const { fontColor, backgroundColor, fontSize, fontFamily, fontWeight, fontStyle, underline } = activeNodeStyle
  const lineDash = activeNodeStyle.lineDash
  const lineColor = activeNodeStyle.lineColor
  const lineWidth = activeNodeStyle.lineWidth
  const handleChangeStyle = useCallback((v) => {
    onChangeNodeStyle && onChangeNodeStyle(v)
    board && board.api.changeNodeStyle(v)
  }, [onChangeNodeStyle, board])

  const handLinkChange = useCallback((link) => {
    board && board.api.changeNodeLink(link)
  }, [board])

  const { startArrowType, endArrowType } = activeNode.style

  const arrowGroup = useMemo(() => {
    return {
      start: getArrowIconName(startArrowType),
      end: getArrowIconName(endArrowType)
    }
  }, [startArrowType, endArrowType])

  const handleArrowChange = useCallback((newVal, isStart) => {
    const newType = getArrowType(newVal)
    board.api.changeLinkArrow(newType, isStart)
  }, [board])

  const [list, setList] = useState([
    {
      name: '样式',
      type: 'style',
      css: 'boxYS',
      checked: true
    },
    {
      name: '文本',
      type: 'text',
      css: 'boxWB',
      checked: false
    }
  ])
  const [rightName, setRightName] = useState('style')

  const onChangeBox = (value, index) => {
    const newArr = list.map((val, idx) => {
      if (idx === index) {
        val.checked = true
      } else {
        val.checked = false
      }
      return val
    })
    setList(newArr)
    setRightName(newArr[index].type)
  }
  const [isShow, setIsShow] = useState(false)
  const onClose = () => {
    setIsShow(false)
  }
  useEffect(() => {
    if (activeNode.name && activeNode.name !== 'note') {
      setIsShow(true)
    } else {
      setIsShow(false)
    }
  }, [activeNode.name])
  const [listChecked, setListChecked] = useState([true, true, true, true, true, true])
  const onChangeChecked = (index) => {
    const newList = listChecked.map((val, idx) => {
      if (idx === index) {
        val = !val
      }
      return val
    })
    setListChecked(newList)
  }

  const handleMove = useCallback((event) => {
    event.stopPropagation()
    event.preventDefault()
  }, [])

  return (
    <RightToolbox onMouseMove={handleMove}>
      {
        isShow && <div className='boxDiv'>
          <div className='names'>
            <div className='text'>设置</div>
            <div className='close' onClick={() => onClose()}>
              <IconButton
                name={'closeRight'}
                showToolTip={false}
              />
            </div>
          </div>
          <div className='tools'>
            <div className='textTwo'>
              {
                list.map((item, index) => {
                  return (
                    <div className={item.css} key={index} onClick={() => onChangeBox(item.type, index)}>
                      <div className='text'>{item.name}</div>
                      <div className={item.checked ? 'true ' : 'activeDiv'}></div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className='toolsBox'>
            {activeNode.name && rightName === 'text' && <ToolsText
              fontColor={fontColor}
              fontSize={fontSize}
              fontFamily={fontFamily}
              fontWeight={fontWeight}
              fontStyle={fontStyle}
              underline={underline}
              handLinkChange={handLinkChange}
              listChecked0={listChecked[0]}
              listChecked1={listChecked[1]}
              selectObject={selectObject}
              handleChangeStyle={handleChangeStyle}
              onChangeChecked={onChangeChecked}
              edition_seven_seven={edition_seven_seven}
            />}
            {
              activeNode.name !== 'link' && activeNode.name === 'node' && rightName === 'style' && <ToolsFrame
                onChangeChecked={onChangeChecked}
                listChecked2={listChecked[2]}
                listChecked3={listChecked[3]}
                handleChangeStyle={handleChangeStyle}
                backgroundColor={backgroundColor}
                lineColor={lineColor}
                lineDash={lineDash}
                lineWidth={lineWidth}
                noFill={true}
                edition_seven_seven={edition_seven_seven}
              />
            }
            {
              activeNode.name === 'link' && activeNode.name !== 'node' && rightName === 'style' && <ToolsLink
                onChangeChecked={onChangeChecked}
                listChecked4={listChecked[4]}
                listChecked5={listChecked[5]}
                handleChangeStyle={handleChangeStyle}
                handleArrowChange={handleArrowChange}
                lineColor={lineColor}
                lineDash={lineDash}
                lineWidth={lineWidth}
                arrowGroup={arrowGroup}
                ArrowTypes={getArrowTypes()}
                edition_seven_seven={edition_seven_seven}
              />
            }
          </div>
        </div>
      }
    </RightToolbox>
  )
}

RightPanels.propTypes = {
  selectObject: PropTypes.object,
  onChangeNodeStyle: PropTypes.func,
  isMouseStr: PropTypes.string,
  onChangeNodeLink: PropTypes.func,
  edition_seven_seven: PropTypes.bool
  //   onChangeNodeLock: PropTypes.func,
}
const mapStateToProps = state => {
  return {
    selectObject: state.selectObject
  }
}

const mapDispatchToProps = dispatch => ({
  onChangeNodeStyle: v => dispatch(changeNodeStyle(v))
})

export default connect(mapStateToProps, mapDispatchToProps)(RightPanels)
