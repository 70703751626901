/* eslint-disable no-throw-literal */
import { ARROWTYPE } from './constant'
class Style {
  constructor(target) {
    this.target = target
  }
  apply(style = {}) {
    this.backgroundColor = style.backgroundColor || 'rgba(160, 194, 253, 0.8)'
    this.color = style.color || '#FF7722'
    this.lineColor = style.lineColor || '#35455b'
    this.lineWidth = style.lineWidth || 2
    this.startArrowColor = style.startArrowColor || '#fff'
    this.arrowLineWidth = style.arrowLineWidth || 0.1
    this.fontSize = style.fontSize || 18
    this.fontColor = style.fontColor || '#1A1A1A'
    this.headColor = style.headColor || '#FFF'
    this.touchHeadColor = style.touchHeadColor || '#FF7722'
    this.headWidth = style.headWidth || 8
    this.lineDash = style.lineDash || []
    this.arrowLength = style.arrowLength || 15
    this.backgroundLineWidth = style.backgroundLineWidth || 6
    this.textStyle = {
      'paddingLeft': 2, 'paddingTop': 2, 'backgroundOpacity': 1, 'borderOpacity': 0, 'fontSize': 18
    }
    this.startArrowType = style.startArrowType || ARROWTYPE.NONE // 开始点箭头的类型
    this.endArrowType = style.endArrowType || ARROWTYPE.TRIANGLE // 结束点箭头的类型
    this._apply()
  }
  changeStyle(config) {
    for (const [key, value] of Object.entries(config)) {
      // TODO 右侧工具的产品设计有缺陷（后续产品需要修改），同时框选形状和线条，显示的是节点的设置界面，设置填充色会修改线条，暂时设置线条不能设置backgroundColor
      key !== 'backgroundColor' && this[key] !== void 0 && (this[key] = value)
    }
    return this
  }
  setHeadTouchStyle(h) { // h: 连接线两头的控制点
    h.getStyle().setFillStyle(this.touchHeadColor)
  }
  resetHeadStyle() {
    const lineW = this.target.board.getDrawLength(1)
    ;[this.target.startHead, this.target.endHead].forEach(h => h.getStyle().setLineWidth(lineW).setStrokeStyle(this.lineColor).setFillStyle(this.headColor))
  }
  applyChildStyle() {
    throw 'Subclasses must implement the method: applyChildStyle!'
  }
  _apply() {
    const { endArrow, startArrow, texts, funcLock } = this.target
    const { fontSize, lineColor, fontColor } = this
    texts.forEach(t => t.style.changeStyle({ fontSize, fontColor })._apply())
    this.applyChildStyle()
    endArrow.getStyle().setLineWidth(this.arrowLineWidth).setFillStyle(lineColor).setStrokeStyle(lineColor)
    startArrow.getStyle().setLineWidth(this.arrowLineWidth).setFillStyle(lineColor).setStrokeStyle(lineColor)
    funcLock.style.apply()
    this.resetHeadStyle()
  }
}

export default Style
