/* eslint-disable prefer-const */
import Compute from './compute'

export default class LinkLayout {
  constructor(target) {
    this.target = target
    this.middles = [] // 中间点集合
    this.preAddPointsInStart = [] // 上一次在连接线开始点添加的节点
    this.preAddPointsInEnd = [] // 上一次在连接线结束点添加的节点
  }
  initStore() {
    this.preAddPointsInStart.length = 0
    this.preAddPointsInEnd.length = 0
    this.middles.length = 0
  }
  judgeDirIsVertical(start, node) { // 判断线段的连接方向是否是上下结构 true 上下 false 左右
    if (!node) return false
    const { x, right, bottom, y } = node
    let gap = Math.abs(start.x - x)
    const isAroundLeft = gap >= 8 && gap <= 20
    gap = Math.abs(start.x - right)
    const isAroundRight = gap >= 8 && gap <= 20
    gap = Math.abs(start.y - bottom)
    const isAroundBottom = gap >= 0 && gap <= 5
    gap = Math.abs(start.y - y)
    const isAroundTop = gap >= 0 && gap <= 5
    return isAroundLeft || isAroundRight || isAroundBottom || isAroundTop
  }
  compute() {
    const t = this.target
    const { isUsedSplit } = t

    let ps = []
    if (isUsedSplit) {
      ps = this.useSplitedLayout()
      // 有可能会重置掉缓存点，当缓存点重置掉，移动界面，下一次再使用缓存点，导致被重构掉的位置异常
      t.operateLine.resetPoints(ps)
    } else {
      ps = this.normalLayout()
    }
    return ps
  }

  useSplitedLayout() { // 拖动过连接线分割点的计算规则
    const t = this.target
    const { splitedStart, splitedEnd, isOperatingStartHead, isOperatingEndHead } = t
    let first, last, start, end, isVerticalEnd, line

    if (isOperatingStartHead) { // 如果正在移动连接线开始点
      line = splitedStart.line
      start = splitedStart.point
      end = t.start
      last = this.target.first
      isVerticalEnd = this.judgeDirIsVertical(end, last) // 结束点所在的线段是否是垂线
    } else if (isOperatingEndHead) { // 如果正在移动连接线结束点
      line = splitedEnd.line
      start = splitedEnd.point
      end = t.end
      last = this.target.last
      isVerticalEnd = this.judgeDirIsVertical(end, last)
    }

    const { kx, ky, jx, jy, isVertical } = line
    if (isVertical) {
      first = { x: kx - 20, y: Math.min(ky, jy), bottom: Math.max(ky, jy), right: kx + 20, cx: kx, cy: (ky + jy) / 2 }
    } else {
      first = { x: Math.min(kx, jx), y: ky - 20, bottom: ky + 20, right: Math.max(kx, jx), cx: (kx + jx) / 2, cy: ky } // 模拟出的开始节点（连接线连接的）
    }

    this.middles = new Compute({ first, last, start, end, isVerticalStart: isVertical, isVerticalEnd }).points
    const ps = [start, ...this.middles, end] // 本次计算得出的所有点
    let ms

    if (isOperatingStartHead) {
      this.preAddPointsInStart = ps.reverse()
      ms = this.preAddPointsInEnd.length ? splitedStart.after.slice(0, -2) : splitedStart.after
      return [...this.preAddPointsInStart, ...ms, ...this.preAddPointsInEnd]
    } else {
      this.preAddPointsInEnd = ps
      ms = this.preAddPointsInStart.length ? splitedEnd.before.slice(2) : splitedEnd.before
      return [...this.preAddPointsInStart, ...ms, ...this.preAddPointsInEnd]
    }
  }
  normalLayout() { // 正常连接线的计算规则
    const t = this.target
    if (t.start.distance(t.end) < 2) { // 当开始点和结束点距离非常近，不再执行智能判断逻辑
      return [t.start, t.end]
    }

    let first, last, start, end, isVerticalStart, isVerticalEnd, isForward

    first = t.first || t.last
    isForward = first === t.first

    if (isForward) {
      last = t.last
      start = t.start
      end = t.end
    } else {
      last = t.first
      start = t.end
      end = t.start
    }

    isVerticalStart = this.judgeDirIsVertical(start, first)
    isVerticalEnd = this.judgeDirIsVertical(end, last)
    this.middles = new Compute({ first, last, start, end, isVerticalStart, isVerticalEnd }).points
    return isForward ? [start, ...this.middles, end] : [end, ...this.middles.reverse(), start]
  }
  getLength() { // 获取所有线段的长度总和
    let sum = 0; let len
    const { lines } = this.target
    for (const line of lines) {
      len = line.length
      sum = sum + len
    }
    this.length = sum
    return this
  }
  setTextRate(n) { // 获取文字长度占比 此方法只在添加文字时执行一次
    // eslint-disable-next-line no-constant-condition
    if (!1) { // 暂时不执行此逻辑，连接线文字需求变更，只允许添加一个文字, 并且在中段
      const { lines } = this.target
      let sum = 0; let condition1; let condition2
      for (const line of lines) {
        condition1 = line.isVertical && n.y > Math.min(line.start.y, line.end.y) && n.y < Math.max(line.start.y, line.end.y) && Math.abs(n.x - line.start.x) <= n.width
        condition2 = !line.isVertical && n.x > Math.min(line.start.x, line.end.x) && n.x < Math.max(line.start.x, line.end.x) && Math.abs(n.y - line.start.y) <= n.height
        if (condition1 || condition2) { // text 位于当前线条上
          sum += line.start.distance(n)
          break
        } else {
          sum += line.length
        }
      }
      const rate = sum / this.length
      n.rateAtLink = rate === 1 ? 0.5 : rate
    }
    n.rateAtLink = 0.5
    return n
  }
  adjustText(t) { // t: text 对象
    t.layoutX = t.layoutX - t.width / 2
    t.layoutY = t.layoutY - t.height / 2
    t.apply()
  }
  layoutTexts() { // 计算文字节点位置
    const { target, length } = this
    let sum, tl, gap
    target.texts.forEach((text) => {
      sum = 0
      tl = text.rateAtLink * length
      for (const line of target.lines) {
        sum += line.length
        if (tl < sum) {
          if (line.isVertical) {
            gap = line.start.y > line.end.y ? sum - tl : tl - sum
            text.setLayout(line.end.x, line.end.y + gap)
          } else {
            gap = line.start.x > line.end.x ? sum - tl : tl - sum
            text.setLayout(line.end.x + gap, line.end.y)
          }
          break
        }
      }
      this.adjustText(text)
      return this
    })
  }
  get isUsedSplit() {
    return this.target.isUsedSplit
  }
  get ctx() {
    return this.target.ctx
  }
}
