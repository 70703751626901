/* eslint-disable no-sequences */
import Utils from './utils'
import Rect from './shapes/rect'

class ImageData {
  constructor(ctx, sx, sy, sw_or_imageData, sh_or_sw, dx, dy, dw, dh) {
    (this.ctx = ctx), this.setDirtyX(dx).setDirtyY(dy).setDirtyWidth(dw).setDirtyHeight(dh).setImageData(sx, sy, sw_or_imageData, sh_or_sw)
  }

  getImageData() {
    return this._imageData
  }

  _put() {
    return (
      this.put === ImageData.prototype._put0
        ? ((Utils._canvas.width = this.width), (Utils._canvas.height = this.height), Utils._ctx.putImageData(this._imageData, 0, 0))
        : ((Utils._canvas.width = this.dw),
        (Utils._canvas.height = this.dh),
        Utils._ctx.putImageData(this._imageData, 0, 0, this.dx, this.dy, this.dw, this.dh)),
      this
    )
  }

  toDataURL(type, quality) {
    return this._put(), Utils._canvas.toDataURL(type, quality)
  }

  setImageData(sx, sy, sw_or_imageData, sh_or_sw) {
    return (
      sw_or_imageData &&
        (isNaN(sw_or_imageData)
          ? ((this._imageData = new ImageData(this.ctx, sw_or_imageData.data, (this.width = sh_or_sw || sw_or_imageData.width))),
          (this.height = this._imageData.height),
          (this.data = this._imageData.data),
          (isNaN(sx) || isNaN(sy)) && (sx = sy = 0),
          this.setStart(sx, sy).resetPut())
          : isNaN(sh_or_sw) ||
            ((this.width = sw_or_imageData),
            (this.height = sh_or_sw),
            isNaN(sx) || isNaN(sy)
              ? ((this._imageData = this.ctx.createImageData(sw_or_imageData, sh_or_sw)), (sx = sy = 0))
              : (this._imageData = this.ctx.getImageData(sx, sy, sw_or_imageData, sh_or_sw)),
            (this.data = this._imageData.data),
            this.setStart(sx, sy).resetPut())),
      this
    )
  }

  toBlob(callback, type, quality) {
    return this._put(), Utils._canvas.toBlob(callback, type, quality), this
  }

  saveAsImage(filename, type, quality) {
    return (Utils._a.href = this.toDataURL(type, quality)), (Utils._a.download = filename), Utils._a.click(), this
  }

  resetPut() {
    return (
      isNaN(this.dx) || isNaN(this.dy) || isNaN(this.dw) || isNaN(this.dh)
        ? (this.put = ImageData.prototype._put0)
        : (this.put = ImageData.prototype._put4),
      this
    )
  }

  _put0() {
    return this.ctx.putImageData(this._imageData, this.sx, this.sy), this
  }

  _put4() {
    return this.ctx.putImageData(this._imageData, this.sx, this.sy, this.dx, this.dy, this.dw, this.dh), this
  }

  getData() {
    return this.data
  }

  isPointInPath(x, y) {
    return x >= this._left && y >= this._top && x <= this._right && y <= this._bottom
  }

  isPointInStroke(x, y) {
    return (
      ((0 === Math.round(x - this._left) || 0 === Math.round(x - this._right)) && y >= this._top && y <= this._bottom) ||
      ((0 === Math.round(y - this._top) || 0 === Math.round(y - this._bottom)) && x >= this._left && x <= this._right)
    )
  }

  _setStartX(sx) {
    return (this.sx = this._sx = sx), this._horizontal()
  }

  _setStartY(sy) {
    return (this.sy = this._sy = sy), this._vertical()
  }

  getDirtyX() {
    return this.dx
  }

  setDirtyX(dx) {
    return (this.dx = dx), this
  }

  getDirtyY() {
    return this.dy
  }

  setDirtyY(dy) {
    return (this.dy = dy), this
  }

  setDirty(dx, dy) {
    return this.setDirtyX(dx).setDirtyY(dy)
  }

  getDirtyWidth() {
    return this.dw
  }

  setDirtyWidth(dw) {
    return (this.dw = dw), this
  }

  getDirtyHeight() {
    return this.dh
  }

  setDirtyHeight(dh) {
    return (this.dh = dh), this
  }

  getPixelIndex(x, y) {
    return 4 * (x - this.sx + (y - this.sy) * this.width)
  }

  forEach(callback, context, x, y, w, h) {
    (x = isNaN(x) || x < this._left ? this._left : x),
    (y = isNaN(y) || y < this._top ? this._top : y),
    (w = isNaN(w) || x + w > this._right ? this._right - x : w),
    (h = isNaN(h) || y + h > this._bottom ? this._bottom - y : h)
    for (var j, data = this.data, index = this.getPixelIndex(x, y), offset = 4 * (this.width - w), i = y; i < y + h; i++) {
      for (j = x; j < x + w; j++) {
        callback.call(context, data, index, j, i), (index += 4)
      }
      index += offset
    }
    return this
  }

  mosaic(t, x, y, w, h) {
    if (1 === t) return this;
    (t = t || 3),
    (x = isNaN(x) || x < this._left ? this._left : x),
    (y = isNaN(y) || y < this._top ? this._top : y),
    (w = isNaN(w) || x + w > this._right ? this._right - x : w),
    (h = isNaN(h) || y + h > this._bottom ? this._bottom - y : h)
    for (
      var j,
        data = this.data,
        index = this.getPixelIndex(x, y),
        width = this.width,
        th = Math.floor(h / t),
        tw = Math.floor(w / t),
        xm = w % t,
        ym = h % t,
        i = 0;
      i < th;
      i++
    ) {
      for (j = 0; j < tw; j++) mosaicRect(index, t, t), (index += 4 * t)
      mosaicRect(index, xm, t), (index += 4 * (xm + (t - 1) * width))
    }
    for (j = 0; j < tw; j++) mosaicRect(index, t, ym), (index += 4 * t)

    function mosaicRect(idx, w, h) {
      for (
        var l,
          rand = idx + 4 * (Utils.randInt(w) + Utils.randInt(h) * width),
          r = data[rand],
          g = data[rand + 1],
          b = data[rand + 2],
          a = data[rand + 3],
          k = 0;
        k < h;
        k++
      ) {
        for (l = 0; l < w; l++) {
          (data[idx] = r), (data[idx + 1] = g), (data[idx + 2] = b), (data[idx + 3] = a), (idx += 4)
        }
        idx += 4 * (width - w)
      }
    }
    return (1 < xm || 1 < ym) && mosaicRect(index, xm, ym), this
  }

  transparencyDisposal(ratio, x, y, w, h) {
    return (
      (ratio = isNaN(ratio) ? 255 : 255 * ratio),
      this.forEach(
        function(data, index) {
          data[index] >= ratio && data[index + 1] >= ratio && data[index + 2] >= ratio && (data[index + 3] = 0)
        },
        this,
        x,
        y,
        w,
        h
      )
    )
  }

  reverseColor(x, y, w, h) {
    return this.forEach(
      function(data, index) {
        (data[index] = 255 - data[index]), (data[index + 1] = 255 - data[index + 1]), (data[index + 2] = 255 - data[index + 2])
      },
      this,
      x,
      y,
      w,
      h
    )
  }

  greyProcessing(x, y, w, h) {
    return this.forEach(
      function(data, index) {
        data[index] = data[index + 1] = data[index + 2] = 0.299 * data[index] + 0.587 * data[index + 1] + 0.114 * data[index + 2]
      },
      this,
      x,
      y,
      w,
      h
    )
  }

  blackWhite(ratio, x, y, w, h) {
    return (
      (ratio = isNaN(ratio) ? 127 : 254 * ratio),
      this.forEach(
        function(data, index) {
          data[index] = data[index + 1] = data[index + 2] = (data[index] + data[index + 1] + data[index + 2]) / 3 > ratio ? 255 : 0
        },
        this,
        x,
        y,
        w,
        h
      )
    )
  }

  relief() {
    for (var j, data = this.data, width = this.width, w = width - 1, h = this.height - 1, w4 = 4 * width, index = 0, i = 0; i < h; i++) {
      for (j = 0; j < w; j++) {
        (data[index] = 2 * data[index] - data[index + 4] - data[index + w4] + 127.5),
        (data[index + 1] = 2 * data[index + 1] - data[index + 5] - data[index + 1 + w4] + 127.5),
        (data[index + 2] = 2 * data[index + 2] - data[index + 6] - data[index + 2 + w4] + 127.5),
        (index += 4)
      }
      index += 4
    }
    for (index = 4 * w, i = 0; i < h; i++) {
      (data[index] = data[index - 4]), (data[index + 1] = data[index - 3]), (data[index + 2] = data[index - 2]), (index += w4)
    }
    for (index = h * w4, j = 0; j < width; j++) {
      (data[index] = data[index - w4]), (data[index + 1] = data[index + 1 - w4]), (data[index + 2] = data[index + 2 - w4]), (index += 4)
    }
    return this
  }

  sunGlass() {
    for (var j, data = this.data, width = this.width, w = width - 1, h = this.height - 1, w4 = 4 * width, index = 0, i = 0; i < h; i++) {
      for (j = 0; j < w; j++) {
        (data[index] = 2 * data[index] - 1.5 * data[index + 4]),
        (data[index + 1] = 2 * data[index + 1] - 1.5 * data[index + 5]),
        (data[index + 2] = 2 * data[index + 2] - 1.5 * data[index + 6]),
        (index += 4)
      }
      index += 4
    }
    for (index = 4 * w, i = 0; i < h; i++) {
      (data[index] = data[index - 4]), (data[index + 1] = data[index - 3]), (data[index + 2] = data[index - 2]), (index += w4)
    }
    for (index = h * w4, j = 0; j < width; j++) {
      (data[index] = data[index - w4]), (data[index + 1] = data[index + 1 - w4]), (data[index + 2] = data[index + 2 - w4]), (index += 4)
    }
    return this
  }

  frostedGlass(t) {
    return (
      (t = t || 8),
      this.forEach(function(data, index, x, y) {
        var randX = Utils.randInt(t)
        var rand = Utils.randInt(t)
        x + randX >= this._right ||
          y + rand >= this._bottom ||
          ((rand = index + 4 * (randX + rand * this.width)),
          (data[index] = data[rand]),
          (data[index + 1] = data[rand + 1]),
          (data[index + 2] = data[rand + 2]),
          (data[index + 3] = data[rand + 3]))
      }, this)
    )
  }
}

ImageData.prototype.put = Utils.noop
Utils._assign(
  ImageData,
  Rect,
  'getWidth',
  'getHeight',
  'getStartX',
  'getStartY',
  'setStart',
  '_horizontal',
  '_vertical',
  'getLeft',
  'getTop',
  'getRight',
  'getBottom'
)

export default ImageData
