/* eslint-disable no-proto */
import Shape from './index.js'
import Line from './line'
import Rect from './rect'
import TextStyle from './styles/textStyle'
import Utils from '../utils'

class Text extends Shape {
  constructor(ctx, sx, sy, text, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.line = new Line(ctx, 0, 0, 0, 0)
    this.angle = 0
    this.setText(text)
  }
  setUnderline(color) {
    this.setLine(color, true)
  }
  setDelLine(color) {
    this.setLine(color, false)
  }
  setLine(color, isUnderline) {
    // 如果是删除线 划线相对高度是下划线的一半
    const linePositionT = this.getTop()
    const linePositionB = this.getBottom()
    const h = isUnderline ? linePositionB : (linePositionT + (linePositionB - linePositionT) / 2)
    this.line.setStart(this.getLeft(), h).setEndX(this.getRight()).setEndY(h)
    this.line.getStyle().setLineWidth(1).setStrokeStyle(color)
    this.line.stroke()
  }

  setAngle(angle) { // 0 -> 360
    this.angle = angle
  }

  _initStyle() {
    this.setStyle(new TextStyle())
  }
  _fill(fillRule = {}) {
    const width = this.getRight() - this.getLeft()
    const height = this.getBottom() - this.getTop()
    const tx = this.sx - this.ox + width / 2
    const ty = this.sy - this.oy + height / 2

    this.ctx.save()
    this.ctx.translate(tx, ty)
    this.ctx.rotate((-this.angle * Math.PI) / 180) // this.ctx.rotate(10 * Math.PI / 180);
    this.ctx.translate(-tx, -ty)

    this.ctx.fillText(this.text, this._sx, this._sy)
    this.ctx.restore()

    const { underline, deleteline, color } = fillRule
    underline === 'underline' && this.setUnderline(color)
    deleteline === 'line-through' && this.setDelLine(color)
    return this
  }
  _stroke() {
    this.ctx.strokeText(this.text, this._sx, this._sy)
    return this
  }
  _clip() {
    const ctx = this.ctx
    return (
      ctx.beginPath(),
      ctx.rect(
        this.getLeft(),
        this.getTop(),
        this.getActualBoundingBoxWidth(),
        this.getActualBoundingBoxHeight()
      ),
      ctx.clip(),
      this
    )
  }
  _setFillStyles() {
    return this.__proto__.__proto__._setFillStyles.call(this)._setTextStyle()
  }
  _setStrokeStyles() {
    return this.__proto__.__proto__._setStrokeStyles.call(this)._setTextStyle()
  }
  _setFillStrokeStyles() {
    return this.__proto__.__proto__.call(this)._setTextStyle()
  }
  _setTextStyle() {
    const ctx = this.ctx; const sty = this.sty
    ctx._font !== sty.font && (ctx.font = ctx._font = sty.font)
    ctx._textAlign !== sty.textAlign && (ctx.textAlign = ctx._textAlign = sty.textAlign)
    ctx._textBaseline !== sty.textBaseline && (ctx.textBaseline = ctx._textBaseline = sty.textBaseline)
    ctx._direction !== sty.direction && (ctx.direction = ctx._direction = sty.direction)
    return this
  }
  getText() {
    return this.text
  }
  setText(text) {
    this.text = text
    return this
  }
  getMetrics() {
    const sty = this.sty
    return Utils.measureText(
      this.text,
      sty.font,
      sty.textAlign,
      sty.textBaseline,
      sty.direction
    )
  }
  getWidth() {
    return this.getMetrics().width
  }
  getActualBoundingBoxWidth() {
    return this.getMetrics().actualBoundingBoxWidth
  }
  getActualBoundingBoxHeight() {
    return this.getMetrics().actualBoundingBoxHeight
  }
  getLeft() {
    return this._sx - this.getMetrics().actualBoundingBoxLeft
  }
  getRight() {
    return this._sx + this.getMetrics().actualBoundingBoxRight
  }
  getTop() {
    return this._sy - this.getMetrics().actualBoundingBoxAscent
  }
  getBottom() {
    return this._sy + this.getMetrics().actualBoundingBoxDescent
  }
  // _preprocess = this._setTransform
  // _isPointIn = this._isQuickIn
}
Utils._assign(Text, Rect, '_isQuickInPath', '_isQuickInStroke')

export default Text
