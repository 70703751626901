/*
  节点增删改操作记录中心
 */
class Operation { // 记录操作
  constructor() {
    this._stack = []
    this.clear()
  }

  onMove() {} // 执行操作回调的勾子
  onPop() {} // 移除历史记录回调的勾子
  onPrev() {} // 外部调用的勾子
  onNext() {} // 外部调用的勾子
  onDispatch() {} // 执行

  push(target, operation, value) {
    this._pop()
    this._stack.push({
      target,
      operation,
      value
    })

    if (this._stack.length > 100) { // 记录30次操作
      this._stack.shift()
    } else {
      this._cursor++
    }

    this.onMove(target, operation)
  }

  prev() {
    this.onPrev(this._cursor - 1)
    if (this._cursor) {
      var peek = this._stack[--this._cursor]
      peek && this._prev(peek)
    }
  }

  _prev(peek) {
    const { target, operation, value } = peek
    this.onDispatch(value)
    this.onMove(target, operation)
  }

  next() {
    if (this._cursor < this._stack.length) {
      var peek = this._stack[++this._cursor]
      peek && this._next(peek)
    }
    this.onNext()
  }

  _next(peek) {
    const { target, operation, value } = peek
    this.onDispatch(value)
    this.onMove(target, operation)
  }

  clear() {
    this._pop()
    this._stack.length = 0
    this._cursor = -1
  }

  _pop() {
    while (this._stack.length > (this._cursor + 1)) {
      var peek = this._stack.pop()
      this.onPop(peek)
    }
  }

  getCurrentData() {
    return this._stack[this._cursor]
  }
}
export default Operation
export { default as RecordOperation } from './recordOperation'
