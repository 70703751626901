/*
 绘制上箭头
 */
import Process from './process.js'
class ArrowTop extends Process {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.setWidth(width).setHeight(height)
  }
  initial(sx, sy, ox, oy) { // 初始化画布
    return this.init(sx, sy, ox, oy)._horizontal()._vertical()
  }
  process() {
    const { ctx, _left, _top, _right, _bottom, width, height } = this

    const height_y = width / 2 > height - 10 && width / 2 !== height - 10 ? _bottom - 5 : width / 2 + _top

    // 角度的弧度
    const arcRadius = width > 35 && height > 21 ? 2 : 1

    // 普通的弧度
    const radius = height > 35 && width > 15 ? 4 : 1

    const arrow0 = { x: _left + width / 2, y: _top }
    const arrow1 = { x: _right, y: height_y }
    const arrow2 = { x: _right - width / 4, y: height_y }
    const arrow3 = { x: _right - width / 4, y: _bottom }
    const arrow4 = { x: _left + width / 4, y: _bottom }
    const arrow5 = { x: _left + width / 4, y: height_y }
    const arrow6 = { x: _left, y: height_y }
    const middlePoint = {
      x: (arrow0.x + arrow1.x) / 2,
      y: (arrow0.y + arrow1.y) / 2
    }

    ctx.beginPath()
    ctx.moveTo(middlePoint.x, middlePoint.y)
    ctx.arcTo(middlePoint.x, middlePoint.y, arrow1.x, arrow1.y, radius)
    ctx.arcTo(arrow1.x, arrow1.y, arrow2.x, arrow2.y, arcRadius)
    ctx.arcTo(arrow2.x, arrow2.y, arrow3.x, arrow3.y, radius)
    ctx.arcTo(arrow3.x, arrow3.y, arrow4.x, arrow4.y, radius)
    ctx.arcTo(arrow4.x, arrow4.y, arrow5.x, arrow5.y, radius)
    ctx.arcTo(arrow5.x, arrow5.y, arrow6.x, arrow6.y, radius)
    ctx.arcTo(arrow6.x, arrow6.y, arrow0.x, arrow0.y, arcRadius)
    ctx.arcTo(arrow0.x, arrow0.y, middlePoint.x, middlePoint.y, arcRadius)
    ctx.arcTo(middlePoint.x, middlePoint.y, middlePoint.x, middlePoint.y, radius)
  }
}

export default ArrowTop
