/*
  连接线 折线类型
  fLink: foldLink
 */
import Link from '../index'
import Layout from './linkLayout'
import Line from './sLine'
import Point from '../../shapes/point'
import FoldLine from '../../shapes/foldLine'
import { LINETYPE } from '../constant'
import Style from './foldLinkStyle'
import OperateLine from './splitLine'
class FoldLink extends Link {
  constructor(ctx, board, data) {
    super(ctx, board, data)
    this.type = LINETYPE.FOLD
    this.style = new Style(this)
    this.length = 0
    this.compoundLine = new FoldLine(ctx, this, this.points, 0, 0, 0, 0) // 折线
    this.background = new FoldLine(ctx, this, this.points, 0, 0, 0, 0) // 背景
    this.line = new Line(ctx, 0, 0, 0, 0) // 用于碰撞检测
    this.layout = new Layout(this)
    this.splitPoints = [] // 保存二分点
    this.splits = [] // 二分点的集合
    this.lines = [] // 根据绘制点生成的线段
    this.isUsedSplit = data.isUsedSplit || false // 是否通过控制点拖动过线段
    this.isInit = true
    this.splitedStart = this.splitedEnd = {}
    this.operateLine = new OperateLine(this)
    this.initPoints()
  }
  initPoints() {
    this.points = this.points.length ? this.points : [this.start, this.end]
    this.drawPoints = this.points.map(p => new Point(p.x, p.y))
    if (this.isUsedSplit) {
      this.operateLine.setSplitTail()
    }
  }
  offset(x, y) { // 移动
    const { splitedStart, splitedEnd } = this
    const { preAddPointsInStart, preAddPointsInEnd } = this.layout
    let other = []
    if (splitedStart.point) {
      other = [splitedStart.point, splitedEnd.point, ...splitedStart.after, ...splitedEnd.before]
    }
    const allPoints = Array.from(new Set([...other, ...this.points, ...preAddPointsInStart, ...preAddPointsInEnd]))
    allPoints.forEach(p => {
      p.offset(x, y)
    })

    this.texts.forEach(it => {
      it.offset(x, y)
    })
    this.splitPoints.forEach(p => {
      p.offset(x, y)
    })
    this.setOperatingTarget(null)
  }
  addTextOperation(t) { // t: text
    // 连接线文字需求变更，只允许添加一个文字 todo: 后续根据需求可进行修改
    t.rateAtLink || this.layout.setTextRate(t)
    this.texts.push(t)
    this.layout.layoutTexts()
    this.onSelectText()
    return t
  }
  isEnterLineRange({ x, y }, gap = 0) {
    return this.background._isPointInStroke(x, y, gap)
  }
  isEnterSplitPoint(point) {
    return false
  }
  isEnterSplitLine({ x, y }, gap) { // 鼠标是否进入折线分线段
    for (const line of this.lines) {
      if (line._isPointInStroke(x, y, gap)) {
        this.setTouchingTarget(line)
        return true
      }
    }
  }
  drawChildContent() {
    this.compoundLine.stroke()
    if (this.isShowBackground) {
      this.background.stroke()
    }
    if (this.isSelected) {
      this.lines.forEach(line => {
        if (line.length > 50) line.controlPoint.fill()
      }) // 绘制二分控制点
    }
  }
  setIsUsedSplit(v) {
    this.isUsedSplit = v
  }
  setDrawPointsByCompute() { // 通过计算，对现有的点数据进行格式化
    this.operateLine.resetPoints(this.points)
    this.drawPoints = [...this.points]
  }
  setDrawPointByArrow() { // 设置开始和结束绘制点的位置根据箭头的形态
    const { start, second, end, nextToEnd } = this.points
    const gap = this.style.arrowLength
    const cStart = { ...start }; const cEnd = { ...end }
    const _start = [second || cEnd, cStart]
    const _end = [nextToEnd || cStart, cEnd]
    const ls = []

    if (this.isShowStartArrow) {
      ls.push(_start)
    }
    if (this.isShowEndArrow) {
      ls.push(_end)
    }

    ls.forEach(d => { // 对开始点和结束点进行处理
      const [p1, p2] = d
      if (parseInt(p1.x) === parseInt(p2.x)) {
        p2.y = p2.y > p1.y ? p2.y - gap : p2.y + gap
      } else if (parseInt(p1.y) === parseInt(p2.y)) {
        p2.x = p2.x > p1.x ? p2.x - gap : p2.x + gap
      }
    })
    return [cStart, cEnd]
  }
  getStyle() {
    return this.style
  }
  childResizeFollowNode() {
    if (this.isConnectSameNode) {
      this.setIsUsedSplit(false)
      this.layout.initStore()
    }
  }
  applyChildContent() {
    if ((this.isOperatingStartHead || this.isOperatingEndHead) && !this.isInit) {
      this.drawPoints = this.layout.compute()
    }
    this.layout.layoutTexts()
    this.texts.forEach(t => {
      t.apply()
    })
    this.setBoundary(this.points)
    const { start, second, end, nextToEnd, middles } = this.points
    const [cStart, cEnd] = this.setDrawPointByArrow()
    this.compoundLine.setStart(cStart).setEnd(cEnd).setMiddles(middles)
    this.background.setStart(start).setEnd(end).setMiddles(middles)
    let e, s
    if (middles.length) {
      e = nextToEnd
      s = second
    } else {
      s = e = start.center(end)
    }
    let angle;
    [{ p: s, p1: start }, { p: e, p1: end }].forEach(o => {
      angle = Math.atan((parseInt(o.p1.y) - parseInt(o.p.y)) / (parseInt(o.p1.x) - parseInt(o.p.x)))
      angle = angle + (parseInt(o.p1.x) >= parseInt(o.p.x) ? 0 : Math.PI)
      this[`${o.p1 === start ? 'start' : 'end'}Arrow`].setRotation(angle)
    })
    this.isInit = false
  }
  isEnterSplit() {
    return false
  }
  collideWidthRect(box) {
    const ps = this.drawPoints
    for (let i = 0; i < ps.length - 1; i++) {
      this.line.setStart(ps[i].x, ps[i].y).setEnd(ps[i + 1].x, ps[i + 1].y)
      if (this.line.collidesWithRect(box)) {
        return true
      }
    }
    return false
  }
  setLines() {
    this.operateLine.setLines()
    this.layout.getLength().layoutTexts()
  }
  setSplitPoints() {
    this.operateLine.setSplitPoints()
    this.style.setSplitStyle()
  }
  setSplitTail() { // 设置拖动线段后，下一次线段走线的起始点（头部和尾部）
    const { points, lines } = this
    const index = (points.length - 2) || 0
    this.splitedEnd = {
      point: points[index],
      line: lines[lines.length - 2] || lines[0],
      before: [...this.points.slice(0, -2)]
    }
    this.splitedStart = {
      point: points[1] || points[0],
      line: lines[1] || lines[0],
      after: [...this.points.slice(2)]
    }
  }
  dragSplitPoint(move) { // 拖动二分点使折线移动
    if (!this.touchingTarget) return
    this.operateLine.eventdrag(move, this.touchingTarget)
    this.layout.getLength().initStore() // 重新计算总的线长
    this.apply()
  }
  onscaleChild() {
    this.operateLine.setSplitSize()
  }
  eventupInChild() {
    if (this.isMovingText) {
      this.layout.setTextRate(this.touchingTarget)
      this.isMovingText = false
    }
    this.operateLine.eventup()
  }
  eventmoveInChild(_mouse) {
    return false
  }
  get drawPoints() {
    return this.points
  }
  set drawPoints(ps) { // 设置drawPoints唯一入口，拦截进行依赖设置
    this.points.length = 0
    this.start = this.points.start = ps[0]
    this.points.second = ps[1]
    this.points.nextToEnd = ps[ps.length - 2]
    this.end = this.points.end = ps[ps.length - 1]
    this.points.middles = ps.slice(1, ps.length - 1)

    while (ps.length) {
      this.points.push(ps.shift())
    }

    this.setLines()
    this.setSplitPoints()
  }
}

export default FoldLink
