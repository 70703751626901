import { initSystemConfig } from '../state'
import {
  CHANGE_SYSTEM_STYLE,
  CHANGE_FLOAT_MENU_TYPE,
  CHANGE_FLOAT_MENU_TYPE_AND_POS,
  CHANGE_FLOAT_MENU_SHOW_STATUS
} from '../mutation-type'

export default function configReducer(state = initSystemConfig, action) {
  switch (action.type) {
    case CHANGE_SYSTEM_STYLE: // 更新页面设置
      return {
        ...state, ...action.value
      }

    case CHANGE_FLOAT_MENU_TYPE:
      return {
        ...state, floatMenu: {
          ...state.floatMenu,
          type: action.value
        }
      }
    case CHANGE_FLOAT_MENU_TYPE_AND_POS: {
      const { type, position } = action.value
      return {
        ...state, floatMenu: {
          ...state.floatMenu,
          type,
          position
        }
      }
    }
    case CHANGE_FLOAT_MENU_SHOW_STATUS: {
      return {
        ...state, floatMenu: {
          ...state.floatMenu,
          show: action.value
        }
      }
    }
    default:
      return state
  }
}
