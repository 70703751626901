import Helper from 'utils/helper'
import { NoteTagKey } from 'views/board/install/dataHelper'

export const getFilteredTagsByTagIds = (tagIds) => {
  const tags = getAllTags(tagIds)
  var tagsMap = new Map(tagIds.map(i => [i, true]))
  return tags.filter(t => tagsMap.has(t.id)).map(t => tagsMap.has(t.id) ? { ...t, isSelected: true } : t)
}

export const getTagsByTagIds = (tagIds) => {
  const tags = getAllTags()
  var tagsMap = new Map(tagIds.map(i => [i, true]))
  return tags.map(t => tagsMap.has(t.id) ? { ...t, isSelected: true } : t)
}

export const getAllTags = () => {
  let tags = []
  try {
    tags = Helper.localStorageGetItem(NoteTagKey) || []
  } catch (error) {
    console.log('error', error)
  }
  return tags.map(t => ({ ...t, isSelected: false }))
}
