import React, { useEffect } from 'react'
import NoteBar from 'components/toolBars/noteBar'
import FrameBar from 'components/toolBars/frameBar'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { changeFloatMenuType, changeFloatMenuTypeAndPos } from 'store/actions'
import { useBoardContext } from 'views/board/hooks'

function Floats(props) {
  const {
    systemConfig,
    selectObject,
    onChangeFloatMenuType,
    onChangeFloatMenuTypeAndPos
  } = props
  const { type, position, show } = systemConfig.floatMenu
  const { activeNode, activeNodeVersion } = selectObject
  const { board } = useBoardContext()

  const getFloatType = (node) => {
    let type
    if (node.type === 'image') {
      type = 'imgBar'
    } else if (node?.subName === 'note') {
      type = 'noteBar'
    } else if (node.type === 'frame') {
      type = 'frameBar'
    }

    return type
  }

  useEffect(() => {
    const type = getFloatType(activeNode)
    if (type && activeNode.id && show) {
      const selectNode = board.selector?.selected[0]
      if (selectNode && selectNode.isMoving) {
        onChangeFloatMenuType(null)
      } else {
        if (!selectNode || !position) return
        let pos = selectNode.getTopCenterPos()
        let screenPos = board.getMousePoint(pos.x, pos.y)
        if (screenPos.y < 60) {
          pos = selectNode.getBottomCenterPos()
          screenPos = board.getMousePoint(pos.x, pos.y)
        }
        onChangeFloatMenuTypeAndPos({ type, position: screenPos })
      }
    } else {
      onChangeFloatMenuType(null)
    }
  }, [activeNode.name, activeNode.id, activeNodeVersion, board, show])

  switch (type) {
    case 'noteBar': {
      return (
        <NoteBar position={position} activeNode={activeNode} />
      )
    }
    case 'frameBar': {
      return (
        <FrameBar position={position} activeNode={activeNode} />
      )
    }
    default: {
      // return (<></>)
    }
  }

  return <></>
}

const mapStateToProps = (state) => {
  return {
    selectObject: state.selectObject,
    systemConfig: state.systemConfig
  }
}

const mapDispatchToProps = (dispatch) => ({
  onChangeFloatMenuType: (v) => dispatch(changeFloatMenuType(v)),
  onChangeFloatMenuTypeAndPos: (v) => dispatch(changeFloatMenuTypeAndPos(v))
})

Floats.propTypes = {
  selectObject: PropTypes.object,
  systemConfig: PropTypes.object,
  onChangeFloatMenuType: PropTypes.func,
  onChangeFloatMenuTypeAndPos: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(Floats)
