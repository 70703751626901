/*
 绘制上下箭头
 */
import Process from './process.js'
class ArrowTopBottom extends Process {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.setWidth(width).setHeight(height)
  }

  process() {
    const { ctx, _left, _top, _right, _bottom, width, height } = this
    
    const halfX = _left + width / 2
    const halfY = _top + height / 2

    const start = { x: _left + width / 4, y: halfY }

    const arrow0 = { x: halfX, y: _top }
    const arrow1 = { x: _right, y: _top + width / 2 }
    const arrow2 = { x: _right - width / 4, y: _top + width / 2 }
    const arrow3 = { x: _right - width / 4, y: _bottom - width / 2 }
    const arrow4 = { x: _right, y: _bottom - width / 2 }
    const arrow5 = { x: halfX, y: _bottom }
    const arrow6 = { x: _left, y: _bottom - width / 2 }
    const arrow7 = { x: _left + width / 4, y: _bottom - width / 2 }
    const arrow8 = { x: _left + width / 4, y: _top + width / 2 }
    const arrow9 = { x: _left, y: _top + width / 2 }

    const arcRadius1 = height > 35 && width > 15 ? 4 : 1
    const arcRadius2 = width > 35 && height > 21 ? 2 : 1

    ctx.beginPath()
    ctx.moveTo(start.x, start.y)
    ctx.arcTo(start.x, start.y, arrow8.x, arrow8.y, arcRadius1)
    ctx.arcTo(arrow8.x, arrow8.y, arrow9.x, arrow9.y, arcRadius2)
    ctx.arcTo(arrow9.x, arrow9.y, arrow0.x, arrow0.y, arcRadius2)
    ctx.arcTo(arrow0.x, arrow0.y, arrow1.x, arrow1.y, arcRadius1)
    ctx.arcTo(arrow1.x, arrow1.y, arrow2.x, arrow2.y, arcRadius2)
    ctx.arcTo(arrow2.x, arrow2.y, arrow3.x, arrow3.y, arcRadius2)
    ctx.arcTo(arrow3.x, arrow3.y, arrow4.x, arrow4.y, arcRadius2)
    ctx.arcTo(arrow4.x, arrow4.y, arrow5.x, arrow5.y, arcRadius2)
    ctx.arcTo(arrow5.x, arrow5.y, arrow6.x, arrow6.y, arcRadius1)
    ctx.arcTo(arrow6.x, arrow6.y, arrow7.x, arrow7.y, arcRadius2)
    ctx.arcTo(arrow7.x, arrow7.y, arrow8.x, arrow8.y, arcRadius2)
    ctx.arcTo(start.x, start.y, start.x, start.y, arcRadius1)
  }
}

export default ArrowTopBottom
