import styled from 'styled-components'

export const LeftPanelsCssWrapper = styled.div`
* {
    box-sizing: border-box
  }

  display: flex;
  position: fixed;
  left: 20px;
  top: 50%;
  margin-top:-140px;
  z-index: 1;
  width: 48px;
  background: #FFFFFF;
  box-shadow: 1px 1px 5px 0px rgba(4, 0, 0, 0.1);
  border-radius: 6px;

  .tab {
    // border: 1px solid ${props => props.theme.main.borderColor};
    display: flex;
    flex-direction: column;
    width: 48px;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .tab button {
    display: block;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background:#fff;
    margin: 0 5px;
  }

  .tab button:hover {
    background-color: #ddd;
  }

  .tab button.active {
    background: #F0F2F5;
    padding-left:5px;
    padding-right:5px;
  }

  .hidden {
    display: none;
  }

  .active {
    background-color: #ccc;
  }

  .tab .category{
    margin-top:15px;
    width: 48px;
    display: flex;
    justify-content: center;
  }

  .tabcontent {
    position: absolute;
    left: 50px;
    height:410px;
    padding-top: 20px;
    border: 1px solid ${props => props.theme.main.borderColor};
    border-left: none;
    flex: 1;
    margin-left: 10px;
    background: #FFFFFF;
    box-shadow: 1px 1px 3px 0px rgba(4, 0, 0, 0.1);
    border-radius: 5px;
  }
`
