/* eslint-disable eqeqeq */
import Helper from 'utils/helper'
import Border from './border'
import Rect from '../shapes/rect'
import Style from './style'
const resizeCursors = ['', 'nw', 'n', 'ne', 'e', 'se', 's', 'sw', 'w']

class Group {
  constructor(ctx, board, { id, x, y, members, width, height }) {
    this.board = board
    this.id = id || Helper.produceNanoId()
    this.members = members
    this.boundary = new Rect(ctx, 0, 0, width, height)
    this.border = new Border(ctx, this)
    this.style = new Group.Style(this)
    this.width = width
    this.height = height
    this.x = x
    this.y = y
    this.moveType = ''
    this.selectType = ''
  }

  draw() {
    // 选中了组、没有框选、移动
    if (this.moveType === 'groupMove' && this.selectType == 'click') {
      this.boundary.stroke()
    }
    // 不移动
    if (this.moveType === '') {
      // 框选状态、
      if (this.selectType == 'frame') {
        this.apply()
        this.boundary.stroke()
      }
      // 选中了组
      if (this.selectType == 'click') {
        this.border.draw()
      }
    }
  }

  apply() {
    const { x, y } = this
    this.boundary.setStart(x, y)
    this.calculateSize()
    this.border.apply()
  }

  calculateSize() {
    const groupMembers = this.board.groups[this.id].members
    const asset = []
    for (const id in groupMembers) {
      this.board.nodes[id] && asset.push(this.board.nodes[id])
      this.board.links[id] && asset.push(this.board.links[id])
    }
    const { right, left, top, bottom } = Helper.calculatePosition(asset)
    this.x = left
    this.y = top
    this.boundary.setWidth(right - left).setHeight(bottom - top)
  }

  eventdown() {
    this.beforeOffset()
    this.selectType != 'frame' && this.setSelectType('click')
    this.draw()
  }

  beforeOffset() { // 使用_x, _y 记录下偏移前的初始值
    this._x = this.x
    this._y = this.y
  }

  eventup(n) {
    if (this.moveType) {
      this.moveType = ''
    }
    this.board.draw()
  }

  eventdrag() {
    this.drag()
    this.apply()
  }

  eventmove({ x, y }, mouse, link) {
    const dir = this.border.eventmove(x, y)
    this.board.$setCursor(`${resizeCursors[dir]}-resize` || 'default')
  }

  drag() {
    const sels = this.board.selector.selected
    const isSingleGroup = sels.every((selNode) => {
      return this.board.operateGroup.getGroup(selNode.id)
    })
    isSingleGroup ? (this.moveType = 'groupMove')
      : this.moveType = 'frameMove'
  }

  setSelectType(v) {
    this.selectType = v
    return this
  }

  unselect() {
    this.setSelectType('')
  }
}

Group.id = 0
Group.createGroup = function(ctx, board, data = {}) {
  const n = new Group(ctx, board, data)
  n.style.apply(data.style || board.style.node)
  return n
}
Group.Style = Style

export default Group
