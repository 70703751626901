/*
  表示决定的icon 圆形状
 */
import Line from '../../shapes/line'
class PartialLine extends Line {
  constructor(ctx, sx, sy, ex, ey, ox, oy) {
    super(ctx, sx, sy, ex, ey, ox, oy)
    this.startHidePoint = { x: sx, y: sy }
    this.endHidePoint = { x: ex, y: ey }
  }

  setStart(sx, sy, arrowPoint = { x: this._sx, y: this._sy }) {
    if (arrowPoint) {
      this.startHidePoint = { ...arrowPoint }
    } else {
      this.startHidePoint = { x: sx, y: sy }
    }
    this.setStartX(sx).setStartY(sy)
    return this
  }
  setEnd(ex, ey, arrowPoint = { x: this._ex, y: this._ey }) {
    if (arrowPoint) {
      this.endHidePoint = { ...arrowPoint }
    } else {
      this.endHidePoint = { x: ex, y: ey }
    }

    this.setEndX(ex).setEndY(ey)
    return this
  }

  process() {
    var ctx = this.ctx
    ctx.beginPath()

    ctx.moveTo(this.startHidePoint.x, this.startHidePoint.y)
    ctx.lineTo(this.endHidePoint.x, this.endHidePoint.y)
  }
}
export default PartialLine
