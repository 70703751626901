import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import IconButton from 'components/comm/IconButton'
import { useBoardContext } from '../../views/board/hooks'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

export const AppFootCssWrapper = styled.div`
  .footer{
    position:fixed;
    // top:125px;
    display:flex;
    align-items:center;
  }
  .footer .leftDiv{
    background: #FFFFFF;
    box-shadow: 1px 1px 5px 0px rgba(4, 0, 0, 0.1);
    border-radius: 5px;
    display:flex;
    align-items:center;
    justify-content:center;
    width: 160px;
    height: 48px;
    border-radius: 6px;
  }
  .footer .leftButton{
    margin:0 10px;
    width: 28px;
    height: 28px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius: 6px;
  }
  .footer .left1{
    padding-right: 2px;
  }
  .footer .leftButton:hover{
    background: #F0F2F5;
  }
  .footer .percentage{
    display:flex;
    align-items:center;
    justify-content:center;
    margin: 0 10px;
    width:50px;
  }
  .footer .leftDiv input{
    border: none;
    width: 50px;
    text-align: right;
  }
`
function Footer(props) {
  const { selectObject, loginStatus, canvasScale } = props
  const { activeNode } = selectObject
  const { board } = useBoardContext()

  const handleBtnClick = useCallback((type) => {
    const isPlus = type === '+'
    board?.operateBoard?.scaleCanvasByConfig(isPlus)
  }, [board])

  const showScale = useMemo(() => (canvasScale * 100).toFixed(), [canvasScale])

  useEffect(() => {
    setTimeout(() => {
      if (board?.transform && board?.$dispatch) {
        board?.updateScale(board?.transform)
      }
    }, 100)
  }, [board])

  return (
    <AppFootCssWrapper>
      <div className='footer' style={loginStatus === 'login' ? !activeNode.name ? { right: '20px', top: '85px' } : { right: '300px', top: '85px' } : !activeNode.name ? { right: '20px', top: '80px' } : { right: '300px', top: '80px' }}>
        <div className='leftDiv'>
          <div className='leftButton left1' onClick={() => handleBtnClick('-')}>
            <IconButton
              name={'reduce'}
              showToolTip={false}
              widthInPx={20}
              heightInPx={22}
              fill='#1C1C1C'
            />
          </div>
          <div className='percentage' onKeyDown={(event) => {
            // 阻止input onChange事件触发onKeyDow冒泡到document
            event.stopPropagation()
          }}>
            {/* <input value={curValue} onInput={handleChange} onBlur={onBlurScale} /> */}
            <div>{showScale}</div>
            <div>%</div>
          </div>
          <div className='leftButton' onClick={() => handleBtnClick('+')}>
            <IconButton
              name={'add'}
              widthInPx={20}
              showToolTip={false}
              heightInPx={22}
              fill='#1C1C1C'
            />
          </div>
        </div>
      </div>
    </AppFootCssWrapper>
  )
}

Footer.propTypes = {
  onChangeNodeStyle: PropTypes.func,
  selectObject: PropTypes.object,
  loginStatus: PropTypes.string,
  canvasScale: PropTypes.number
}

const mapStateToProps = state => {
  return {
    selectObject: state.selectObject,
    canvasScale: state.canvasConfig.canvasScale
  }
}
const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
// export default Footer
