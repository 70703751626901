import { getTransformByLocal } from 'utils/globalHelper'

const canvas = document.createElement('canvas')
const aCtx = canvas.getContext('2d')

const BaseCheckPix = 10

// gap 待使用
function JudgePointInShape(point, node, drawType, gap = 0) {
  if (!point.x || !point.y) return false

  const { scale } = getTransformByLocal()
  if (!scale) return false

  // keep same size
  const ctx = node.ctx
  const c = node.ctx.canvas
  canvas.width = c.width
  canvas.height = c.height

  if (!node.patchShape || !node.getShapePatch) return false
  const nM = node.getShapePatch()
  const pM = { x: nM.x - c.width / 2, y: nM.y - c.height / 2 }

  node.ctx = aCtx
  const nPoint = {x: point.x - pM.x, y: point.y - pM.y } 

  node.patchShape({x:-pM.x, y: -pM.y})
  node[drawType]()
  node.patchShape({x:pM.x, y: pM.y})

  const pxSize = Math.max(Math.floor(BaseCheckPix / scale), 2)

  const left = Math.round(nPoint.x - pxSize / 2)
  const top = Math.round(nPoint.y - pxSize / 2)

  let imgData
  try {
    imgData = aCtx.getImageData(left, top, pxSize, pxSize)
  } catch (error) {
    return false
  }

  node.ctx = ctx
  for (let i = 0; i < imgData.data.length; i += 4) {
    const alpha = imgData.data[i + 3]
    if (alpha > 0) return true
  }
  return false
}
export default JudgePointInShape
