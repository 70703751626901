/**
 * 此文件用于操作画板的功能集合
 * board: 画板实例
 */

import Helper from "utils/helper"

class OperateBoard {
  constructor(board) {
    this.board = board
    this.board.operateTarget = null
    this.timer = null
    this.tempData = null
  }

  saveTempData() { // 保存临时数据用于操作回退
    this.timer && clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.tempData = this.board.collectData().data
    }, 100)
  }

  moveBoard(move) { // 移动画布
    const b = this.board
    this.onmove(move)
    b.draw()
    this.saveTempData()
  }

  translateBoard(x, y) {
    const { scale, offsetX, offsetY } = this.transform
    this.$ctx.setTransform(scale, 0, 0, scale, offsetX + x * scale, offsetY + y * scale)
  }

  interruptAddDragInShape() { // 中断添加 左侧拉进画板的图形 整个拖拽图形到画板
    const that = this.board
    if (that.operateTarget) { // 所有的判断依据是operateTarget及对应的图形节点对象
      that.operateTarget.initDragInShape()
      that.draw()
    }
  }
  onpaste(nodes) {
    const ns = []
    const ls = []
    const move = { x: 0, y: 0 }
    const mouse = { x: this.board.mouse.boardX, y: this.board.mouse.boardY }
    this.box.calculateSize(nodes)

    if (nodes.length > 1) {
      move.x = mouse.x - this.box.cx
      move.y = mouse.y - this.box.cy
    } else {
      move.x = mouse.x - nodes[0].cx
      move.y = mouse.y - nodes[0].cy
    }

    nodes.forEach(n => {
      n.isNode ? ns.push(n) : ls.push(n)
      for (const cn of Object.values({ ...this.board.nodes, ...this.board.links })) {
        if (Math.ceil(n.x) + Math.ceil(move.x) === Math.ceil(cn.x) && Math.ceil(n.y) + Math.ceil(move.y) === Math.ceil(cn.y)) { // 粘贴时，有重叠的node节点，需要移动下坐标
          move.x += 10
          move.y += 10
        }
      }
    })

    const newNodes = this.operateNode.onpaste(ns, move) || []
    const newLinks = this.operateLink.onpaste(ls, move, newNodes) || []
    this.adaptionNodes(newNodes)
    this.adaptionNodes(newLinks)
    this.onOperation([...newNodes, ...newLinks], OperateBoard.OPERATION.PASTE)
  }
  onscale(mouse, updateStep) { // 缩放画布
    const isPlus = updateStep > 0
    const step = Math.abs(updateStep)
    const b = this.board
    const s = b.transform
    mouse = b.getDrawCoordinate(mouse.x, mouse.y)
    const reckonStep = isNaN(step) ? s.step : step
    const offsetX = mouse.x * reckonStep
    const offsetY = mouse.y * reckonStep
    if (isPlus > 0) {
      s.offsetX -= s.scale >= s.max ? 0 : offsetX
      s.offsetY -= s.scale >= s.max ? 0 : offsetY
      s.scale = (s.scale + reckonStep).toFixed(5) // 用Math.abs取值，不能用这种方式
    } else {
      s.offsetX += s.scale <= s.min ? 0 : offsetX
      s.offsetY += s.scale <= s.min ? 0 : offsetY
      s.scale = (s.scale- reckonStep ).toFixed(5)
    }
    s.scale = Math.min(s.max, Math.max(s.scale, s.min))
    b.scaleCanvas(s)
    b.mouse.setBoardPosition(b.mouse)
    b.draw()
    b.redrawFloatMenu()
  }

  onScaleAnimate(mouse, step) { // 带动画的缩小画布
    this.board.scaleAnimate(mouse, step * this.board.$dpr).start()
  }

  scaleCanvasByConfig(isPlus) {
    const screenPos = this.getViewCenterPoint()
    const curScale = this.board.transform.scale
    const maxScale = this.board.transform.max
    const dpr = this.board.$dpr 
   
    // config in here
    const nS = Helper.getNextButtonScale(isPlus, curScale / dpr, maxScale / dpr)
    const nS2 = nS * dpr

    const step = (nS2 - curScale)
    //todo 正在执行动画时，再次点击时，重新计算值，防止放大的值不对
    this.board.scaleAnimate(screenPos, step).start()
  }

  scaleCanvasByStep(mouse, size) {
    const curScale = this.board.transform.scale
    const dpr = this.board.$dpr 
   
    const nS = Helper.getNextMouseScale(size, curScale / dpr)
    const nS2 = nS * dpr
    const step = nS2 - curScale
    this.board.transform.lastScaleBoard = true
    this.onscale(mouse, step.toFixed(2))
  }

  onmove(move) { // 移动画布
    const b = this.board
    const s = b.transform
    s.offsetX += move.x * s.scale
    s.offsetY += move.y * s.scale

    const { scale, offsetX, offsetY } = b.transform
    b.scaleBackground(s)
    b.$ctx.setTransform(scale, 0, 0, scale, offsetX, offsetY)
    b.hacker.deactivate()

    b.nodeGridSnap.onmove(s)
  }

  eventup(ev) {
    const that = this.board
    switch (that.mouse.eventType) { // 菜单事件类型判断 由于右键鼠标拖动和右键鼠标存在动作重叠，需要在mouseUp的时候做出前面动作的判断
      case 'mouseRightDown':
        that.eventdown(ev, that.mouse.left)
        that.$menu.locate(ev.$x, ev.$y, (that._node || that._link) ? 'node' : 'board')
        break
      case 'mouseRightDrag':
        break
      case 'mouseLeftDrag': // mouseup的时候需要判断之前的动作是否只左键按下移动
        if (ev.relatedTarget === null && that.operateTarget) { // 两个区域event事情穿透时
          that.operateTarget.addDragInShape()
        }
        break
    }
  }

  eventdown(ev, sels) { // point: mouse 对应的画点 在鼠标点击点击的时候，需要记录前面的鼠标动作轨迹
    const that = this.board
    if (that.box.isVisible) {
      const n = that._node || that._link
      if (!sels.includes(n) && that.selector.selected.length === 1) { that.box.hide() }
    }
    if (that.operateTarget) {
      if (that.mouse.eventType === 'mousemove') { // 左侧栏图形单击，画板生成新的node, 并且跟随鼠标移动
        that.operateTarget.addDragInShape()
        that._eventmove(ev)
        that.$setCursor('move')
        that.dragIn.container.needClose = false
      }
      /* 需求变更，暂时去掉此功能 */
      // else if (that.dragIn.mouse.eventType === 'mousedown') { // 左侧栏图形双击，画板生成新的node 双击分为两层，左侧栏的点击，画板的点击 1 + 1
      //   that.operateTarget.addDragInShape(point)
      // }
    }
  }
  adaptionNodes(newNodes) { // 复制节点后，隐藏部分节点自适应展示
    if (newNodes.length === 0) return
    const b = this.board
    const distance = { left: 0, top: 0, right: 0, bottom: 0 }
    newNodes.forEach(n => {
      distance.left = Math.min(distance.left, n.left * b.transform.scale + b.transform.offsetX)
      distance.top = Math.min(distance.top, n.top * b.transform.scale + b.transform.offsetY)
      if (n.right > b.$wrapper.$.$width) distance.right = Math.min(distance.right, -((n.right * b.transform.scale) - (b.$wrapper.$.$width * b.$dpr) + b.transform.offsetX))
      if (n.bottom > b.$wrapper.$.$height) distance.bottom = Math.min(distance.bottom, -((n.bottom * b.transform.scale) - (b.$wrapper.$.$height * b.$dpr) + b.transform.offsetY))
    })
    const mouseReal = this.board.getMousePoint(b.mouse.boardX, b.mouse.boardY)// 缩放之前的鼠标坐标
    const scaleValue = []
    let distanceMin = 0
    for (const [k, v] of Object.entries(distance)) {
      if (v < 0) { // 有隐藏距离
        distance[k] = distance[k] - (25 * b.$dpr)// 默认加一个格子的距离
        distanceMin = Math.min(distanceMin, distance[k])
        scaleValue.push(Math.abs((distance[k] / ((['top', 'bottom'].includes(k) ? b.$wrapper.$.$height * b.$dpr : b.$wrapper.$.$width * b.$dpr) + Math.abs(distance[k]))) * b.transform.scale))
      }
    }
    let referMouseX = b.$wrapper.$.$width - mouseReal.x // 鼠标对称x点
    const referMouseY = b.$wrapper.$.$height - mouseReal.y // 鼠标堆成y点
    if (distance.left < 0 && (distance.top < 0 || distance.bottom < 0)) referMouseX = b.$wrapper.$.$width * 0.8 // 避免x轴平移距离过多
    if (distance.right < 0 && (distance.top < 0 || distance.bottom < 0)) referMouseX = b.$wrapper.$.$width * 0.2 // 避免x轴平移距离过多
    if (distanceMin < 0) this.onScaleAnimate({x:referMouseX,y:referMouseY}, -Math.max(...scaleValue))
  }
  getViewEdgePoints() {
    const gc = this.board.$canvas
    const canvasBox = gc.getBoundingClientRect() // 获取canvas元素的边界框
    const { left, right, top, bottom } = canvasBox
    const tLPoint = { x : left, y: - top }
    const bRPoint = { x : right, y: bottom }
    return { tLPoint, bRPoint }
  }
  getViewCenterPoint() {
    const { tLPoint: p1, bRPoint: p2 } = this.getViewEdgePoints()
    const midP = { x: (p1.x + p2.x) / 2, y: (p1.y + p2.y) / 2 }
    return midP
  }
  get operateNode() {
    return this.board.operateNode
  }
  get operateLink() {
    return this.board.operateLink
  }
  get box() {
    return this.board.box
  }
  get selector() {
    return this.board.selector
  }
}

OperateBoard.OPERATION = {
  'PASTE': '_pasteOperation',
  'MOVE': '_moveOperation'
}

OperateBoard.setOnOperation = function(cb) {
  this.prototype.onOperation = cb
}
export default OperateBoard
