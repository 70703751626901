/* eslint-disable no-useless-constructor */
import mathHelper from '../../../utils/mathHelper'
import Border from '../border'
class NoteBorder extends Border {
  constructor($ctx, target) {
    super($ctx, target)
  }
  apply() {
    const { target } = this
    const { x, y, width, height, right, bottom } = target
    this.boundary.setStart(x, y).setWidth(width).setHeight(height)
    const list = [
      { x, y },
      { x: right, y: y },
      { x: right, y: bottom },
      { x: x, y: bottom }
    ]
    const pointSide = this.board.getDrawLength(this.style.anchorWidth) // 方位点的边长
    list.forEach((it, i) => {
      this.points[i]
        .setStart(it.x - pointSide / 2, it.y - pointSide / 2)
        .setWidth(pointSide)
        .setHeight(pointSide)
    })
  }
  draw() {
    this.boundary.stroke()
    this.target.isMoving ||
      this.points.forEach((p) => {
        p.fillStroke()
      })
  }
  eventDrag({ x, y }, ev) {
    let gapX = 0
    let gapY = 0
    const { right, bottom, top, left, width, height } = this.target

    let refX = left
    let refY = top

    let restWidth, restHeight
    let isNeedResize = true

    switch (
      this.mouseInPoint // 调整原理： 先计算width, height的增量，在根据增量计算出left, top
    ) {
      case 1: { // 1左上角的点，2，3，4...后面的点按照顺时针依次排列
        const scalePos = { x: right, y: bottom }
        const dragPos = { x: left, y: top }
        const cuiZuPos = mathHelper.getPointToLineCuiZu(scalePos, dragPos, { x, y })
        gapX = left - cuiZuPos.x
        gapY = top - cuiZuPos.y
        refX = left - gapX
        refY = top - gapY
        break
      }
      case 2: {
        const scalePos = { x: left, y: bottom }
        const dragPos = { x: right, y: top }
        const cuiZuPos = mathHelper.getPointToLineCuiZu(scalePos, dragPos, { x, y })
        gapX = cuiZuPos.x - right
        gapY = top - cuiZuPos.y
        refY = top - gapY
        break
      }
      case 3: {
        const scalePos = { x: left, y: top }
        const dragPos = { x: right, y: bottom }
        const cuiZuPos = mathHelper.getPointToLineCuiZu(scalePos, dragPos, { x, y })
        gapX = cuiZuPos.x - right
        gapY = cuiZuPos.y - bottom
        break
      }
      case 4: {
        const scalePos = { x: right, y: top }
        const dragPos = { x: left, y: bottom }
        const cuiZuPos = mathHelper.getPointToLineCuiZu(scalePos, dragPos, { x, y })
        gapX = left - cuiZuPos.x
        gapY = cuiZuPos.y - bottom
        refX = left - gapX
        break
      }
      case 5:
        gapX = 0
        gapY = top - y
        refY = top - gapY
        if (gapY < 0) {
          restHeight = height + gapX
        }
        break
      case 6:
        gapX = x - right
        if (gapX < 0) {
          restWidth = width + gapX
        }
        gapY = 0
        break
      case 7:
        gapX = 0
        gapY = y - bottom
        if (gapY < 0) {
          restHeight = height + gapX
        }
        break
      case 8:
        gapX = left - x
        if (gapX < 0) {
          restWidth = width + gapX
        }
        gapY = 0
        refX = left - gapX
        break
    }

    if (this.mouseInPoint <= 4) {
      if (gapX < 0) {
        restWidth = width + gapX
      }

      if (gapY < 0) {
        restHeight = height + gapY
      }
      const { isValid } = this.target.checkNewSizeConfigIsValid({ width: restWidth, height: restHeight })
      if (!isValid) {
        gapX = 0
        gapY = 0
        isNeedResize = false
      }
    } else {
      if (restWidth) {
        const { isValid, pathHeight } = this.target.checkNewSizeConfigIsValid({ width: restWidth })
        if (!isValid) {
          isNeedResize = false
          gapX = 0
        }
        if (pathHeight) {
          gapY = pathHeight
        }
      } else if (restHeight) {
        const { isValid } = this.target.checkNewSizeConfigIsValid({ height: restHeight })
        if (!isValid) {
          isNeedResize = false
          gapY = 0
        }
      }
    }

    this.target.isMoving = true
    isNeedResize && this.target.resize({ x: refX, y: refY, gapX, gapY })
  }
  onmouseleave() {
    this.mouseInPoint = 0
  }
  eventmove(x, y) {
    let p
    const ps = this.points
    this.preMouseInPoint = this.mouseInPoint
    this.mouseInPoint = 0
    this.style.setUnhoverStyle(ps[this.preMouseInPoint - 1])
    if (this.boundary._isQuickInPath(x, y, this.style.anchorWidth / 2)) {
      for (let i = 0; i < ps.length; i++) {
        p = ps[i]
        if (p._isQuickInPath(x, y)) {
          this.style.setHoverStyle(p)
          this.mouseInPoint = i + 1
          break
        } else {
          this.judgeBorder(x, y)
        }
      }
    }
    if (this.preMouseInPoint !== this.mouseInPoint) this.board.draw()
    return this.mouseInPoint
  }
  onscale() {
    this.style._apply()
    this.apply()
  }
  eventup() {}
  // 判断是哪条边
  judgeBorder(x1, y1) {
    const points = { x: x1, y: y1 } // 鼠标点
    const { target } = this
    const { x, y, right, bottom } = target
    const list = [
      { x, y },
      { x: right, y: y },
      { x: right, y: bottom },
      { x: x, y: bottom }
    ]
    if (mathHelper.getPointToLineDis(list[0], list[1], points) < 4) {
      this.mouseInPoint = 5
    } else if (mathHelper.getPointToLineDis(list[1], list[2], points) < 4) {
      this.mouseInPoint = 6
    } else if (mathHelper.getPointToLineDis(list[2], list[3], points) < 4) {
      this.mouseInPoint = 7
    } else if (mathHelper.getPointToLineDis(list[3], list[0], points) < 4) {
      this.mouseInPoint = 8
    }
  }
}

export default NoteBorder
