/* eslint-disable no-proto */
import Shape from '../../shapes/index.js'
import Rect from '../../shapes/rect'
import TextStyle from '../../shapes/styles/textStyle'
import Utils from '../../utils'

class ColorText extends Shape {
  constructor(ctx, sx, sy, text, config) {
    const { color = 'white', backgroundColor = '#fff' } = config || {}
    super(ctx, sx, sy, 0, 0)
    this.text = text
    this.color = color
    this.width = 0
    this.height = 0
    this.rSx = 0
    this.rSy = 0
    this.backgroundColor = backgroundColor
    this._initStyle()
  }
  updateBackgroundColor(color) {
    this.backgroundColor = color
  }
  _initStyle() {
    this.setStyle(new TextStyle())
  }
  setRelativeStart(x, y) {
    this.rSx = x
    this.rSy = y
  }
  _fill() {
    const width = this.getRight() - this.getLeft()
    const height = this.getBottom() - this.getTop()
    const tx = this.sx + width / 2
    const ty = this.sy + height / 2

    const paddingLeftRight = 5
    const paddingTopBottom = 5

    const ctx = this.ctx

    ctx.save()
    ctx.fillStyle = this.backgroundColor
    ctx.fillRect(this.sx - paddingLeftRight, this.sy - paddingTopBottom, width + paddingLeftRight * 2, height + paddingTopBottom * 2)
    ctx.restore()

    ctx.save()
    ctx.font = this.sty.font
    ctx.fillStyle = this.color
    ctx.translate(tx, ty)
    ctx.rotate((-this.angle * Math.PI) / 180)
    ctx.translate(-tx, -ty)
    ctx.fillText(this.text, this._sx, this._sy)
    ctx.restore()
    return this
  }
  _stroke() {
    this.ctx.strokeText(this.text, this._sx, this._sy)
    return this
  }
  _setTextStyle() {
    const ctx = this.ctx
    const sty = this.sty
    ctx._font !== sty.font && (ctx.font = ctx._font = sty.font)
    ctx._textAlign !== sty.textAlign && (ctx.textAlign = ctx._textAlign = sty.textAlign)
    ctx._textBaseline !== sty.textBaseline && (ctx.textBaseline = ctx._textBaseline = sty.textBaseline)
    return this
  }
  getText() {
    return this.text
  }
  setText(text) {
    this.text = text
    return this
  }
  getMetrics() {
    const sty = this.sty
    return Utils.measureText(
      this.text,
      sty.font,
      sty.textAlign,
      sty.textBaseline,
      sty.direction
    )
  }
  getWidth() {
    if (!this.width) {
      this.width = this.getMetrics().width
    }
    return this.width + 10
  }
  getHeight() {
    if (!this.height) {
      this.height = this.getActualBoundingBoxHeight() + 15
    }
    return this.height
  }
  getActualBoundingBoxWidth() {
    return this.getMetrics().actualBoundingBoxWidth
  }
  getActualBoundingBoxHeight() {
    return this.getMetrics().actualBoundingBoxHeight
  }
  getLeft() {
    return this._sx - this.getMetrics().actualBoundingBoxLeft
  }
  getRight() {
    return this._sx + this.getMetrics().actualBoundingBoxRight
  }
  getTop() {
    return this._sy - this.getMetrics().actualBoundingBoxAscent
  }
  getBottom() {
    return this._sy + this.getMetrics().actualBoundingBoxDescent
  }
}
Utils._assign(ColorText, Rect, '_isQuickInPath', '_isQuickInStroke')

export default ColorText
