/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import IconButton from 'components/comm/IconButton'
import withClickModalV3 from 'components/comm/hoc/withClickModalV3'
import PropTypes from 'prop-types'
import NumberEditorRight from 'components/comm/NumberEditorRight'

import styled from 'styled-components'

const MCCssWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 110px;
  height: 34px;
  background: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
  // justify-content: center;

  .modContent {
    // width: 130px;
    // background-color: #fafbfc;
    padding: 0;
    margin-bottom: -4px;
    margin-top: 6px;
  }

  .space {
    justify-content: center;
  }

  .item {
    // border: 1px solid;
    margin-bottom: 10px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      // border: 1px solid #0f71e4;
    }
  }

  .customBtnDiv{
    width: 110px;
    // border: 1px solid red;
    display:flex;
    height:100%;
    align-items: center;
    justify-content: center;
  }

  .lineButton {
    align-items: center;
    height: 100%;
    margin-left: 5px;
    border-right:1px solid #E3E3E3;
    width: 80px;
    display: flex;
    justify-content: center;
  }

  .customBtnDiv .bottomBtn{
    display:flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 -15px 12px;
  }
`

function CustomBtn(props) {
  const { curLine, setShowModal, onClick, ...rest } = props

  return (
    <div className='customBtnDiv' onClick={() => {
      onClick()
      setShowModal(true)
    }}>
      <IconButton className='lineButton' name={curLine.icon} widthInPx={50} heightInPx={20} showToolTip={false} {...rest}/>
      <div className='bottomBtn'>
        <IconButton name={'bottomBtn'} showToolTip={false} />
      </div>
    </div>
  )
}

function CustomModalContent(props) {
  const { lines, curLine, onLineChange, setShowModal } = props
  return (
    <div className='flexColumn modContent'>
      <div className='flexWrap space'>
        {
          lines.map((line, index) => (
            <IconButton
              key={index}
              name={line.icon}
              widthInPx={50}
              heightInPx={20}
              className='item'
              selected={curLine.icon === line.icon}
              onClick={() => {
                onLineChange(line.value)
                setShowModal(false)
              }}
            />
          ))
        }
      </div>
    </div>
  )
}

const LineSettingBtn = withClickModalV3(CustomBtn, CustomModalContent)
function FixLineSettingBtn(props) {
  const { lines, curLine, onLineChange, lineSize, onLineSizeChange, maxSize, minSize, index, name } = props
  const [showModal, setShowModal] = useState(false)
  const _curLine = lines.find(line => String(line.value) === String(curLine)) || lines[0]
  const btnProps = { curLine: _curLine, setShowModal }
  const modProps = { lines, curLine: _curLine, onLineChange, setShowModal }

  return (
    <MCCssWrapper>
      {index === 0 && <LineSettingBtn btnProps={btnProps} modProps={modProps} show={showModal} />}
      {index === 1 && <NumberEditorRight
        value={lineSize}
        unit='px'
        name={name}
        min={minSize}
        max={maxSize}
        onValueChanged={onLineSizeChange}
      />}
    </MCCssWrapper>
  )
}

FixLineSettingBtn.propTypes = {
  lines: PropTypes.array,
  curLine: PropTypes.any,
  onLineChange: PropTypes.func,
  onLineSizeChange: PropTypes.func,
  lineSize: PropTypes.number,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  index: PropTypes.number,
  name: PropTypes.string
}

export default FixLineSettingBtn
