import React, { useState, useCallback, useRef, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import IconButton from 'components/comm/IconButton'
import useOutsideClick from '../comm/hoc/useOutsideClick'
import { useBoardContext } from 'views/board/hooks'
import { LINETYPE } from 'drawBoard/link/constant'

const CssWrapper = styled.div`
width: 110px;
height: 34px;
background: #FFFFFF;
border: 1px solid #E3E3E3;
border-radius: 5px;
  .select{
    display:flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .select .selectLine {
    align-items: center;
    height: 100%;
    margin-left: 5px;
    border-right:1px solid #E3E3E3;
    width: 80px;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .select .bottomBtn{
    display:flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 -15px 12px;
  }

  .item{
    position: relative;
  }

  .ul{
    position: absolute;
    z-index: 1;
    transform: translateX(-50%);
    min-height: 10px;
    padding: 4px;
    box-shadow: 1px 1px 3px 0px rgba(4, 0, 0, 0.1);
    border-radius: 6px;
    background-color: white;
    width: 110px;
    right: -55px;
  }
  .li{
    width:100%;
    height:30px;
    display:flex;
    align-items: center;
    justify-content: center;
  }
`

const Item = (props) => {
  const { list, onChangeValue } = props
  return (
    <div className='ul'>
      {list && list.map((item, index) => {
        return (
          <div className='li' key={index} onClick={() => onChangeValue(index)} id={item.name}>
            <IconButton name={`${item.value}-line`} showToolTip={false} />
          </div>
        )
      })}
    </div>
  )
}

Item.propTypes = {
  list: PropTypes.array,
  onChangeValue: PropTypes.func
}

const SelectLine = () => {
  const [isShow, setIsShow] = useState(false)
  const { board } = useBoardContext()

  const list = [
    { value: LINETYPE.FOLD },
    { value: LINETYPE.STRAIGHT },
    { value: LINETYPE.CURVE }
  ]
  const [value, setValue] = useState(LINETYPE.FOLD)

  useEffect(() => {
    const selected = board.selector?.ctrl
    if ((selected || {}).isLink) {
      const type = selected.type
      setValue(type)
    }
  }, [board.selector?.ctrl])

  const onChangeValue = useCallback((index) => {
    const type = list[index].value
    board && board.api.setLineType(type)
    setIsShow(false)
  }, [board])

  const handleClick = useCallback(() => {
    setIsShow(!isShow)
  }, [])

  const handleOutClick = useCallback(() => {
    setIsShow(false)
  }, [])

  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, handleOutClick)

  return (
    <CssWrapper ref={wrapperRef}>
      <div className='select' onClick={(e) => {
        handleClick()
        setIsShow(true)
      }}>
        <div className='selectLine' >
          <IconButton name={`${value}-line`} showToolTip={false} widthInPx={22} />
        </div>
        <div className='bottomBtn' >
          <IconButton name={'bottomBtn'} showToolTip={false} />
        </div>
      </div>

      {isShow && <div className='item'>
        <Item list={list} onChangeValue={onChangeValue}/>
      </div>}
    </CssWrapper>
  )
}

export { SelectLine }
