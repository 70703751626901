/*
  表示结束的icon
 */
import Rect from '../rect'
class Terminator extends Rect {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.width = width || 100
    this.height = height || 100
  }
  process() {
    const { ctx, _left, _right, _top, _bottom, width } = this
    ctx.beginPath()
    const gap = width / 5
    const gap1 = gap / 3
    const start = { x: _left + gap, y: _top }
    const p1 = { x: _right + gap1, y: _top }
    const p2 = { x: _right + gap1, y: _bottom }
    const p3 = { x: _left - gap1, y: _bottom }
    const p4 = { x: _left - gap1, y: _top }
    ctx.beginPath()
    ctx.moveTo(start.x, start.y)
    ctx.lineTo(_right - gap, _top)
    ctx.bezierCurveTo(p1.x, p1.y, p2.x, p2.y, _right - gap, _bottom)
    ctx.lineTo(_left + gap, _bottom)
    ctx.bezierCurveTo(p3.x, p3.y, p4.x, p4.y, start.x, start.y)
  }

  getShapePatch() {
    return {
      x: this._left,
      y: this._top
    }
  }

  patchShape(move) {
    this._left += move.x
    this._right += move.x
    this._top += move.y
    this._bottom += move.y
  }

}
export default Terminator
