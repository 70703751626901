/*
  连接线 直线类型
  fLink: StraightLink
 */
import Link from '../index'
import Layout from './linkLayout'
import Line from '../../shapes/line'
import CompoundLines from './compoundLines'
import { LINETYPE } from '../constant'
import Style from './straightLinkStyle'
import mathHelper from 'utils/mathHelper'

class StraightLink extends Link {
  constructor(ctx, board, data) {
    super(ctx, board, data)
    this.type = LINETYPE.STRAIGHT
    this.style = new Style(this)
    this.length = 0
    this.initLines(ctx, board, data) // this.compoundLines
    this.line = new Line(ctx, 0, 0, 0, 0) // 用于碰撞检测
    this.layout = new Layout(this)
    this.drawPoints = []
  }

  initLines(ctx, board, data) {
    this.compoundLines = new CompoundLines(ctx, board, this.style, data) // 折线
  }

  // 清除一些状态
  cleanStatus() {
    this.compoundLines.clean()
  }

  addTextOperation(t) { // t: text
    // 连接线文字需求变更，只允许添加一个文字 todo: 后续根据需求可进行修改
    t.rateAtLink || this.layout.setTextRate(t)
    this.texts.push(t)
    this.layout.layoutTexts()
    this.onSelectText()
    return t
  }
  isEnterLineRange({ x, y }, gap = 0) {
    return this.compoundLines.isEnterBackground(x, y, gap)
  }
  isEnterSplitPoint(point) {
    return this.compoundLines.isTouchingSplitPoint(point, 3)
  }
  isEnterDragPoint(point) {
    return this.compoundLines.isTouchingDragPoint(point, 3)
  }
  drawChildContent() {
    this.compoundLines.draw(this.isShowBackground)
  }

  dragDragPoint(move) {
    const arrow = this.compoundLines.dragDragPoint(move)
    if (arrow.start) {
      this.startArrow.setRotation(arrow.start)
    }

    if (arrow.end) {
      this.endArrow.setRotation(arrow.end)
    }
    this.updateTextPos()
  }

  dragSplitPoint(move) {
    this.dragDragPoint(move)
  }

  offset(x, y) {
    this.start.offset(x, y)
    this.end.offset(x, y)
    this.texts.forEach(it => {
      it.offset(x, y)
    })
    this.compoundLines.getAllLines().forEach((l) => {
      l.offset(x, y)
    })
  }

  setDrawPointByArrow(start, end, points) { // 设置开始和结束绘制点的位置根据箭头的形态

  }

  // 获取当前拖动点的实时坐标
  getCurrentDragPointPos() {
    return this.compoundLines.getCurDragPointPos()
  }

  // 获取当前拖动点对应的两条线的两个端点
  getCurrentDragPointTwoEndPoints() {
    return this.compoundLines.getCurDragPointTwoEndPoints()
  }

  getPoints() {
    const points = []
    const lines = this.compoundLines.getAllLines()

    for (let i = 0; i < lines.length; i++) {
      const cl = lines[i]
      const { start } = cl.getLineTwoEndPoints()
      if (i !== 0) {
        // 保留第二条先开始的起始点,即所有的中点分割点
        points.push(start)
      }
    }

    return { points }
  }

  splitLinkLine() {
    this.compoundLines.splitLine()
    this.style.applyChildStyle()
  }

  mergeStraightLine() {
    this.compoundLines.mergeLine(this.isTouchDragPoint, this.isTouchSplit)
    this.compoundLines.lastSplitIndex = null
    this.applyChildContent()
  }

  onscaleChild() {
    const size = this.board.getDrawLength(this.getStyle().headWidth)
    this.compoundLines.setLineHeadSize(size)
  }

  getStyle() {
    return this.style
  }

  getArrowPoint(arrow, angle) {
    const start = { x: arrow._sx, y: arrow._sy }
    const dis = arrow.length
    return mathHelper.getEndPointOfSlash(start, angle, dis)
  }

  applyChildContent() {
    const [start, end, sSlope, eSlope] = this.layout.compute()
    const { points } = this.getPoints()
    this.drawPoints = this.setBoundary([start, ...points, end])

    this.startArrow.setRotation(sSlope)
    this.endArrow.setRotation(eSlope)

    const option = {
      startArrowPoint: null,
      endArrowPoint: null
    }

    if (this.isShowStartArrow) {
      option.startArrowPoint = this.getArrowPoint(this.startArrow, sSlope)
    }

    if (this.isShowEndArrow) {
      option.endArrowPoint = this.getArrowPoint(this.endArrow, eSlope)
    }

    this.compoundLines.apply(start, end, option)

    this.updateTextPos()
  }

  updateTextPos() {
    this.layout.layoutTexts()
    this.texts.forEach(t => {
      t.apply()
    })
  }

  collideWidthRect(box) {
    const ps = this.drawPoints
    for (let i = 0; i < ps.length - 1; i++) {
      this.line.setStart(ps[i].x, ps[i].y).setEnd(ps[i + 1].x, ps[i + 1].y)
      if (this.line.collidesWithRect(box)) {
        return true
      }
    }
    return false
  }
  eventmoveInChild(_mouse) {
    let target = ''
    if ((target = this.isEnterDragPoint(_mouse))) {
      this.isMouseIn = true
      this.mouseInWhich = target
      this.board.$setCursor('move')
    } else if ((target = this.isEnterSplitPoint(_mouse))) {
      this.isMouseIn = true
      this.mouseInWhich = target
      if (this.isCurveLink || this.isStraightLink) {
        this.board.$setCursor('move')
      }
    }
    return this.isMouseIn
  }
  eventup() {
    this.mergeStraightLine()
    if (this.isMovingText) {
      this.layout.setTextRate(this.touchingTarget)
      this.isMovingText = false
    }
  }
}

export default StraightLink
